/*-------------------------------------------Mobile Media Query Start-----------------------------------------------------------------*/
@media only screen and (min-width: 300px) and (max-width: 374px) {
  .sub_section_1 {
    position: relative;
    width: 100%;
    float: left;
    display: none;
  }



  .consultText2 {
    width: 50px !important;
  }

  .sub_section_2 {
    width: 100%;
    float: right;
  }

  .go_to_cart_btn {
    height: 50px;
    padding: 10px 30px;
  }

  .health_package_left_side_section {
    padding: 24px 0px;
  }

  .popular_package_card_img {
    padding: 10px 18px;
  }

  .thumbnail-image {
    display: none;
  }

  .consultation_hide {
    display: none;
  }

  .item_list_box {
    display: none;
  }

  .medicine_footer {
    background: #ffffff;
    box-shadow: 0px -6px 21px rgba(4, 29, 66, 0.06);
    border-radius: 0px 0px 10px 10px;
    padding: 15px 10px;
    margin-left: -5.5px;
    width: 87%;
    bottom: 0;
    position: fixed;
  }

  .pagination_content {
    justify-content: left;
  }

  .radiology_booked_test_for_name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
    text-align: left;
  }

  .logo_box {
    padding: 50px 15px;
    margin-left: 3px;
    width: 90%;
  }

  .slider_1 {
    padding: 10px 30px 50px 30px;
    display: none;
  }

  .owl_banner_img_box {
    padding: 20px 0px;
    width: 100% !important;
    height: 100%;
    margin-left: -10px;
  }

  .otpInput {
    width: 30px;
    height: 30px;
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    padding: 10px;
    text-align: center;
    font-family: 'Inter';
    font-weight: 700;
    background: #F9FAFB;
  }

  .signup_box {
    padding: 90px 20px;
  }

  .signup_card {
    padding: 30px 30px;
    background: #fff;
    border-radius: 14px;
    height: 360px;
  }

  .signup_in_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #15181E;
    margin-bottom: 15px;
  }

  .signup_in_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-top: 0;
  }

  .i_agree_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #4D5764;
    position: absolute;
    margin-top: 1px;
    margin-left: 6px;
    display: contents;
  }

  .term_condition_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #199A8E;
    text-decoration: none;
  }

  .request_otp {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    width: -webkit-fill-available;
  }

  .slider_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #fff;
  }

  /*----------------------------------------------------------------------------------*/
  .verification_code_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    color: #15181E;
    margin-bottom: 0;
  }

  .verification_code_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .verification_number {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #199A8E;
    margin-bottom: 0;
    margin-top: 0;
  }

  .receive_the_code {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    color: #67696C;
    text-align: center;
  }

  .resend {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    color: #199A8E;
    text-decoration: none;
  }

  /*----------------------------------------------------------------------------------*/
  .signup_details_form_box {
    padding: 80px 10px;
  }

  .gender_option {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 14px 12px;
    justify-content: left;
    width: 55px;
    height: 70px;
  }

  .gender_icon {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .signup_details_box {
    padding: 20px 0px;
  }

  .gender_option_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #909196;
  }

  .gender_active {
    background: #199A8E !important;
    border-radius: 14px;
    width: 55px;
    position: absolute;
    margin-top: -31px;
    margin-left: -18px;
  }

  .gender_active_2 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 55px;
    position: absolute;
    margin-top: -31px;
    margin-left: -32px;
  }

  .gender_active_3 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 55px;
    position: absolute;
    margin-top: -31px;
    /*margin-left: -16px;*/
  }

  .gender_option_active_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  .common_pagination .next {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    /* width: -webkit-fill-available;
    margin-top: 300px; */
  }

  /*-----------------------Dashboard Home Start-------------------------*/
  .three_section_box {
    display: inline-grid;
    column-gap: 95px;
    row-gap: 20px;
    width: 100%;
  }

  .heath_problem_list_box {
    margin-top: 45px;
    display: grid;
    column-gap: 5px;
    grid-template-columns: 135px 135px;
    row-gap: 25px;
  }

  .home_bannerslide_img {
    width: -webkit-fill-available;
  }

  .btn-outline-secondary {
    border-right: 1px solid #DFDFDF;
    border-left: none;
    border-bottom: none;
    border-top: none;
    padding: 16px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #424448;
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-hover-bg: #F0F0F4;
    --bs-btn-active-color: #424448;
    --bs-btn-hover-color: #424448;
  }

  .city_search_dropdown {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 51px;
    color: #1d1929;
    background: #F0F0F4;
    border-radius: 14px 0 0 14px;
    width: 135px;
  }

  .city_search_input_box {
    background: #F0F0F4;
    border-radius: 14px;
    display: block;
    width: 100%;
  }

  .city_search_icon_input {
    padding: 21px 13px;
    margin-left: 7px;
    position: absolute;
  }

  .city_searchicon_box {
    width: 235px;
  }

  .consult_now_box {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: 100%;
  }

  .consult_now_box_2 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: 100%;
  }

  .consult_now_box_3 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: 100%;
  }

  .health_problem_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .sec_1_slider_text_box {
    padding: 30px 10px 25px 10px;
  }

  .healthproblem_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.3px;
    color: #424448;
    margin-top: 17px;
  }

  .city_search_input {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 54px;
    color: #1d1929;
    padding: 0px 14px 0px 40px;
    background: #F0F0F4;
    border-radius: 0 14px 14px 0;
  }

  .headerNav {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    font-family: "Inter", sans-serif;
    height: auto;
  }

  .login_signup_btn {
    display: none;
  }

  .nav_options_2 {
    margin-left: 10px;
  }

  .medicines_img {
    width: 64px;
    height: 78px;
    float: right;
  }

  .labtests_img {
    width: 61px;
    height: 83px;
    float: right;
  }

  .heath_problem_list_box_modal {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    row-gap: 20px;
    grid-template-columns: 50% 50%;
  }

  .heath_problem_list_box_model_map_sub {
    flex: 1 0 19.33%;
    max-width: 100%;
    padding: 0 10px;
  }

  .healthproblem_icon_bg_modal {
    background: rgba(25, 154, 142, 0.07);
    border-radius: 16px;
    padding: 25px 22px;
  }

  .healthproblem_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
  }

  /*-----------------------Dashboard Home End-------------------------*/
  /*------------------------------------Chat Screen Start------------------------------------*/
  .chat_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 30px;
  }

  .chat_modal_footer {
    display: inline-flex;
    column-gap: 5px;
  }

  .yesno_box {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    width: 220px;
  }

  .yes_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 14px 14px 0 0;
    display: flex;
    justify-content: space-between;
  }

  .no_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 0 0 14px 14px;
    display: flex;
    justify-content: space-between;
  }

  .chat_body_box_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .chat-message-list-inner {
    height: 100vh;
    overflow-y: scroll;
    padding: 30px 5px 24px 5px;
  }

  .chat_modal_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
  }

  .consult_time_btn_box {
    display: inline-flex;
    column-gap: 30px;
    margin-top: 25px;
    margin-bottom: 15px;
    margin-left: 15px;
  }

  .consult_info_box {
    padding-left: 15px;
    margin-top: 10px;
  }

  .consultant_info {
    display: block;
  }

  .available_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #199A8E;
    padding: 4px 10px;
    /* background: #FFECDA; */
    border-radius: 6px;
    margin-left: 0px;
  }

  .book_date_card_box {
    display: inline-flex;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .time_slot_card {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    padding: 5px 12px;
  }

  .time_slot_card_active {
    background: #FCEFE6;
    border: 1px solid #DF6128;
    border-radius: 14px;
    padding: 5px 12px;
  }

  .time_slot_card_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
  }

  .subscription_plan_modal_card {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    padding: 14px;
    display: block;
    width: 100%;
    justify-content: space-between;
  }

  .subscription_plan_modal_card1 {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    padding: 14px;
    display: block;
    width: 100%;
    justify-content: space-between;
  }


  /*-------------------------------------Chat Screen End-------------------------------------*/
  .subscription_body_box {
    padding: 0;
    display: inline-grid;
  }

  .subscription_body_box_card_body {
    background: #ffffff;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    border-radius: 0px 0px 0px 0px;
    padding: 0 15px;
  }

  .subscription_plan_card_active {
    background: #FCEFE6 !important;
    border: 1px solid #DF6129 !important;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    cursor: pointer;
  }

  .subscription_plan_card {
    background: #FFFFFF;
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 14px;
    padding: 14px;
    display: block;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    /* border: 1px solid #E2E2E6; */
  }

  .subscription_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .subscription_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .subscription_plan_box {
    width: 230px;
  }

  .subscription_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 15px;
  }

  .select_consult_member_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-left: 15px;
    margin-bottom: 0;
  }

  .subscription_plan_card {
    background: #FFFFFF;
    border-radius: 14px;
    padding: 14px;
    display: block;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    /* border: 1px solid #E2E2E6; */
  }

  .consult_member_box_inner {
    display: inline-flex;
    column-gap: 115px;
  }

  .consult_member_box_inner_2 {
    display: inline-flex;
    column-gap: 130px;
  }

  .consult_member_modal_box_inner {
    display: inline-flex;
    column-gap: 67px;
  }

  .consult_member_modal_box_inner_2 {
    display: inline-flex;
    column-gap: 83px;
  }

  /*--------------------------------Start Consultation start--------------------------------*/
  .payment_body_box {
    padding: 200px 30px;
  }

  .start_consultation_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .start_consultation_card_body {
    background: #ffffff;
    box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 0px;
  }

  .start_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .wating_box {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .start_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .start_consultation_card_head_2 {
    background: #ffffff;
    box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 134px;
    margin-top: 7px;
  }

  .chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .start_consultation_card_body_2 {
    background: #ffffff;
    box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  /*--------------------------------Start Consultation End--------------------------------*/
  /*---------------------------Start Consultation Chat Start---------------------------------*/
  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .start_consultation_chat_head_inner {
    display: inline-flex;
    float: right;
    column-gap: 18px;
    margin-top: 3px;
    margin-right: 0px;
  }

  .start_consult_user_img {
    width: 35px;
    height: 35px;
    border: 2px solid #199A8E;
    border-radius: 40px;
  }

  .start_consult_user_box {
    display: inline-flex;
    column-gap: 10px;
  }

  .consul_chat_more_icon {
    width: 25px;
    height: 25px;
  }

  .consult_chat_videocall_icon {
    width: 21px;
    height: 18px;
  }

  .consult_chat_call_icon {
    width: 18px;
    height: 18px;
  }

  /*----------------------------Start Consultation Chat End----------------------------------*/
  /*----------------------------Profile Dashboard Screen Start----------------------------*/
  .profiledash_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    margin-top: 15px;
    border-bottom: 1px solid #eceff4;
  }

  .profiledash_member_box {
    display: inline-grid;
    column-gap: 16px;
    grid-template-columns: 100%;
    width: 100%;
    row-gap: 16px;
  }

  .profiledash_member_card {
    background: #FFFFFF;
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 14px;
    padding: 14px;
    height: 80px;
  }

  /*-----------------------------Profile Dashboard Screen End-----------------------------*/
  /*-----------------------------Members Profile Screen Start-----------------------------*/
  .membersprofile_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 15px;
  }

  .membersprofile_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .membersprofile_delete_btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    padding: 8px 17px;
    margin-left: 10px;
  }

  .membersprofile_edit_btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #199A8E;
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    padding: 8px 20px;
  }

  .membersprofile_btn_box {
    float: right;
    display: inline-flex;
  }

  /*------------------------------Members Profile Screen End------------------------------*/
  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-------------------------Own Profile Consultation Start-------------------------*/
  .upcoming_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .past_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .upcoming_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .past_consultation_card_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #DB202B;
  }

  .past_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #199A8E;
  }

  .consultation_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
    display: grid;
    justify-items: center;
  }

  .cancel_appointment {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #DB202B;
  }

  .cancel_icon {
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .rating_star_box {
    float: left;
    display: block;
    margin-top: 20px;
    margin-right: 26px;
  }

  .consultation_cancel_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consultation_cancel_modal_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*--------------------------Own Profile Consultation End--------------------------*/
  /*---------------------------Upcoming Consultation Start---------------------------*/
  .upcoming_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .upcoming_consultation_chat_card_body {
    background: #ffffff;
    box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
    height: auto;
    margin-bottom: 20px;
  }

  .upcoming_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .upcoming_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .upcoming_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .upcoming_consultation_chat_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .upcoming_consultation_chat_card_head_2 {
    background: #ffffff;
    box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .upcoming_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .upcoming_consultation_chat_card_body_2 {
    background: #ffffff;
    box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    height: auto;
  }

  .upcoming_consultation_chat_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: auto;
  }

  .chat_card_box {
    display: none;
  }

  /*---------------------------Upcoming Consultation End---------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .completed_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 14px 15px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 14px 24px;
    width: 100%;
  }

  .completed_consult_chat_card_bg {
    box-shadow: none;
    border-radius: none;
    background: transparent;
  }

  /*------------------------Completed Consult Screen End------------------------*/
  /*-----------------------Canceled Consult Screen Start-----------------------*/
  .canceled_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .canceled_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .canceled_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .canceled_consultation_chat_card_body {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .canceled_consultation_chat_card_body_2 {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-left: 1px solid #ECEFF4;
  }

  .canceled_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .canceled_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #BE2121;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .canceled_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  /*------------------------Canceled Consult Screen End------------------------*/
  /*----------------------------Profile Test booking Screen Start----------------------------*/
  .profile_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .upcoming_test_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .consultation_test_option_box_1 {
    display: grid;
    float: left;
    margin-top: 14px;
    margin-left: 3px;
  }

  .consultation_test_complete_option_box_inner {
    padding-left: 0;
    padding-right: 22px;
  }

  .consultation_test_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 20px;
  }

  /*-----------------------------Profile Test booking Screen End-----------------------------*/
  /*----------------------------Test Completed details Screen Start----------------------------*/
  .test_completed_option_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 15px;
    margin-top: 15px;
  }

  .tracking-item {
    border-left: 2px dashed #199A8E;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .tracking-item-pending {
    border-left: 2px dashed #C4C4C4;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item-pending .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .track_dot_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -29px;
  }

  .track_dot_last_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -30px;
  }

  .track_dot {
    margin-left: 0px;
    width: 13px;
    margin-top: 0.7px;
  }

  /*-----------------------------Radiology booked details Screen Start-----------------------------*/
  .radiology_booked_option_box_inner {
    padding-left: 22px;
    padding-right: 10px;
  }

  .radiology_booked_test_profile_box {
    display: inline-flex;
    column-gap: 10px;
    margin-bottom: 20px;
  }

  .radiology_booked_option_box_1 {
    display: inline-flex;
    float: right;
    margin-top: 25px;
  }

  /*------------------------------Radiology booked details Screen End------------------------------*/
  /*-------------------------------My Consultation details Screen Start-------------------------------*/
  .my_consultation_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  /*--------------------------------My Consultation details Screen End--------------------------------*/
  /*-----------------------My Test Booking Screen Start-----------------------*/
  .my_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 23.5px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 33px;
  }

  /*------------------------My Test Booking Screen End------------------------*/
  /*-----------------------Medicine Orders Screen Start-----------------------*/
  .medicine_orders_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .medicine_order_box_inner {
    text-align: right;
    margin-top: 10px;
  }

  /*------------------------Medicine Orders Screen End------------------------*/
  /*-----------------------Health Package Screen Start-----------------------*/
  .health_package_Recommended {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #15181E;
  }

  .popular_package_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    margin-bottom: 20px;
    height: 240px;
  }

  .health_package_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .health_package_right_owl_slider_img {
    Width: 100%;
    Height: auto;
    border-radius: 14px;
  }

  .popular_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #15181E;
  }

  .health_package_container {
    background: #F5F5F5;
    padding: 30px 0px 50px 0;
  }

  .health_package_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .search input {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .search .results {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    width: -webkit-fill-available;
    padding: 20px 16px;
  }

  .lab_test_modal_box_inner_1 {
    padding: 0 10px 20px 10px;
  }

  .lab_test_modal_box_head_padding {
    padding: 25px 25px 10px 25px;
  }

  .lab_test_modal_box_2 {
    padding: 0 10px;
  }

  .health_package_left_padding {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .health_package_right_padding {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  /*------------------------Health Package Screen End------------------------*/
  /*------------------------Lab Test Cart Screen Start------------------------*/
  .lab_test_right_padding {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .lab_test_address {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3E4046;
    margin-bottom: 15px;
    margin-top: 0px;
  }

  .lab_test_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3E4046;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .lab_test_cart_left_side_2 {
    background: #fff;
    padding: 5px;
    border-radius: 0px 0px 0px 0px;
    border-top: 1px solid #ECEFF4;
    border-bottom: 1px solid #ECEFF4;
  }

  .lab_test_cart_right_side {
    background: #fff;
    padding: 24px;
    border-radius: 0px 12px 0px 0px;
    border-left: none;
  }

  .lab_test_left_padding {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .lab_test_cart_left_side {
    background: #fff;
    padding: 24px;
    border-radius: 12px 12px 0px 0px;
  }

  .lab_test_popup_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consult_member_box_1 {
    display: unset;
    padding: 0px 20px;
  }

  .book_date_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 5px;
  }

  .book_date_card_active {
    background: #FCEFE6 !important;
    border: 1px solid #DF6128;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    cursor: pointer;
  }

  .book_date_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .lab_sample_collection_add_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 0;
  }

  .lab_sample_collection_add_para_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 0;
  }

  .payment_done_popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  .payment_done_book_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #15181E;
    text-align: right;
    margin-bottom: 0;
  }

  .fullbody_package_box_inner {
    display: inline-flex;
    column-gap: 10px;
  }

  .fullbody_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
  }

  /*-------------------------Lab Test Cart Screen End-------------------------*/
  /*-------------------------Health Radiological tab Screen Start-------------------------*/
  .health_radiological_popup_list_box_inner {
    display: flex;
    column-gap: 10px;
  }

  .select_radiology_img {
    width: 60px;
  }

  .healthradiological_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .tab_details_head_img {
    width: 60px;
  }

  .healthradiological_tab_details_box {
    display: grid;
    justify-content: space-between;
  }

  .tab_details_view_map_btn {
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #199A8E;
    padding: 10px 19.5px 10px 19.5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /*--------------------------Health Radiological tab Screen End--------------------------*/
  /*--------------------------Wallet Screen Start--------------------------*/
  .wallet_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .wallet_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .wallet_card_body_box {
    margin-top: 0px;
    margin-bottom: 25px;
  }

  .wallet_card_body_value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #424448;
    margin-bottom: 5px;
  }

  .wallet_card_body_for {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*---------------------------Wallet Screen End---------------------------*/
  /*--------------------------Address Screen Start--------------------------*/
  .address_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .address_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /*---------------------------Address Screen End---------------------------*/
  /*--------------------------My Subscription Screen Start--------------------------*/
  .my_subscription_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_subscription_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .address_body_card_head_add_icon {
    margin-right: 10px;
    margin-top: -3px;
  }

  .address_body_card_head_new_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    margin-top: 15px;
    float: right;
  }

  .my_subscription_card_inner {
    display: grid;
    justify-content: space-between;
    row-gap: 25px;
  }

  /*---------------------------My Subscription Screen End---------------------------*/
  /*--------------------------Subscription Package Screen Start--------------------------*/
  .subscription_package_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .subscription_package_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #424448;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .subscription_package_members_box {
    display: grid;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .subscription_package_members_profile_box {
    display: grid;
    column-gap: 25px;
    grid-template-columns: 50% 50%;
    row-gap: 20px;
    margin-bottom: 25px;
  }

  .subscription_package_body_card_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    float: left;
    margin-top: 5px;
  }

  .subscription_package_consultation_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  .subscription_package_consultation_complete_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #199A8E;
    margin-bottom: 0;
  }

  .subscription_package_consultation_cancel_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #DB202B;
    margin-bottom: 0;
  }

  .subscription_package_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_rating_star_box {
    float: left;
    margin-top: 20px;
    margin-right: 26px;
  }

  .payment_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 10px;
  }

  /*---------------------------Subscription Package Screen End---------------------------*/
  /*--------------------------Follow Up Consultation Screen Start--------------------------*/
  .follow_up_upcoming_consultation_box {
    display: grid;
    column-gap: 24px;
    row-gap: 25px;
  }

  .follow_up_consult_width {
    width: -webkit-fill-available;
  }

  .follow_up_test_width {
    width: auto;
  }
  .follow_up_test_widths {
    width: auto;
  }


  .follow_up_upcoming_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .follow_up_upcoming_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  /*---------------------------Follow Up Consultation Screen End---------------------------*/
  /*--------------------------Follow Up Subscription Plan Screen Start--------------------------*/
  .follow_up_subscription_plan_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .follow_up_subscription_plan_body_box {
    padding: 0;
    display: inline-grid;
  }

  .follow_up_subscription_plan_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .follow_up_subscription_plan_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .follow_up_subscription_plan_book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .follow_up_subscription_plan_box_inner {
    width: 225px;
  }

  .popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  /*---------------------------Follow Up Subscription Plan Screen End---------------------------*/
  /*--------------------------medicine details Screen Start--------------------------*/
  .medicine_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: 100%;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .medicine_slider {
    display: flex;
    float: left;
    grid-column-gap: 0px;
    border-right: 0;
    border-bottom: 1px solid #ECEFF4;
    padding: 0px;
  }

  .medicine_slider_box_active {
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #199A8E;
    border-radius: 6px;
    margin-top: 10px;
  }

  .medicine_slider_box {
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 6px;
    margin-top: 10px;
  }

  .product_details {
    padding: 0;
  }

  .product_details_para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #67696C;
    align-items: center;
    text-align: justify;
  }

  .medicine_slider_img {
    width: 100%;
    height: 200px;
  }

  /*---------------------------medicine details Screen End---------------------------*/
  /*---------------------------Test completed details Screen start---------------------------*/
  .order-tracking {
    text-align: center;
    width: 24.33%;
    position: relative;
    display: block;
  }

  .booking_placed_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #909196;
    margin-top: -40px;
  }

  .booking_placed_title.active {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #199A8E;
    margin-top: -40px;
  }

  .booking_cancelled_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #DB202B;
    margin-top: -40px;
  }

  .order-tracking p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 6px;
    color: #A4A4A4;
    margin-top: 30px;
    margin-bottom: 0;
    line-height: 10px;
  }

  .cancellation_reason_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #15181E;
    margin-bottom: 0;
  }

  .went_offline_lab_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #424448;
    margin-bottom: 0;
  }

  .your_refund_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #ef861a;
    margin-bottom: 0;
  }

  .order-tracking.completed p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 6px;
    line-height: 9px;
    color: #909196;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .chat_card_box {
    display: none;
  }

  .subscription_container {
    margin-top: -70px;
  }

  .health_problem_head {
    height: 100%;
  }

  /*---------------------------Test completed details Screen End---------------------------*/


}

@media only screen and (min-width: 375px) and (max-width: 405px) {
  .health_problem_head {
    height: 100%;
  }

  .consultText2 {
    width: 80px !important;
  }

  .section_1 {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -30px;
  }

  .section_2 {
    background: #fff;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: -70px;
  }

  .dashboard-owl-slider.owl-theme .owl-dots .owl-dot span {
    width: 4px !important;
    height: 4px !important;

  }

  .go_to_cart_btn {
    height: 50px;
    padding: 10px 30px;
  }

  .medicine_footer {
    background: #ffffff;
    box-shadow: 0px -6px 21px rgba(4, 29, 66, 0.06);
    border-radius: 0px 0px 10px 10px;
    padding: 15px 10px;
    margin-left: -5.5px;
    width: 87%;
    bottom: 0;
    position: fixed;
  }

  .pagination_content {
    justify-content: left;
  }

  /* .display_inline{
  display:none;
} */
  .medicine_card_box {
    height: 1025px;
  }

  .radiology_booked_test_for_name {
    margin-left: -10px;
  }

  .radiology_booked_test_for_name2 {
    margin-left: 0px;
  }

  .radiology_booked_test_for_amount {
    margin-left: 0px;
    width: 200px;
  }

  .ownprofile_info_box_value {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
    word-wrap: break-word;
  }

  .consult_now_btn_2 {
    background: #199a8e;
    border-radius: 9px;
    border: none;
    text-transform: uppercase;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
    padding: 12px 25px;
    margin-left: 38px;
  }

  .item_list_box {
    display: none;
  }

  .consultant_doc_img {
    margin-top: 13px;
  }

  .health_package_left_side_section {
    padding: 24px 0px;
  }

  .popular_package_card_img {
    padding: 10px 18px;
  }

  .chat_body_box_card_body {
    background: #ffffff;
    box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
    border-radius: 0 0 12px 12px;
    padding: 25px;
    margin-top: -40px;
  }

  .sub_section_1 {
    position: relative;
    width: 100%;
    float: left;
    display: none;
  }

  .sub_section_2 {
    width: 100%;
    float: right;
  }

  .logo_box {
    padding: 50px 15px;
    margin-left: 3px;
    width: 90%;
  }

  .slider_1 {
    padding: 10px 30px 50px 30px;
  }

  .owl_banner_img_box {
    padding: 20px 0px;
    width: 280px !important;
    height: 320px;
    margin-left: -10px;
  }

  .owl-item img.benner {
    height: 100%;
  }

  .signup_box {
    padding: 90px 20px;
  }

  .popular_package {
    margin-left: -5px;
  }

  .signup_card {
    padding: 30px 30px;
    background: #fff;
    border-radius: 14px;
    height: 360px;
  }

  .signup_in_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #15181E;
    margin-bottom: 15px;
  }

  .signup_in_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-top: 0;
  }

  .i_agree_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #4D5764;
    position: absolute;
    margin-top: 1px;
    margin-left: 6px;
  }

  .term_condition_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #199A8E;
    text-decoration: none;
  }

  .request_otp {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    width: -webkit-fill-available;
  }

  .slider_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #fff;
  }

  /*----------------------------------------------------------------------------------*/
  .verification_code_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    color: #15181E;
    margin-bottom: 0;
  }

  .verification_code_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .verification_number {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #199A8E;
    margin-bottom: 0;
    margin-top: 0;
  }

  .receive_the_code {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    color: #67696C;
    text-align: center;
  }

  .resend {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    color: #199A8E;
    text-decoration: none;
  }

  /*----------------------------------------------------------------------------------*/
  .signup_details_form_box {
    padding: 80px 10px;
  }

  .gender_option {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 14px 12px;
    justify-content: left;
    width: 64px;
    height: 75px;
  }

  .gender_icon {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .signup_details_box {
    padding: 20px 0px;
  }

  .gender_option_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #909196;
  }

  .gender_active {
    background: #199A8E !important;
    border-radius: 14px;
    width: 64px;
    position: absolute;
    margin-top: -32px;
    margin-left: -18px;
  }

  .gender_active_2 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 64px;
    position: absolute;
    margin-top: -32px;
    margin-left: -32px;
  }

  .gender_active_3 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 64px;
    position: absolute;
    margin-top: -32px;
    margin-left: -16px;
  }

  .gender_option_active_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  .common_pagination .next {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    /* width: -webkit-fill-available;
    margin-top: 300px; */
  }

  /*-----------------------Dashboard Home Start-------------------------*/
  .three_section_box {
    display: inline-grid;
    column-gap: 95px;
    row-gap: 20px;
    width: 99.5%;
  }

  .heath_problem_list_box {
    margin-top: 45px;
    display: grid;
    column-gap: 10%;
    grid-template-columns: 45% 45%;
    row-gap: 25px;
  }

  .healthproblem_icon_bg {
    background: rgba(25, 154, 142, 0.07);
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 16px;
    width: 160px;
    padding: 45px 35px;
  }

  .home_bannerslide_img {
    width: -webkit-fill-available;
  }

  .btn-outline-secondary {
    border-right: 1px solid #DFDFDF;
    border-left: none;
    border-bottom: none;
    border-top: none;
    padding: 16px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #424448;
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-hover-bg: #F0F0F4;
    --bs-btn-active-color: #424448;
    --bs-btn-hover-color: #424448;
  }

  .city_search_dropdown {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 51px;
    color: #1d1929;
    background: #F0F0F4;
    border-radius: 14px 0 0 14px;
    width: 135px;
  }

  .city_search_input_box {
    background: #F0F0F4;
    border-radius: 14px;
    display: inline-flex;
    width: 100%;
  }

  .city_search_icon_input {
    padding: 18px 13px;
    position: absolute;
  }

  .city_searchicon_box {
    width: 235px;
  }

  .consult_now_box {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_2 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_3 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .health_problem_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 10px;
    margin-top: 5px;
  }

  .sec_1_slider_text_box {
    padding: 30px 10px 25px 10px;
  }

  .healthproblem_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.3px;
    color: #424448;
    margin-top: 17px;
  }

  .city_search_input {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 54px;
    color: #1d1929;
    padding: 0px 14px 0px 40px;
    background: #F0F0F4;
    border-radius: 0 14px 14px 0;
  }

  .headerNav {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    font-family: "Inter", sans-serif;
    height: auto;
  }

  .login_signup_btn {
    display: none;
  }

  .nav_options_2 {
    margin-left: 10px;
  }

  .medicines_img {
    width: 64px;
    height: 78px;
    float: right;
  }

  .labtests_img {
    width: 61px;
    height: 83px;
    float: right;
  }

  .consult_now_img {
    width: 119px;
    height: 95px;
    float: right;
  }

  .heath_problem_list_box_modal {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    /* column-gap: 15px; */
    row-gap: 20px;
    grid-template-columns: 50% 50%;
  }

  .healthproblem_icon_bg_modal {
    background: rgba(25, 154, 142, 0.07);
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 16px;
    padding: 25px 22px;
  }

  .heath_problem_list_box_model_map_sub {
    flex: 1 0 19.33%;
    max-width: 100%;
    padding: 0 10px;
  }

  .healthproblem_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
  }

  /*-----------------------Dashboard Home End-------------------------*/
  /*------------------------------------Chat Screen Start------------------------------------*/
  .chat_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 30px;
  }

  .yesno_box {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    width: 220px;
  }

  .yes_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 14px 14px 0 0;
    display: flex;
    justify-content: space-between;
  }

  .no_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 0 0 14px 14px;
    display: flex;
    justify-content: space-between;
  }

  .chat_body_box_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .chat-message-list-inner {
    height: 100vh;
    overflow-y: scroll;
    padding: 30px 5px 24px 5px;
  }

  .chat_modal_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
  }

  .consult_time_btn_box {
    display: inline-flex;
    column-gap: 30px;
    margin-top: 25px;
    margin-bottom: 15px;
    margin-left: 15px;
  }

  .consult_info_box {
    padding-left: 15px;
    margin-top: 10px;
  }

  .consultant_info {
    display: block;
  }

  .available_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #199A8E;
    padding: 4px 10px;
    /* background: #FFECDA; */
    border-radius: 6px;
    margin-left: 0px;
  }

  .available_time2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #199A8E;
    /* padding: 4px 10px; */
    /* background: #FFECDA; */
    border-radius: 6px;
    margin-left: -10px;
  }

  .consult_time_btn_box2 {
    display: inline-flex;
    column-gap: 30px;
    margin-top: 25px;
    margin-bottom: 15px;
    margin-left: 0px;
  }

  .book_date_card_box {
    display: inline-flex;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .time_slot_card {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    padding: 5px 12px;
  }

  .time_slot_card_active {
    background: #FCEFE6;
    border: 1px solid #DF6128;
    border-radius: 14px;
    padding: 5px 12px;
  }

  .time_slot_card_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
  }

  .time_slot_card_box {
    display: inline-grid;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 5px;
    grid-template-columns: 33.33% 33.33% 33.33%;
    row-gap: 15px;
  }

  .time_slot_btn {
    margin-top: 20px;
    width: 89%;
  }

  /*-------------------------------------Chat Screen End-------------------------------------*/
  .subscription_body_box {
    padding: 0;
    display: inline-grid;
  }

  .subscription_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .subscription_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    width: 120%;
    color: #FBFCFC;
  }

  .subscription_plan_box {
    width: 230px;
  }

  .subscription_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 15px;
  }

  .select_consult_member_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-left: 15px;
    margin-bottom: 0;
  }

  .consult_modal_btn {
    width: 100%;
  }

  .mb_25 {
    margin-bottom: 12.5px;
  }

  .my_subscription_card_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 1px;
  }

  .search {
    width: 100%;
  }

  .health_package_lab_test {
    margin-top: -20px;
  }

  .subscription_plan_card_active {
    background: #FCEFE6 !important;
    border: 1px solid #DF6129 !important;
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 14px;
    cursor: pointer;
  }

  .subscription_plan_card {
    background: #FFFFFF;
    border-radius: 14px;
    padding: 14px;
    display: block;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    /* border: 1px solid #E2E2E6; */
  }

  .consult_member_box_inner {
    display: inline-flex;
    column-gap: 115px;
  }

  .consult_member_box_inner_2 {
    display: inline-flex;
    column-gap: 130px;
  }

  .consult_member_modal_box_inner {
    display: inline-flex;
    column-gap: 67px;
  }

  .consult_member_modal_box_inner_2 {
    display: inline-flex;
    column-gap: 83px;
  }

  .lab_sample_apply_coupon_list_box {
    padding: 24px 0 0 0;
    width: 98%;
  }

  .book_consult_card {
    background: #ffffff;
    box-shadow: 0px -6px 21px rgba(4, 29, 66, 0.06);
    border-radius: 0px 0px 10px 10px;
    padding: 15px 10px;
    margin-left: -20px;
    justify-content: space-around;
    display: flex;
  }

  /*--------------------------------Start Consultation start--------------------------------*/
  .payment_body_box {
    padding: 200px 30px;
  }

  .start_consultation_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .start_consultation_card_body {
    background: #ffffff;
    box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 0px;
  }

  .start_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .wating_box {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .start_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .start_consultation_card_head_2 {
    background: #ffffff;
    box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 134px;
    margin-top: 7px;
  }

  .chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .start_consultation_card_body_2 {
    background: #ffffff;
    box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  /*--------------------------------Start Consultation End--------------------------------*/
  /*---------------------------Start Consultation Chat Start---------------------------------*/
  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .start_consultation_chat_head_inner {
    display: inline-flex;
    float: right;
    column-gap: 18px;
    margin-top: 3px;
    margin-right: 0px;
  }

  .medicine_orders_card_head_status_processing {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-align: left;
    color: #df6129;
    width: 150px;
    margin-left: -20px;
    margin-bottom: 0;
  }

  .my_test_profile_icon_2 {
    display: none;
  }

  .start_consult_user_img {
    width: 35px;
    height: 35px;
    border: 2px solid #199A8E;
    border-radius: 40px;
  }

  .start_consult_user_box {
    display: inline-flex;
    column-gap: 10px;
  }

  .consul_chat_more_icon {
    width: 25px;
    height: 25px;
  }

  .consult_chat_videocall_icon {
    width: 21px;
    height: 18px;
  }

  .consult_chat_call_icon {
    width: 18px;
    height: 18px;
  }

  /*----------------------------Start Consultation Chat End----------------------------------*/
  /*----------------------------Profile Dashboard Screen Start----------------------------*/
  .profiledash_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    margin-top: 15px;
    border-bottom: 1px solid #eceff4;
  }

  .profiledash_member_box {
    display: inline-grid;
    column-gap: 16px;
    grid-template-columns: 100%;
    width: 100%;
    row-gap: 16px;
  }

  .profiledash_member_card {
    background: #FFFFFF;
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 14px;
    padding: 14px;
    height: 80px;
  }

  .profiledash_member_card_border {
    height: 108px;
  }

  /*-----------------------------Profile Dashboard Screen End-----------------------------*/
  /*-----------------------------Members Profile Screen Start-----------------------------*/
  .membersprofile_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 15px;
  }

  .membersprofile_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .membersprofile_delete_btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    padding: 8px 17px;
    margin-left: 10px;
  }

  .membersprofile_edit_btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #199A8E;
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    padding: 8px 20px;
  }

  .membersprofile_btn_box {
    float: right;
    display: inline-flex;
  }

  /*------------------------------Members Profile Screen End------------------------------*/
  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-------------------------Own Profile Consultation Start-------------------------*/
  .upcoming_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .past_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .upcoming_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .past_consultation_card_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #DB202B;
  }

  .past_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #199A8E;
  }

  .consultation_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
    display: grid;
    justify-items: center;
  }

  .cancel_appointment {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #DB202B;
  }

  .cancel_icon {
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .rating_star_box {
    float: left;
    margin-top: 20px;
    margin-right: 26px;
  }

  .consultation_cancel_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consultation_cancel_modal_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /* .chat_card_box {
      display: none;
    } */
  .consultation_hide {
    display: none;
  }

  .subscription_container {
    margin-top: -50px;
  }

  /*--------------------------Own Profile Consultation End--------------------------*/
  /*---------------------------Upcoming Consultation Start---------------------------*/
  .upcoming_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .upcoming_consultation_chat_card_body {
    background: #ffffff;
    box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
    height: auto;
    margin-bottom: 20px;
  }

  .upcoming_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .upcoming_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .upcoming_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .upcoming_consultation_chat_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .upcoming_consultation_chat_card_head_2 {
    background: #ffffff;
    box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .upcoming_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .upcoming_consultation_chat_card_body_2 {
    background: #ffffff;
    box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    height: auto;
  }

  .upcoming_consultation_chat_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: auto;
  }

  /*---------------------------Upcoming Consultation End---------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .completed_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 165px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 14px 15px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 14px 24px;
    width: 100%;
  }

  .completed_consult_chat_card_bg {
    box-shadow: none;
    border-radius: none;
    background: transparent;
  }

  /*------------------------Completed Consult Screen End------------------------*/
  /*-----------------------Canceled Consult Screen Start-----------------------*/
  .canceled_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .canceled_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .canceled_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .canceled_consultation_chat_card_body {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .canceled_consultation_chat_card_body_2 {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .canceled_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #BE2121;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .canceled_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  /*------------------------Canceled Consult Screen End------------------------*/
  /*----------------------------Profile Test booking Screen Start----------------------------*/
  .profile_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .profile_test_body_box_card_body {
    background: #ffffff;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    border-radius: 12px;
    padding: 25px;
    height: 100%;
    margin-top: 10px;
  }

  .upcoming_test_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .consultation_test_option_box_1 {
    display: grid;
    float: left;
    margin-top: 14px;
    margin-left: 3px;
  }

  .consultation_test_complete_option_box_inner {
    padding-left: 0;
    padding-right: 22px;
  }

  .consultation_test_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 20px;
  }

  /*-----------------------------Profile Test booking Screen End-----------------------------*/
  /*----------------------------Test Completed details Screen Start----------------------------*/
  .test_completed_option_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 15px;
    margin-top: 15px;
  }

  .tracking-item {
    border-left: 2px dashed #199A8E;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .tracking-item-pending {
    border-left: 2px dashed #C4C4C4;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item-pending .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .track_dot_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -29px;
  }

  .track_dot_last_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -30px;
  }

  .track_dot {
    margin-left: 0px;
    width: 13px;
    margin-top: 0.7px;
  }

  /*-----------------------------Test Completed details Screen End-----------------------------*/
  /*-----------------------------Radiology booked details Screen Start-----------------------------*/
  .radiology_booked_option_box_inner {
    padding-left: 22px;
    padding-right: 10px;
  }

  .radiology_booked_test_profile_box {
    display: inline-flex;
    column-gap: 10px;
    margin-bottom: 20px;
  }

  .radiology_booked_option_box_1 {
    display: inline-flex;
    float: right;
    margin-top: 25px;
  }

  /*------------------------------Radiology booked details Screen End------------------------------*/
  /*-------------------------------My Consultation details Screen Start-------------------------------*/
  .my_consultation_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  /*--------------------------------My Consultation details Screen End--------------------------------*/
  /*-----------------------My Test Booking Screen Start-----------------------*/
  .my_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 23.5px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 33px;
  }

  /*------------------------My Test Booking Screen End------------------------*/
  /*-----------------------Medicine Orders Screen Start-----------------------*/
  .medicine_orders_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .medicine_order_box_inner {
    text-align: right;
    margin-top: 10px;
  }

  /*------------------------Medicine Orders Screen End------------------------*/
  /*-----------------------Health Package Screen Start-----------------------*/
  .health_package_Recommended {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #15181E;
  }

  .popular_package_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    margin-bottom: 20px;
    height: 240px;
    padding: 0rem;
  }

  .health_package_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .health_package_right_owl_slider_img {
    Width: 100%;
    Height: auto;
    border-radius: 14px;
  }

  .popular_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #15181E;
  }

  .health_package_container {
    background: #F5F5F5;
    padding: 30px 0px 50px 0;
  }

  .health_package_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .search input {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    margin-left: -5px;
    padding: 0px 14px 0px 50px;
  }

  .lab_member_modal_btn {
    margin-top: 0px;
    width: 100%;
  }

  .lab_test_modal_footer_subtitle {
    margin-left: -4px;
  }

  .lab_test_modal_footer_btn {
    margin-right: -15px;
  }

  .add_member_box {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .lab_test_for_test_icon {
    width: 20px;
    height: 20px;
  }

  .search .results {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    width: -webkit-fill-available;
    padding: 20px 16px;
  }

  .lab_test_modal_box_inner_1 {
    padding: 0 10px 20px 10px;
  }

  .lab_test_modal_box_head_padding {
    padding: 25px 25px 10px 25px;
  }

  .lab_test_modal_box_2 {
    padding: 0 10px;
  }

  .health_package_left_padding {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  /* .health_package_right_padding {
      padding-right: calc(var(--bs-gutter-x) * .5);
    } */
  /*------------------------Health Package Screen End------------------------*/
  /*------------------------Lab Test Cart Screen Start------------------------*/
  .lab_test_right_padding {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .lab_test_address {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3E4046;
    margin-bottom: 15px;
    margin-top: 0px;
  }

  .lab_test_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3E4046;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .lab_test_cart_left_side_2 {
    background: #fff;
    padding: 24px;
    margin-top: -20px;
    border-radius: 0px 0px 0px 0px;
    border-top: 1px solid #ECEFF4;
    border-bottom: 1px solid #ECEFF4;
  }

  .lab_test_cart_right_side {
    background: #fff;
    padding: 24px;
    border-radius: 0px 12px 0px 0px;
    border-left: none;
    /* padding-top:10px; */
  }

  .profileName {
    font-size: 16px;
  }

  .dropdown-item {
    font-size: 15px;
    line-height: 30px;
  }

  .thumbnail-image {
    display: none;
  }

  .lab_test_footer_card {
    margin-top: -35px;
  }

  .error_text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #df6129;
    text-align: left !important;
    margin-top: -5px;
    /* margin-left: 14px; */
    margin-bottom: 0;
    padding-bottom: 0px;
  }

  /* .lab_test_price_box{
      margin-right:70px;
    } */
  .lab_test_footer_card {
    column-gap: 60px;
    justify-content: space-between;
  }

  .lab_test_footer_card2 {
    column-gap: 0px;
    justify-content: space-between;
    margin-top: -60px;
  }

  .lab_test_card_box2 {
    margin-left: 20px;
  }

  /* .lab_test_btn2{
    margin-left: 30px;
   } */
  .lab_test_btn {
    margin-left: 40px;
  }

  .lab_test_left_padding {
    padding-left: calc(var(--bs-gutter-x) * .5);
    padding-bottom: 10px;
  }

  .lab_test_cart_left_side {
    background: #fff;
    padding: 24px;
    border-radius: 12px 12px 0px 0px;
  }

  .lab_test_popup_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consult_member_box_1 {
    display: unset;
    padding: 0px 20px;
  }

  .book_date_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 5px;
  }

  .book_date_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .book_date_card_active {
    background: #FCEFE6;
    border: 1px solid #DF6128;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    cursor: pointer;
    height: 35px;
  }

  .book_date_card_text {
    text-align: center;
    line-height: 13px;
  }

  .lab_sample_collection_add_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 0;
  }

  .lab_sample_collection_add_para_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 0;
  }

  .payment_done_popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  .payment_done_book_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #15181E;
    text-align: right;
    margin-bottom: 0;
  }

  .fullbody_package_box_inner {
    display: inline-flex;
    column-gap: 10px;
  }

  .fullbody_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
  }

  /*-------------------------Lab Test Cart Screen End-------------------------*/
  /*-------------------------Health Radiological tab Screen Start-------------------------*/
  .health_radiological_popup_list_box_inner {
    display: flex;
    column-gap: 10px;
  }

  .select_radiology_img {
    width: 60px;
  }

  .healthradiological_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .tab_details_head_img {
    width: 60px;
  }

  .healthradiological_tab_details_box {
    display: grid;
    justify-content: space-between;
  }

  .tab_details_view_map_btn {
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #199A8E;
    padding: 10px 19.5px 10px 19.5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /*--------------------------Health Radiological tab Screen End--------------------------*/
  /*--------------------------Wallet Screen Start--------------------------*/
  .wallet_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .wallet_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .wallet_card_body_box {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .wallet_card_body_value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #424448;
    margin-bottom: 5px;
  }

  .wallet_card_body_for {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*---------------------------Wallet Screen End---------------------------*/
  /*--------------------------Address Screen Start--------------------------*/
  .address_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .address_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /*---------------------------Address Screen End---------------------------*/
  /*--------------------------My Subscription Screen Start--------------------------*/
  .my_subscription_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_subscription_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .address_body_card_head_add_icon {
    margin-right: 10px;
    margin-top: -3px;
  }

  .address_body_card_head_new_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    margin-top: 15px;
    float: right;
  }

  .my_subscription_card_inner {
    display: grid;
    justify-content: space-between;
    row-gap: 25px;
  }

  .payment_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 10px;
  }

  /*---------------------------My Subscription Screen End---------------------------*/
  /*--------------------------Subscription Package Screen Start--------------------------*/
  .subscription_package_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .subscription_package_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #424448;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .subscription_package_members_box {
    display: grid;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .subscription_package_members_profile_box {
    display: grid;
    column-gap: 25px;
    grid-template-columns: 50% 50%;
    row-gap: 20px;
    margin-bottom: 25px;
  }

  .subscription_package_body_card_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    float: left;
    margin-top: 5px;
  }

  .subscription_package_consultation_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 0;
    display: contents;
  }

  .subscription_package_consultation_complete_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #199A8E;
    margin-bottom: 0;
  }

  .subscription_package_consultation_cancel_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #DB202B;
    margin-bottom: 0;
  }

  .subscription_package_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_rating_star_box {
    float: left;
    margin-top: 20px;
    margin-right: 26px;
  }

  /*---------------------------Subscription Package Screen End---------------------------*/
  /*--------------------------Follow Up Consultation Screen Start--------------------------*/
  .follow_up_upcoming_consultation_box {
    display: grid;
    column-gap: 24px;
    row-gap: 25px;
  }

  .follow_up_consult_width {
    width: -webkit-fill-available;
  }

  .follow_up_test_width {
    width: auto;
  }
  .follow_up_test_widths {
    width: auto;
  }

  .follow_up_upcoming_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .follow_up_upcoming_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  /*---------------------------Follow Up Consultation Screen End---------------------------*/
  /*--------------------------Follow Up Subscription Plan Screen Start--------------------------*/
  .follow_up_subscription_plan_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .follow_up_subscription_plan_body_box {
    padding: 0;
    display: inline-grid;
  }

  .follow_up_subscription_plan_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .follow_up_subscription_plan_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .follow_up_subscription_plan_book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .follow_up_subscription_plan_box_inner {
    width: 225px;
  }

  .popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  /*---------------------------Follow Up Subscription Plan Screen End---------------------------*/
  /*--------------------------medicine details Screen Start--------------------------*/
  .medicine_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: 100%;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .medicine_slider {
    display: flex;
    float: left;
    grid-column-gap: 0px;
    border-right: 0;
    border-bottom: 1px solid #ECEFF4;
    padding: 0px;
  }

  .medicine_slider_box_active {
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #199A8E;
    border-radius: 6px;
    margin-top: 10px;
  }

  .medicine_slider_box {
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 6px;
    margin-top: 10px;
  }

  .product_details {
    padding: 0;
  }

  .product_details_para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #67696C;
    align-items: center;
    text-align: justify;
  }

  .medicine_slider_img {
    width: 100%;
    height: 200px;
  }

  /*---------------------------medicine details Screen End---------------------------*/
  /*---------------------------Test completed details Screen start---------------------------*/
  .order-tracking {
    text-align: center;
    width: 24.33%;
    position: relative;
    display: block;
  }

  .booking_placed_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #909196;
    margin-top: -40px;
  }

  .booking_placed_title.active {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #199A8E;
    margin-top: -40px;
  }

  .booking_cancelled_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #DB202B;
    margin-top: -40px;
  }

  .order-tracking.completed p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 6px;
    line-height: 9px;
    color: #909196;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .order-tracking p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 6px;
    color: #A4A4A4;
    margin-top: 30px;
    margin-bottom: 0;
    line-height: 9px;
  }

  .cancellation_reason_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #15181E;
    margin-bottom: 0;
  }

  .went_offline_lab_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: #424448;
    margin-bottom: 0;
  }

  .your_refund_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #ef861a;
    margin-bottom: 0;
  }

  /*---------------------------Test completed details Screen End---------------------------*/

}

@media only screen and (min-width: 406px) and (max-width: 459px) {
  .sub_section_1 {
    position: relative;
    width: 100%;
    float: left;
    display: none;
  }

  .consultText2 {
    width: 110px !important;
  }

  .sub_section_2 {
    width: 100%;
    float: right;
  }

  .logo_box {
    padding: 50px 15px;
    margin-left: 3px;
    width: 90%;
  }

  .slider_1 {
    padding: 10px 30px 50px 30px;
  }

  .owl_banner_img_box {
    padding: 20px 0px;
    width: 300px !important;
    height: 340px;
    margin-left: -10px;
  }

  .signup_box {
    padding: 90px 20px;
  }

  .signup_card {
    padding: 30px 30px;
    background: #fff;
    border-radius: 14px;
  }

  .signup_in_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #15181E;
    margin-bottom: 15px;
  }

  .signup_in_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-top: 0;
  }

  .i_agree_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #4D5764;
    position: absolute;
    margin-top: 1px;
    margin-left: 6px;
  }

  .term_condition_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #199A8E;
    text-decoration: none;
  }

  .request_otp {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    width: -webkit-fill-available;
  }

  .slider_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    color: #fff;
  }

  /*----------------------------------------------------------------------------------*/
  .verification_code_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 135%;
    text-align: center;
    color: #15181E;
    margin-bottom: 0;
  }

  .verification_code_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .verification_number {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #199A8E;
    margin-bottom: 0;
    margin-top: 0;
  }

  .receive_the_code {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    align-items: center;
    color: #67696C;
    text-align: center;
  }

  .resend {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    align-items: center;
    color: #199A8E;
    text-decoration: none;
  }

  /*----------------------------------------------------------------------------------*/
  .signup_details_form_box {
    padding: 80px 10px;
  }

  .gender_option {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 14px 12px;
    justify-content: left;
    width: 64px;
    height: 75px;
  }

  .gender_icon {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .signup_details_box {
    padding: 20px 0px;
  }

  .gender_option_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #909196;
  }

  .gender_active {
    background: #199A8E !important;
    border-radius: 14px;
    width: 64px;
    position: absolute;
    margin-top: -32px;
    margin-left: -18px;
  }

  .gender_active_2 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 64px;
    position: absolute;
    margin-top: -32px;
    margin-left: -32px;
  }

  .gender_active_3 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 64px;
    position: absolute;
    margin-top: -32px;
    margin-left: -16px;
  }

  .gender_option_active_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  .common_pagination .next {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    /* width: -webkit-fill-available;
    margin-top: 300px; */
  }

  /*-----------------------Dashboard Home Start-------------------------*/
  .three_section_box {
    display: inline-grid;
    column-gap: 95px;
    row-gap: 20px;
    width: 99.5%;
  }

  .heath_problem_list_box {
    margin-top: 45px;
    display: grid;
    column-gap: 10%;
    grid-template-columns: 45% 45%;
    row-gap: 25px;
  }

  .healthproblem_icon_bg {
    background: rgba(25, 154, 142, 0.07);
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 16px;
    padding: 55px 35px;
  }

  .home_bannerslide_img {
    width: -webkit-fill-available;
  }

  .btn-outline-secondary {
    border-right: 1px solid #DFDFDF;
    border-left: none;
    border-bottom: none;
    border-top: none;
    padding: 16px 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #424448;
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-hover-bg: #F0F0F4;
    --bs-btn-active-color: #424448;
    --bs-btn-hover-color: #424448;
  }

  .city_search_dropdown {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 51px;
    color: #1d1929;
    background: #F0F0F4;
    border-radius: 14px 0 0 14px;
    width: 135px;
  }

  .city_search_input_box {
    background: #F0F0F4;
    border-radius: 14px;
    display: inline-flex;
    width: 100%;
  }

  .city_search_icon_input {
    padding: 18px 13px;
    position: absolute;
  }

  .city_searchicon_box {
    width: 235px;
  }

  .consult_now_box {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_2 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_3 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .health_problem_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .sec_1_slider_text_box {
    padding: 30px 10px 25px 10px;
  }

  .healthproblem_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.3px;
    color: #424448;
    margin-top: 17px;
  }

  .city_search_input {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 54px;
    color: #1d1929;
    padding: 0px 14px 0px 40px;
    background: #F0F0F4;
    border-radius: 0 14px 14px 0;
  }

  .headerNav {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    font-family: "Inter", sans-serif;
    height: auto;
  }

  .login_signup_btn {
    display: none;
  }

  .nav_options_2 {
    margin-left: 10px;
  }

  .medicines_img {
    width: 64px;
    height: 78px;
    float: right;
  }

  .labtests_img {
    width: 61px;
    height: 83px;
    float: right;
  }

  .consult_now_img {
    width: 119px;
    height: 95px;
    float: right;
  }

  .healthproblem_icon {
    width: 55px;
    Height: 55px;
  }

  .heath_problem_list_box_modal {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    /* column-gap: 23px; */
    row-gap: 20px;
    grid-template-columns: 50% 50%;
  }

  .healthproblem_icon_bg_modal {
    background: rgba(25, 154, 142, 0.07);
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 16px;
    padding: 25px 22px;
  }

  .heath_problem_list_box_model_map_sub {
    flex: 1 0 19.33%;
    max-width: 99%;
    padding: 0 10px;
  }

  .healthproblem_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
  }

  /*-----------------------Dashboard Home End-------------------------*/
  /*------------------------------------Chat Screen Start------------------------------------*/
  .chat_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 30px;
  }

  .yesno_box {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    width: 220px;
  }

  .yes_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 14px 14px 0 0;
    display: flex;
    justify-content: space-between;
  }

  .no_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 0 0 14px 14px;
    display: flex;
    justify-content: space-between;
  }

  .chat_body_box_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .chat-message-list-inner {
    height: 100vh;
    overflow-y: scroll;
    padding: 30px 5px 24px 5px;
  }

  .chat_modal_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
  }

  .consult_time_btn_box {
    display: inline-flex;
    column-gap: 58px;
    margin-top: 25px;
    margin-bottom: 15px;
    /* margin-left: 15px; */
  }

  .consult_info_box {
    padding-left: 15px;
    margin-top: 10px;
  }

  .book_date_card_box {
    display: inline-flex;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .time_slot_card {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    padding: 5px 12px;
  }

  .time_slot_card_active {
    background: #FCEFE6;
    border: 1px solid #DF6128;
    border-radius: 14px;
    padding: 5px 12px;
  }

  .time_slot_card_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
  }

  /*-------------------------------------Chat Screen End-------------------------------------*/
  .subscription_body_box {
    padding: 0;
    display: inline-grid;
  }

  .subscription_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .subscription_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .subscription_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 15px;
  }

  .select_consult_member_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consult_member_box_inner {
    display: inline-flex;
    column-gap: 145px;
  }

  .consult_member_box_inner_2 {
    display: inline-flex;
    column-gap: 160px;
  }

  .subscription_plan_box {
    width: 230px;
  }

  .consult_member_modal_box_inner {
    display: inline-flex;
    column-gap: 100px;
  }

  .consult_member_modal_box_inner_2 {
    display: inline-flex;
    column-gap: 113px;
  }

  .consult_member_modal_box_inner {
    display: inline-flex;
    column-gap: 67px;
  }

  .consult_member_modal_box_inner_2 {
    display: inline-flex;
    column-gap: 83px;
  }

  /*--------------------------------Start Consultation start--------------------------------*/
  .payment_body_box {
    padding: 200px 30px;
  }

  .start_consultation_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .start_consultation_card_body {
    background: #ffffff;
    box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 0px;
  }

  .start_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .wating_box {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .start_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .start_consultation_card_head_2 {
    background: #ffffff;
    box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 165px;
    margin-top: 7px;
  }

  .chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .start_consultation_card_body_2 {
    background: #ffffff;
    box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  /*--------------------------------Start Consultation End--------------------------------*/
  /*---------------------------Start Consultation Chat Start---------------------------------*/
  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .start_consultation_chat_head_inner {
    display: inline-flex;
    float: right;
    column-gap: 13px;
    margin-top: 3px;
    margin-right: 0px;
  }

  .start_consult_user_img {
    width: 35px;
    height: 35px;
    border: 2px solid #199A8E;
    border-radius: 40px;
  }

  .start_consult_user_box {
    display: inline-flex;
    column-gap: 10px;
  }

  .consul_chat_more_icon {
    width: 25px;
    height: 25px;
  }

  .consult_chat_videocall_icon {
    width: 21px;
    height: 18px;
  }

  .consult_chat_call_icon {
    width: 18px;
    height: 18px;
  }

  /*----------------------------Start Consultation Chat End----------------------------------*/
  /*----------------------------Profile Dashboard Screen Start----------------------------*/
  .profiledash_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    margin-top: 15px;
    border-bottom: 1px solid #eceff4;
  }

  .profiledash_member_box {
    display: inline-grid;
    column-gap: 16px;
    grid-template-columns: 100%;
    width: 100%;
    row-gap: 16px;
  }

  .profiledash_member_card {
    background: #FFFFFF;
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 14px;
    padding: 14px;
    height: 108px;
  }

  /*-----------------------------Profile Dashboard Screen End-----------------------------*/
  /*-----------------------------Members Profile Screen Start-----------------------------*/
  .membersprofile_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 15px;
  }

  .membersprofile_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .membersprofile_delete_btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    padding: 8px 17px;
    margin-left: 10px;
  }

  .membersprofile_edit_btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #199A8E;
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    padding: 8px 20px;
  }

  .membersprofile_btn_box {
    float: right;
    display: inline-flex;
  }

  /*------------------------------Members Profile Screen End------------------------------*/
  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-------------------------Own Profile Consultation Start-------------------------*/
  .upcoming_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .past_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .upcoming_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .past_consultation_card_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #DB202B;
  }

  .past_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #199A8E;
  }

  .consultation_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
    display: grid;
    justify-items: center;
  }

  .cancel_appointment {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #DB202B;
  }

  .cancel_icon {
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .rating_star_box {
    float: left;
    margin-top: 20px;
    margin-right: 26px;
  }

  .consultation_cancel_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consultation_cancel_modal_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*--------------------------Own Profile Consultation End--------------------------*/
  /*---------------------------Upcoming Consultation Start---------------------------*/
  .upcoming_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .upcoming_consultation_chat_card_body {
    background: #ffffff;
    box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
    height: auto;
    margin-bottom: 20px;
  }

  .upcoming_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .upcoming_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 165px;
    margin-top: 7px;
  }

  .upcoming_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .upcoming_consultation_chat_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .upcoming_consultation_chat_card_head_2 {
    background: #ffffff;
    box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .upcoming_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .upcoming_consultation_chat_card_body_2 {
    background: #ffffff;
    box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    height: auto;
  }

  .upcoming_consultation_chat_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: auto;
  }

  /*---------------------------Upcoming Consultation End---------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .completed_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 14px 15px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 14px 24px;
    width: 100%;
  }

  .completed_consult_chat_card_bg {
    box-shadow: none;
    border-radius: none;
    background: transparent;
  }

  /*------------------------Completed Consult Screen End------------------------*/
  /*-----------------------Canceled Consult Screen Start-----------------------*/
  .canceled_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .canceled_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .canceled_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .canceled_consultation_chat_card_body {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .canceled_consultation_chat_card_body_2 {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    border-right: 1px solid #ECEFF4;
    padding: 24px 24px;
  }

  .canceled_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .canceled_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #BE2121;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .canceled_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  /*------------------------Canceled Consult Screen End------------------------*/
  /*----------------------------Profile Test booking Screen Start----------------------------*/
  .profile_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .profile_test_body_box_card_body {
    background: #ffffff;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    border-radius: 12px;
    padding: 25px;
    height: 100%;
    margin-top: 10px;
  }

  .upcoming_test_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .consultation_test_option_box_1 {
    display: grid;
    float: left;
    margin-top: 14px;
    margin-left: 3px;
  }

  .consultation_test_complete_option_box_inner {
    padding-left: 22px;
    padding-right: 22px;
  }

  .consultation_test_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 20px;
  }

  /*-----------------------------Profile Test booking Screen End-----------------------------*/
  /*----------------------------Test Completed details Screen Start----------------------------*/
  .test_completed_option_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 15px;
    margin-top: 15px;
  }

  .tracking-item {
    border-left: 2px dashed #199A8E;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .tracking-item-pending {
    border-left: 2px dashed #C4C4C4;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item-pending .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .track_dot_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -29px;
  }

  .track_dot_last_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -30px;
  }

  .track_dot {
    margin-left: 0px;
    width: 13px;
    margin-top: 0px;
  }

  /*-----------------------------Test Completed details Screen End-----------------------------*/
  /*-----------------------------Radiology booked details Screen Start-----------------------------*/
  .radiology_booked_option_box_inner {
    padding-left: 22px;
    padding-right: 10px;
  }

  .radiology_booked_test_profile_box {
    display: inline-flex;
    column-gap: 10px;
    margin-bottom: 20px;
  }

  .radiology_booked_option_box_1 {
    display: inline-flex;
    float: right;
    margin-top: 25px;
  }

  /*------------------------------Radiology booked details Screen End------------------------------*/
  /*-------------------------------My Consultation details Screen Start-------------------------------*/
  .my_consultation_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  /*--------------------------------My Consultation details Screen End--------------------------------*/
  /*-----------------------My Test Booking Screen Start-----------------------*/
  .my_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 23.5px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 33px;
  }

  /*------------------------My Test Booking Screen End------------------------*/
  /*-----------------------Medicine Orders Screen Start-----------------------*/
  .medicine_orders_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .medicine_order_box_inner {
    text-align: right;
    margin-top: 10px;
  }

  /*------------------------Medicine Orders Screen End------------------------*/
  /*-----------------------Health Package Screen Start-----------------------*/
  .health_package_Recommended {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #15181E;
  }

  .popular_package_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    margin-bottom: 20px;
    height: 250px;
  }

  .health_package_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .health_package_right_owl_slider_img {
    Width: 100%;
    Height: auto;
    border-radius: 14px;
  }

  .popular_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #15181E;
  }

  .health_package_container {
    background: #F5F5F5;
    padding: 30px 0px 50px 0;
  }

  .health_package_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .search input {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .search .results {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    width: -webkit-fill-available;
    padding: 20px 16px;
  }

  .lab_test_modal_box_inner_1 {
    padding: 0 10px 20px 10px;
  }

  .lab_test_modal_box_head_padding {
    padding: 25px 25px 10px 25px;
  }

  .lab_test_modal_box_2 {
    padding: 0 10px;
  }

  .health_package_left_padding {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .health_package_right_padding {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .health_problems_img {
    width: 42px;
    height: 42px;
  }

  /*------------------------Health Package Screen End------------------------*/
  /*------------------------Lab Test Cart Screen Start------------------------*/
  .lab_test_right_padding {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .lab_test_address {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3E4046;
    margin-bottom: 15px;
    margin-top: 0px;
  }

  .lab_test_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3E4046;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .lab_test_cart_left_side_2 {
    background: #fff;
    padding: 24px;
    border-radius: 0px 0px 0px 0px;
    border-top: 1px solid #ECEFF4;
    border-bottom: 1px solid #ECEFF4;
  }

  .lab_test_cart_right_side {
    background: #fff;
    padding: 24px;
    border-radius: 0px 12px 0px 0px;
    border-left: none;
  }

  .lab_test_left_padding {
    padding-left: calc(var(--bs-gutter-x) * .5);

  }

  .lab_test_cart_left_side {
    background: #fff;
    padding: 24px;
    border-radius: 12px 12px 0px 0px;
  }

  .lab_test_popup_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consult_member_box_1 {
    display: unset;
    padding: 0px 20px;
  }

  .book_date_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 5px;
  }

  .book_date_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .book_date_card_active {
    background: #FCEFE6;
    border: 1px solid #DF6128;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    cursor: pointer;
  }

  .lab_sample_collection_add_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 0;
  }

  .lab_sample_collection_add_para_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 0;
  }

  .payment_done_popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  .payment_done_book_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #15181E;
    text-align: right;
    margin-bottom: 0;
  }

  /*-------------------------Lab Test Cart Screen End-------------------------*/
  /*-------------------------Health Radiological tab Screen Start-------------------------*/
  .health_radiological_popup_list_box_inner {
    display: flex;
    column-gap: 10px;
  }

  .select_radiology_img {
    width: 70px;
  }

  .healthradiological_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .tab_details_head_img {
    width: 60px;
  }

  .healthradiological_tab_details_box {
    display: grid;
    justify-content: space-between;
  }

  .tab_details_view_map_btn {
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #199A8E;
    padding: 10px 19.5px 10px 19.5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /*--------------------------Health Radiological tab Screen End--------------------------*/
  /*--------------------------Wallet Screen Start--------------------------*/
  .wallet_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .wallet_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .wallet_card_body_box {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .wallet_card_body_value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #424448;
    margin-bottom: 5px;
  }

  .wallet_card_body_for {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*---------------------------Wallet Screen End---------------------------*/
  /*--------------------------Address Screen Start--------------------------*/
  .address_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .address_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /*---------------------------Address Screen End---------------------------*/
  /*--------------------------My Subscription Screen Start--------------------------*/
  .my_subscription_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_subscription_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .address_body_card_head_add_icon {
    margin-right: 10px;
    margin-top: -3px;
  }

  .address_body_card_head_new_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    margin-top: 15px;
    float: right;
  }

  .my_subscription_card_inner {
    display: grid;
    justify-content: space-between;
    row-gap: 25px;
  }

  /*---------------------------My Subscription Screen End---------------------------*/
  .payment_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 10px;
  }

  /*--------------------------Subscription Package Screen Start--------------------------*/
  .subscription_package_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .subscription_package_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #424448;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .subscription_package_members_box {
    display: grid;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .subscription_package_members_profile_box {
    display: grid;
    column-gap: 25px;
    grid-template-columns: 50% 50%;
    row-gap: 20px;
    margin-bottom: 25px;
  }

  .subscription_package_body_card_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    float: left;
    margin-top: 5px;
  }

  .subscription_package_consultation_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  .subscription_package_consultation_complete_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #199A8E;
    margin-bottom: 0;
  }

  .subscription_package_consultation_cancel_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #DB202B;
    margin-bottom: 0;
  }

  .subscription_package_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_rating_star_box {
    float: left;
    margin-top: 20px;
    margin-right: 26px;
  }

  /*---------------------------Subscription Package Screen End---------------------------*/
  /*--------------------------Follow Up Consultation Screen Start--------------------------*/
  .follow_up_upcoming_consultation_box {
    display: grid;
    column-gap: 24px;
    row-gap: 25px;
  }

  .follow_up_consult_width {
    width: -webkit-fill-available;
  }

  .follow_up_test_width {
    width: auto;
  }
  .follow_up_test_widths {
    width: auto;
  }

  .follow_up_upcoming_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .follow_up_upcoming_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  /*---------------------------Follow Up Consultation Screen End---------------------------*/
  /*--------------------------Follow Up Subscription Plan Screen Start--------------------------*/
  .follow_up_subscription_plan_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .follow_up_subscription_plan_body_box {
    padding: 0;
    display: inline-grid;
  }

  .follow_up_subscription_plan_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .follow_up_subscription_plan_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .follow_up_subscription_plan_book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .follow_up_subscription_plan_box_inner {
    width: 225px;
  }

  .popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  .prescription_btn {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    width: 146px;
    height: 36px;
    background: #E6F4F3;
    border-radius: 5px;
    color: #424448;
    margin-top: 0px;
    margin-left: 90px;
    /* float: right;  */
  }

  .bill_btn {
    margin-left: 90px;
    margin-top: 5px;
  }

  /*---------------------------Follow Up Subscription Plan Screen End---------------------------*/
  /*--------------------------medicine details Screen Start--------------------------*/
  .medicine_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: 100%;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .medicine_slider {
    display: flex;
    float: left;
    grid-column-gap: 0px;
    border-right: 0;
    border-bottom: 1px solid #ECEFF4;
    padding: 0px;
  }

  .medicine_slider_box_active {
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #199A8E;
    border-radius: 6px;
    margin-top: 10px;
  }

  .medicine_slider_box {
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 6px;
    margin-top: 10px;
  }

  .product_details {
    padding: 0;
  }

  .product_details_para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #67696C;
    align-items: center;
    text-align: justify;
  }

  .medicine_slider_img {
    width: 100%;
    height: 250px;
  }

  /*---------------------------medicine details Screen End---------------------------*/
  /*---------------------------Test completed details Screen start---------------------------*/
  .order-tracking {
    text-align: center;
    width: 24.33%;
    position: relative;
    display: block;
  }

  .booking_placed_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #909196;
    margin-top: -40px;
  }

  .booking_placed_title.active {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #199A8E;
    margin-top: -40px;
  }

  .booking_cancelled_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #DB202B;
    margin-top: -40px;
  }

  .order-tracking.completed p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 6px;
    line-height: 9px;
    color: #909196;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .order-tracking p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 6px;
    color: #A4A4A4;
    margin-top: 30px;
    margin-bottom: 0;
    line-height: 9px;
  }

  .cancellation_reason_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #15181E;
    margin-bottom: 0;
  }

  .went_offline_lab_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
  }

  .your_refund_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    color: #ef861a;
    margin-bottom: 0;
  }

  /*---------------------------Test completed details Screen End---------------------------*/

}

@media only screen and (min-width: 460px) and (max-width: 540px) {
  .sub_section_1 {
    position: relative;
    width: 100%;
    float: left;
    display: none;
  }

  .sub_section_2 {
    width: 100%;
    float: right;
  }

  .logo_box {
    padding: 50px 15px;
    margin-left: 3px;
  }

  .slider_1 {
    padding: 10px 30px 50px 30px;
  }

  .paddingDiv {
    padding: 5px 10px;
  }

  .consultText2 {
    width: 200px !important;
  }

  .owl_banner_img_box {
    padding: 20px 0px;
    width: 370px !important;
    height: 410px;
    margin-left: -20px;
  }

  .signup_box {
    padding: 90px 20px;
  }

  .signup_card {
    padding: 30px 30px;
    background: #fff;
    border-radius: 14px;
  }

  .signup_in_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    color: #15181E;
    margin-bottom: 15px;
  }

  .signup_in_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-top: 0;
  }

  .i_agree_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #4D5764;
    position: absolute;
    margin-top: 1px;
    margin-left: 8px;
  }

  .term_condition_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #199A8E;
    text-decoration: none;
  }

  .request_otp {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    width: -webkit-fill-available;
  }

  .slider_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    color: #fff;
  }

  /*----------------------------------------------------------------------------------*/
  .verification_code_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 135%;
    text-align: center;
    color: #15181E;
    margin-bottom: 0;
  }

  .verification_code_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .verification_number {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #199A8E;
    margin-bottom: 0;
    margin-top: 0;
  }

  .receive_the_code {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    align-items: center;
    color: #67696C;
    text-align: center;
  }

  .resend {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    align-items: center;
    color: #199A8E;
    text-decoration: none;
  }

  /*----------------------------------------------------------------------------------*/
  .signup_details_form_box {
    padding: 80px 10px;
  }

  .gender_option {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 14px 12px;
    justify-content: left;
    width: 64px;
    height: 75px;
  }

  .gender_icon {
    width: 22px;
    height: 22px;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .signup_details_box {
    padding: 20px 0px;
  }

  .gender_option_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #909196;
  }

  .gender_active {
    background: #199A8E !important;
    border-radius: 14px;
    width: 64px;
    position: absolute;
    margin-top: -32px;
    margin-left: -18px;
  }

  .gender_active_2 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 64px;
    position: absolute;
    margin-top: -32px;
    margin-left: -32px;
  }

  .gender_active_3 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 64px;
    position: absolute;
    margin-top: -32px;
    margin-left: -16px;
  }

  .gender_option_active_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  .common_pagination .next {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
  }

  /*-----------------------Dashboard Home Start-------------------------*/
  .three_section_box {
    display: inline-grid;
    column-gap: 95px;
    row-gap: 20px;
    width: 99.5%;
  }

  .heath_problem_list_box {
    margin-top: 45px;
    display: grid;
    column-gap: 5%;
    grid-template-columns: 30% 30% 30%;
    row-gap: 25px;
  }

  .healthproblem_icon_bg {
    background: rgba(25, 154, 142, 0.07);
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 16px;
    padding: 30px 25px;
  }

  .healthproblem_icon {
    width: 50px;
    Height: 50px;
  }

  .home_bannerslide_img {
    width: -webkit-fill-available;
  }

  .btn-outline-secondary {
    border-right: 1px solid #DFDFDF;
    border-left: none;
    border-bottom: none;
    border-top: none;
    padding: 16px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #424448;
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-hover-bg: #F0F0F4;
    --bs-btn-active-color: #424448;
    --bs-btn-hover-color: #424448;
  }

  .city_search_dropdown {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 51px;
    color: #1d1929;
    background: #F0F0F4;
    border-radius: 14px 0 0 14px;
    width: 135px;
  }

  .city_search_input_box {
    background: #F0F0F4;
    border-radius: 14px;
    display: inline-flex;
    width: 100%;
  }

  .city_search_icon_input {
    padding: 18px 13px;
    position: absolute;
  }

  .city_searchicon_box {
    width: 235px;
  }

  .consult_now_box {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_2 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_3 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .health_problem_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .sec_1_slider_text_box {
    padding: 30px 10px 25px 10px;
  }

  .healthproblem_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.3px;
    color: #424448;
    margin-top: 17px;
  }

  .city_search_input {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 54px;
    color: #1d1929;
    padding: 0px 14px 0px 40px;
    background: #F0F0F4;
    border-radius: 0 14px 14px 0;
  }

  .headerNav {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    font-family: "Inter", sans-serif;
    height: auto;
  }

  .login_signup_btn {
    display: none;
  }

  .nav_options_2 {
    margin-left: 10px;
  }

  .medicines_img {
    width: 64px;
    height: 78px;
    float: right;
  }

  .labtests_img {
    width: 61px;
    height: 83px;
    float: right;
  }

  .consult_now_img {
    width: 119px;
    height: 95px;
    float: right;
  }

  .heath_problem_list_box_modal {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    column-gap: 23px;
    row-gap: 20px;
    grid-template-columns: 45% 45%;
  }

  .healthproblem_icon_bg_modal {
    background: rgba(25, 154, 142, 0.07);
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 16px;
    padding: 25px 35px;
  }

  .healthproblem_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
  }

  /*-----------------------Dashboard Home End-------------------------*/
  /*------------------------------------Chat Screen Start------------------------------------*/
  .chat_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 30px;
  }

  .yesno_box {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    width: 220px;
  }

  .yes_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 14px 14px 0 0;
    display: flex;
    justify-content: space-between;
  }

  .no_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 0 0 14px 14px;
    display: flex;
    justify-content: space-between;
  }

  .chat_body_box_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .chat-message-list-inner {
    height: 100vh;
    overflow-y: scroll;
    padding: 30px 5px 24px 5px;
  }

  .chat_modal_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
  }

  .consult_time_btn_box {
    display: inline-flex;
    column-gap: 58px;
    margin-top: 25px;
    margin-bottom: 15px;
    /* margin-left: 15px; */
  }

  .consult_info_box {
    padding-left: 15px;
    margin-top: 10px;
  }

  .book_date_card_box {
    display: inline-flex;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .time_slot_card {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    padding: 5px 12px;
  }

  .time_slot_card_active {
    background: #FCEFE6;
    border: 1px solid #DF6128;
    border-radius: 14px;
    padding: 5px 12px;
  }

  .time_slot_card_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
  }

  .chat_card_box {
    display: none;
  }

  /*-------------------------------------Chat Screen End-------------------------------------*/
  .subscription_body_box {
    padding: 0;
    display: inline-grid;
  }

  .subscription_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .subscription_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .consult_member_box_inner {
    display: inline-flex;
    column-gap: 200px;
  }

  .consult_member_box_inner_2 {
    display: inline-flex;
    column-gap: 215px;
  }

  .consult_member_modal_box_inner {
    display: inline-flex;
    column-gap: 155px;
  }

  .consult_member_modal_box_inner_2 {
    display: inline-flex;
    column-gap: 165px;
  }

  /*--------------------------------Start Consultation start--------------------------------*/
  .payment_body_box {
    padding: 200px 30px;
  }

  .start_consultation_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .start_consultation_card_body {
    background: #ffffff;
    box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 0px;
  }

  .start_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .wating_box {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .start_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .start_consultation_card_head_2 {
    background: #ffffff;
    box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 220px;
    margin-top: 7px;
  }

  .chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .start_consultation_card_body_2 {
    background: #ffffff;
    box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  /*--------------------------------Start Consultation End--------------------------------*/
  /*---------------------------Start Consultation Chat Start---------------------------------*/
  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .start_consultation_chat_head_inner {
    display: inline-flex;
    float: right;
    column-gap: 13px;
    margin-top: 3px;
    margin-right: 0px;
  }

  .start_consult_user_img {
    width: 35px;
    height: 35px;
    border: 2px solid #199A8E;
    border-radius: 40px;
  }

  .start_consult_user_box {
    display: inline-flex;
    column-gap: 10px;
  }

  .consul_chat_more_icon {
    width: 25px;
    height: 25px;
  }

  .consult_chat_videocall_icon {
    width: 21px;
    height: 18px;
  }

  .consult_chat_call_icon {
    width: 18px;
    height: 18px;
  }

  .health_problems_img {
    width: 42px;
    height: 42px;
  }

  /*----------------------------Start Consultation Chat End----------------------------------*/
  /*----------------------------Profile Dashboard Screen Start----------------------------*/
  .profiledash_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    margin-top: 15px;
    border-bottom: 1px solid #eceff4;
  }

  .profiledash_member_box {
    display: inline-grid;
    column-gap: 16px;
    grid-template-columns: 100%;
    width: 100%;
    row-gap: 16px;
  }

  .profiledash_member_card {
    background: #FFFFFF;
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 14px;
    padding: 14px;
    height: 108px;
  }

  /*-----------------------------Profile Dashboard Screen End-----------------------------*/
  /*-----------------------------Members Profile Screen Start-----------------------------*/
  .membersprofile_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 15px;
  }

  .membersprofile_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .membersprofile_delete_btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    padding: 8px 17px;
    margin-left: 10px;
  }

  .membersprofile_edit_btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #199A8E;
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    padding: 8px 20px;
  }

  .membersprofile_btn_box {
    float: right;
    display: inline-flex;
  }

  /*------------------------------Members Profile Screen End------------------------------*/
  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-------------------------Own Profile Consultation Start-------------------------*/
  .upcoming_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .past_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .upcoming_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .past_consultation_card_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DB202B;
  }

  .past_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #199A8E;
  }

  .consultation_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .cancel_appointment {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #DB202B;
  }

  .cancel_icon {
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .rating_star_box {
    float: left;
    margin-top: 20px;
    margin-right: 26px;
  }

  .consultation_option_box {
    display: inline-flex;
    float: right;
    margin-top: 33px;
    margin-right: 4px;
  }

  .consultation_cancel_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consultation_cancel_modal_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*--------------------------Own Profile Consultation End--------------------------*/
  /*---------------------------Upcoming Consultation Start---------------------------*/
  .upcoming_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .upcoming_consultation_chat_card_body {
    background: #ffffff;
    box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
    height: auto;
    margin-bottom: 20px;
  }

  .upcoming_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .upcoming_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 220px;
    margin-top: 7px;
  }

  .upcoming_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .upcoming_consultation_chat_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .upcoming_consultation_chat_card_head_2 {
    background: #ffffff;
    box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .upcoming_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .upcoming_consultation_chat_card_body_2 {
    background: #ffffff;
    box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    height: auto;
  }

  .upcoming_consultation_chat_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: auto;
  }

  /*---------------------------Upcoming Consultation End---------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .completed_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 215px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 14px 15px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 14px 24px;
    width: 100%;
  }

  .completed_consult_chat_card_bg {
    box-shadow: none;
    border-radius: none;
    background: transparent;
  }

  /*------------------------Completed Consult Screen End------------------------*/
  /*-----------------------Canceled Consult Screen Start-----------------------*/
  .canceled_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .canceled_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .canceled_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .canceled_consultation_chat_card_body {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .canceled_consultation_chat_card_body_2 {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .canceled_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: #BE2121;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .canceled_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  /*------------------------Canceled Consult Screen End------------------------*/
  /*----------------------------Profile Test booking Screen Start----------------------------*/
  .profile_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .profile_test_body_box_card_body {
    background: #ffffff;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    border-radius: 12px;
    padding: 25px;
    height: 100%;
    margin-top: 10px;
  }

  .upcoming_test_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .consultation_test_option_box_1 {
    display: inline-flex;
    float: right;
    margin-top: 14px;
    margin-left: 3px;
  }

  .consultation_test_complete_option_box_inner {
    padding-left: 22px;
    padding-right: 22px;
  }

  .consultation_test_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 20px;
  }

  /*-----------------------------Profile Test booking Screen End-----------------------------*/
  /*----------------------------Test Completed details Screen Start----------------------------*/
  .test_completed_option_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 15px;
    margin-top: 15px;
  }

  .tracking-item {
    border-left: 2px dashed #199A8E;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .tracking-item-pending {
    border-left: 2px dashed #C4C4C4;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item-pending .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .track_dot_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -29px;
  }

  .track_dot_last_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -30px;
  }

  .track_dot {
    margin-left: 0px;
    width: 13px;
    margin-top: 0px;
  }

  /*-----------------------------Test Completed details Screen End-----------------------------*/
  /*-----------------------------Radiology booked details Screen Start-----------------------------*/
  .radiology_booked_option_box_inner {
    padding-left: 22px;
    padding-right: 10px;
  }

  .radiology_booked_test_profile_box {
    display: inline-flex;
    column-gap: 10px;
    margin-bottom: 20px;
  }

  .radiology_booked_option_box_1 {
    display: inline-flex;
    float: right;
    margin-top: 25px;
  }

  /*------------------------------Radiology booked details Screen End------------------------------*/
  /*-------------------------------My Consultation details Screen Start-------------------------------*/
  .my_consultation_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  /*--------------------------------My Consultation details Screen End--------------------------------*/
  /*-----------------------My Test Booking Screen Start-----------------------*/
  .my_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 23.5px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 33px;
  }

  /*------------------------My Test Booking Screen End------------------------*/
  /*-----------------------Medicine Orders Screen Start-----------------------*/
  .medicine_orders_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .medicine_order_box_inner {
    text-align: right;
    margin-top: 10px;
  }

  /*------------------------Medicine Orders Screen End------------------------*/
  /*-----------------------Health Package Screen Start-----------------------*/
  .health_package_Recommended {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #15181E;
  }

  .popular_package_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    margin-bottom: 20px;
    height: 270px;
  }

  .health_package_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .health_package_right_owl_slider_img {
    Width: 100%;
    Height: auto;
    border-radius: 14px;
  }

  .popular_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #15181E;
  }

  .health_package_container {
    background: #F5F5F5;
    padding: 30px 0px 50px 0;
  }

  .health_package_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .search input {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .search .results {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    width: -webkit-fill-available;
    padding: 20px 16px;
  }

  .health_package_left_padding {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .health_package_right_padding {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  /*------------------------Health Package Screen End------------------------*/
  /*------------------------Lab Test Cart Screen Start------------------------*/
  .lab_test_right_padding {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .lab_test_address {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3E4046;
    margin-bottom: 15px;
    margin-top: 0px;
  }

  .lab_test_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3E4046;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .lab_test_cart_left_side_2 {
    background: #fff;
    padding: 24px;
    border-radius: 0px 0px 0px 0px;
    border-top: 1px solid #ECEFF4;
    border-bottom: 1px solid #ECEFF4;
  }

  .lab_test_cart_right_side {
    background: #fff;
    padding: 24px;
    border-radius: 0px 12px 0px 0px;
    border-left: none;
  }

  .lab_test_left_padding {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .lab_test_cart_left_side {
    background: #fff;
    padding: 24px;
    border-radius: 12px 12px 0px 0px;
  }

  .lab_test_popup_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consult_member_box_1 {
    display: unset;
    padding: 0px 40px;
  }

  .book_date_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 5px;
  }

  .book_date_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .book_date_card_active {
    background: #FCEFE6;
    border: 1px solid #DF6128;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    cursor: pointer;
  }

  .lab_sample_collection_add_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 0;
  }

  .lab_sample_collection_add_para_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 0;
  }

  .payment_done_popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  .payment_done_book_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #15181E;
    text-align: right;
    margin-bottom: 0;
  }

  /*-------------------------Lab Test Cart Screen End-------------------------*/
  /*-------------------------Health Radiological tab Screen Start-------------------------*/
  .health_radiological_popup_list_box_inner {
    display: flex;
    column-gap: 10px;
  }

  .select_radiology_img {
    width: 70px;
  }

  .healthradiological_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .tab_details_head_img {
    width: 60px;
  }

  .healthradiological_tab_details_box {
    display: grid;
    justify-content: space-between;
  }

  .tab_details_view_map_btn {
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #199A8E;
    padding: 10px 19.5px 10px 19.5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /*--------------------------Health Radiological tab Screen End--------------------------*/
  /*--------------------------Wallet Screen Start--------------------------*/
  .wallet_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .wallet_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .wallet_card_body_box {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .wallet_card_body_value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #424448;
    margin-bottom: 5px;
  }

  .wallet_card_body_for {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*---------------------------Wallet Screen End---------------------------*/
  /*--------------------------Address Screen Start--------------------------*/
  .address_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .address_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /*---------------------------Address Screen End---------------------------*/
  /*--------------------------My Subscription Screen Start--------------------------*/
  .my_subscription_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_subscription_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .address_body_card_head_add_icon {
    margin-right: 10px;
    margin-top: -3px;
  }

  .address_body_card_head_new_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    margin-top: 15px;
    float: right;
  }

  .my_subscription_card_inner {
    display: grid;
    justify-content: space-between;
    row-gap: 25px;
  }

  /*---------------------------My Subscription Screen End---------------------------*/
  .payment_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 10px;
  }

  /*--------------------------Subscription Package Screen Start--------------------------*/
  .subscription_package_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .subscription_package_option_box {
    display: flex;
    float: inherit;
    margin-top: 33px;
    justify-content: left;
  }

  .subscription_package_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #424448;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .subscription_package_members_box {
    display: grid;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .subscription_package_members_profile_box {
    display: grid;
    column-gap: 25px;
    grid-template-columns: 50% 50%;
    row-gap: 20px;
    margin-bottom: 25px;
  }

  .subscription_package_body_card_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    float: left;
    margin-top: 5px;
  }

  .subscription_package_consultation_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  .subscription_package_consultation_complete_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #199A8E;
    margin-bottom: 0;
  }

  .subscription_package_consultation_cancel_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #DB202B;
    margin-bottom: 0;
  }

  .subscription_package_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_rating_star_box {
    float: left;
    margin-top: 20px;
    margin-right: 26px;
  }

  /*---------------------------Subscription Package Screen End---------------------------*/
  /*--------------------------Follow Up Consultation Screen Start--------------------------*/
  .follow_up_upcoming_consultation_box {
    display: grid;
    column-gap: 24px;
    row-gap: 25px;
  }

  .follow_up_consult_width {
    width: -webkit-fill-available;
  }

  .follow_up_test_width {
    width: auto;
  }
  .follow_up_test_widths {
    width: auto;
  }

  .follow_up_upcoming_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .follow_up_upcoming_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  /*---------------------------Follow Up Consultation Screen End---------------------------*/
  /*--------------------------Follow Up Subscription Plan Screen Start--------------------------*/
  .follow_up_subscription_plan_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .follow_up_subscription_plan_body_box {
    padding: 0;
    display: inline-grid;
  }

  .follow_up_subscription_plan_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .follow_up_subscription_plan_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .follow_up_subscription_plan_book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .follow_up_subscription_plan_box_inner {
    width: 225px;
  }

  .popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  /*---------------------------Follow Up Subscription Plan Screen End---------------------------*/
  /*--------------------------medicine details Screen Start--------------------------*/
  .medicine_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: 100%;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .medicine_slider {
    display: flex;
    float: left;
    grid-column-gap: 0px;
    border-right: 0;
    border-bottom: 1px solid #ECEFF4;
    padding: 0px;
  }

  .medicine_slider_box_active {
    width: 70px;
    height: 70px;
    background: #FFFFFF;
    border: 1px solid #199A8E;
    border-radius: 6px;
    margin-top: 10px;
  }

  .medicine_slider_box {
    width: 70px;
    height: 70px;
    background: #FFFFFF;
    border-radius: 6px;
    margin-top: 10px;
  }

  .product_details {
    padding: 0;
  }

  .product_details_para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #67696C;
    align-items: center;
    text-align: justify;
  }

  .medicine_slider_img {
    width: 100%;
    height: 300px;
  }

  /*---------------------------medicine details Screen End---------------------------*/
  /*---------------------------Test completed details Screen start---------------------------*/
  .order-tracking {
    text-align: center;
    width: 24.33%;
    position: relative;
    display: block;
  }

  .booking_placed_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #909196;
    margin-top: -40px;
  }

  .booking_placed_title.active {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #199A8E;
    margin-top: -40px;
  }

  .order-tracking.completed p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 6px;
    line-height: 9px;
    color: #909196;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .booking_cancelled_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    color: #DB202B;
    margin-top: -40px;
  }

  .order-tracking p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 6px;
    color: #A4A4A4;
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 9px;
  }

  .cancellation_reason_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #15181E;
    margin-bottom: 0;
  }

  .went_offline_lab_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
  }

  .your_refund_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ef861a;
    margin-bottom: 0;
  }

  /*---------------------------Test completed details Screen End---------------------------*/

}

@media only screen and (min-width: 541px) and (max-width: 767px) {
  .sub_section_1 {
    position: relative;
    width: 100%;
    float: left;
    display: none;
  }

  .togglesidebar {
    visibility: visible;
  }

  .sub_section_2 {
    width: 100%;
    float: right;
  }

  .logo_box {
    padding: 50px 15px;
    margin-left: 3px;
  }

  .slider_1 {
    padding: 10px 30px 50px 30px;
  }

  .owl_banner_img_box {
    padding: 20px 0px;
    width: 400px !important;
    height: 440px;
  }

  .signup_box {
    padding: 90px 40px;
  }

  .signup_card {
    padding: 30px 30px;
    background: #fff;
    border-radius: 14px;
  }

  .signup_in_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #15181E;
    margin-bottom: 15px;
  }

  .signup_in_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-top: 0;
  }

  .i_agree_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #4D5764;
    position: absolute;
    margin-top: 1px;
    margin-left: 10px;
  }

  .term_condition_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #199A8E;
    text-decoration: none;
  }

  .request_otp {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    width: -webkit-fill-available;
  }

  .slider_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    color: #fff;
  }

  /*----------------------------------------------------------------------------------*/
  .verification_code_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 135%;
    text-align: center;
    color: #15181E;
    margin-bottom: 0;
  }

  .verification_code_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .verification_number {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #199A8E;
    margin-bottom: 0;
    margin-top: 12px;
  }

  .receive_the_code {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
    color: #67696C;
    text-align: center;
  }

  .resend {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
    color: #199A8E;
    text-decoration: none;
  }

  /*----------------------------------------------------------------------------------*/
  .signup_details_form_box {
    padding: 80px 10px;
  }

  .gender_option {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 14px 20px;
    justify-content: left;
    width: 80px;
    height: 85px;
  }

  .gender_icon {
    width: 22px;
    height: 22px;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .signup_details_box {
    padding: 20px 0px;
  }

  .gender_option_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #909196;
  }

  .gender_active {
    background: #199A8E !important;
    border-radius: 14px;
    width: 80px;
    position: absolute;
    margin-top: -32px;
    margin-left: -26px;
  }

  .gender_active_2 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 80px;
    position: absolute;
    margin-top: -32px;
    margin-left: -40px;
  }

  .gender_active_3 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 80px;
    position: absolute;
    margin-top: -32px;
    margin-left: -24px;
  }

  .gender_option_active_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  .common_pagination .next {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
  }

  /*-----------------------Dashboard Home Start-------------------------*/
  .three_section_box {
    display: inline-grid;
    column-gap: 95px;
    row-gap: 20px;
    width: 99.5%;
  }

  .heath_problem_list_box {
    margin-top: 45px;
    display: grid;
    column-gap: 5%;
    grid-template-columns: 30% 30% 30%;
    row-gap: 25px;
  }

  .healthproblem_icon_bg {
    background: rgba(25, 154, 142, 0.07);
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 16px;
    padding: 30px 25px;
  }

  .healthproblem_icon {
    width: 50px;
    Height: 50px;
  }

  .home_bannerslide_img {
    width: -webkit-fill-available;
  }

  .btn-outline-secondary {
    border-right: 1px solid #DFDFDF;
    border-left: none;
    border-bottom: none;
    border-top: none;
    padding: 16px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #424448;
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-hover-bg: #F0F0F4;
    --bs-btn-active-color: #424448;
    --bs-btn-hover-color: #424448;
  }

  .city_search_dropdown {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 51px;
    color: #1d1929;
    background: #F0F0F4;
    border-radius: 14px 0 0 14px;
    width: 135px;
  }

  .city_search_input_box {
    background: #F0F0F4;
    border-radius: 14px;
    display: inline-flex;
    width: 100%;
  }

  .city_search_icon_input {
    padding: 18px 13px;
    position: absolute;
  }

  .city_searchicon_box {
    width: 235px;
  }

  .consult_now_box {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_2 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_3 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .health_problem_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .sec_1_slider_text_box {
    padding: 30px 10px 25px 10px;
  }

  .healthproblem_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.3px;
    color: #424448;
    margin-top: 17px;
  }

  .city_search_input {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 54px;
    color: #1d1929;
    padding: 0px 14px 0px 40px;
    background: #F0F0F4;
    border-radius: 0 14px 14px 0;
  }

  .headerNav {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    font-family: "Inter", sans-serif;
    height: auto;
  }

  .login_signup_btn {
    display: none;
  }

  .nav_options_2 {
    margin-left: 10px;
  }

  .medicines_img {
    width: 64px;
    height: 78px;
    float: right;
  }

  .labtests_img {
    width: 61px;
    height: 83px;
    float: right;
  }

  .consult_now_img {
    width: 119px;
    height: 95px;
    float: right;
  }

  .heath_problem_list_box_modal {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    column-gap: 23px;
    row-gap: 20px;
    grid-template-columns: 50% 50%;
  }

  .healthproblem_icon_bg_modal {
    background: rgba(25, 154, 142, 0.07);
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 16px;
    padding: 25px 25px;
  }

  .healthproblem_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
  }

  /*-----------------------Dashboard Home End-------------------------*/
  /*------------------------------------Chat Screen Start------------------------------------*/
  .chat_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 30px;
  }

  .yesno_box {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    width: 220px;
  }

  .yes_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 14px 14px 0 0;
    display: flex;
    justify-content: space-between;
  }

  .no_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 0 0 14px 14px;
    display: flex;
    justify-content: space-between;
  }

  .chat_body_box_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .chat-message-list-inner {
    height: 100vh;
    overflow-y: scroll;
    padding: 30px 5px 24px 5px;
  }

  .chat_modal_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
  }

  .consult_time_btn_box {
    display: inline-flex;
    column-gap: 58px;
    margin-top: 25px;
    margin-bottom: 15px;
    /* margin-left: 15px; */
  }

  .consult_info_box {
    padding-left: 15px;
    margin-top: 10px;
  }

  .book_date_card_box {
    display: inline-flex;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .time_slot_card {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    padding: 5px 12px;
  }

  .time_slot_card_active {
    background: #FCEFE6;
    border: 1px solid #DF6128;
    border-radius: 14px;
    padding: 5px 12px;
  }

  .time_slot_card_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
  }

  /*-------------------------------------Chat Screen End-------------------------------------*/
  .subscription_body_box {
    padding: 0;
    display: inline-grid;
  }

  .subscription_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .subscription_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .consult_member_box_inner {
    display: inline-flex;
    column-gap: 235px;
  }

  .consult_member_box_inner_2 {
    display: inline-flex;
    column-gap: 250px;
  }

  .consult_member_modal_box_inner {
    display: inline-flex;
    column-gap: 211px;
  }

  .consult_member_modal_box_inner_2 {
    display: inline-flex;
    column-gap: 223px;
  }

  /*--------------------------------Start Consultation start--------------------------------*/
  .payment_body_box {
    padding: 200px 30px;
  }

  .start_consultation_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .start_consultation_card_body {
    background: #ffffff;
    box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 0px;
  }

  .start_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .wating_box {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  .start_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .start_consultation_card_head_2 {
    background: #ffffff;
    box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 300px;
    margin-top: 7px;
  }

  .chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .start_consultation_card_body_2 {
    background: #ffffff;
    box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  /*--------------------------------Start Consultation End--------------------------------*/
  /*---------------------------Start Consultation Chat Start---------------------------------*/
  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .start_consultation_chat_head_inner {
    display: inline-flex;
    float: right;
    column-gap: 13px;
    margin-top: 3px;
    margin-right: 0px;
  }

  .start_consult_user_img {
    width: 35px;
    height: 35px;
    border: 2px solid #199A8E;
    border-radius: 40px;
  }

  .start_consult_user_box {
    display: inline-flex;
    column-gap: 10px;
  }

  .consul_chat_more_icon {
    width: 25px;
    height: 25px;
  }

  .consult_chat_videocall_icon {
    width: 21px;
    height: 18px;
  }

  .consult_chat_call_icon {
    width: 18px;
    height: 18px;
  }

  /*----------------------------Start Consultation Chat End----------------------------------*/
  /*----------------------------Profile Dashboard Screen Start----------------------------*/
  .profiledash_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    margin-top: 15px;
    border-bottom: 1px solid #eceff4;
  }

  .profiledash_member_box {
    display: inline-grid;
    column-gap: 16px;
    grid-template-columns: 100%;
    width: 100%;
    row-gap: 16px;
  }

  .profiledash_member_card {
    background: #FFFFFF;
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 14px;
    padding: 14px;
    height: 108px;
  }

  .ownprofile_info_box_value {
    width: 100%;
  }

  /*-----------------------------Profile Dashboard Screen End-----------------------------*/
  /*-----------------------------Members Profile Screen Start-----------------------------*/
  .membersprofile_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 15px;
  }

  .membersprofile_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .membersprofile_delete_btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    padding: 8px 17px;
    margin-left: 10px;
  }

  .membersprofile_edit_btn {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #199A8E;
    background: #FFFFFF;
    border: 1.5px solid #E2E2E6;
    border-radius: 14px;
    padding: 8px 20px;
  }

  .membersprofile_btn_box {
    float: right;
    display: inline-flex;
  }

  /*------------------------------Members Profile Screen End------------------------------*/
  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-------------------------Own Profile Consultation Start-------------------------*/
  .upcoming_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .past_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .upcoming_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .past_consultation_card_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DB202B;
  }

  .past_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #199A8E;
  }

  .consultation_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .cancel_appointment {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #DB202B;
  }

  .cancel_icon {
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .rating_star_box {
    float: right;
    margin-top: 20px;
    margin-right: 26px;
  }

  .consultation_option_box {
    display: inline-flex;
    float: right;
    margin-top: 33px;
    margin-right: 4px;
  }

  .consultation_cancel_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consultation_cancel_modal_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*--------------------------Own Profile Consultation End--------------------------*/
  /*---------------------------Upcoming Consultation Start---------------------------*/
  .upcoming_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .upcoming_consultation_chat_card_body {
    background: #ffffff;
    box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
    height: auto;
    margin-bottom: 20px;
  }

  .upcoming_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .upcoming_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 300px;
    margin-top: 7px;
  }

  .upcoming_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .upcoming_consultation_chat_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .upcoming_consultation_chat_card_head_2 {
    background: #ffffff;
    box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .upcoming_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .upcoming_consultation_chat_card_body_2 {
    background: #ffffff;
    box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    height: auto;
  }

  .upcoming_consultation_chat_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: auto;
  }

  /*---------------------------Upcoming Consultation End---------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .completed_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 0px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 14px 15px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 14px 24px;
    width: 100%;
  }

  .completed_consult_chat_card_bg {
    box-shadow: none;
    border-radius: none;
    background: transparent;
  }

  /*------------------------Completed Consult Screen End------------------------*/
  /*-----------------------Canceled Consult Screen Start-----------------------*/
  .canceled_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .canceled_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .canceled_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .canceled_consultation_chat_card_body {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .canceled_consultation_chat_card_body_2 {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    border-right: 1px solid #ECEFF4;
    padding: 24px 24px;
  }

  .canceled_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .canceled_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: #BE2121;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .canceled_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .canceled_chat_message_list_inner {
    height: 111.9vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  /*------------------------Canceled Consult Screen End------------------------*/
  /*----------------------------Profile Test booking Screen Start----------------------------*/
  .profile_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .profile_test_body_box_card_body {
    background: #ffffff;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    border-radius: 12px;
    padding: 25px;
    height: 100%;
    margin-top: 10px;
  }

  .upcoming_test_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .consultation_test_option_box_1 {
    display: inline-flex;
    float: right;
    margin-top: 14px;
    margin-left: 3px;
  }

  .consultation_test_complete_option_box_inner {
    padding-left: 22px;
    padding-right: 22px;
  }

  .consultation_test_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 20px;
  }

  .e_report_bg {
    background: #E3F3FB;
    border-radius: 5px;
    padding: 8px 11px 8px 6px;
    display: inline-flex;
    column-gap: 15px;
  }

  /*-----------------------------Profile Test booking Screen End-----------------------------*/
  /*----------------------------Test Completed details Screen Start----------------------------*/
  .test_completed_option_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 15px;
    margin-top: 15px;
  }

  .tracking-item {
    border-left: 2px dashed #199A8E;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .tracking-item-pending {
    border-left: 2px dashed #C4C4C4;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item-pending .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .track_dot_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -29px;
  }

  .track_dot_last_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -30px;
  }

  .track_dot {
    margin-left: 0px;
    width: 13px;
    margin-top: 0px;
  }

  /*-----------------------------Test Completed details Screen End-----------------------------*/
  /*-----------------------------Radiology booked details Screen Start-----------------------------*/
  .radiology_booked_option_box_inner {
    padding-left: 22px;
    padding-right: 10px;
  }

  .radiology_booked_test_profile_box {
    display: inline-flex;
    column-gap: 10px;
    margin-bottom: 20px;
  }

  .radiology_booked_option_box_1 {
    display: inline-flex;
    float: right;
    margin-top: 25px;
  }

  /*------------------------------Radiology booked details Screen End------------------------------*/
  /*-------------------------------My Consultation details Screen Start-------------------------------*/
  .my_consultation_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  /*--------------------------------My Consultation details Screen End--------------------------------*/
  /*-----------------------My Test Booking Screen Start-----------------------*/
  .my_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 23.5px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 33px;
  }

  /*------------------------My Test Booking Screen End------------------------*/
  /*-----------------------Medicine Orders Screen Start-----------------------*/
  .medicine_orders_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .medicine_order_box_inner {
    text-align: right;
    margin-top: 10px;
  }

  /*------------------------Medicine Orders Screen End------------------------*/
  /*-----------------------Health Package Screen Start-----------------------*/
  .health_package_Recommended {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #15181E;
  }

  .popular_package_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    margin-bottom: 20px;
    height: 275px;
  }

  .health_package_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .health_package_right_owl_slider_img {
    Width: 100%;
    Height: auto;
    border-radius: 14px;
  }

  .popular_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #15181E;
  }

  .health_package_container {
    background: #F5F5F5;
    padding: 30px 0px 50px 0;
  }

  .health_package_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .search input {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .search .results {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    width: -webkit-fill-available;
    padding: 20px 16px;
  }

  .health_package_left_padding {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .health_package_right_padding {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  /*------------------------Health Package Screen End------------------------*/
  /*------------------------Lab Test Cart Screen Start------------------------*/
  .lab_test_right_padding {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .lab_test_address {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3E4046;
    margin-bottom: 15px;
    margin-top: 0px;
  }

  .lab_test_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #3E4046;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .lab_test_cart_left_side_2 {
    background: #fff;
    padding: 24px;
    border-radius: 0px 0px 0px 0px;
    border-top: 1px solid #ECEFF4;
    border-bottom: 1px solid #ECEFF4;
  }

  .lab_test_cart_right_side {
    background: #fff;
    padding: 24px;
    border-radius: 0px 12px 0px 0px;
    border-left: none;
  }

  .lab_test_left_padding {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  .lab_test_cart_left_side {
    background: #fff;
    padding: 24px;
    border-radius: 12px 12px 0px 0px;
  }

  .lab_sample_collection_add_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 0;
  }

  .lab_sample_collection_add_para_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 0;
  }

  /*-------------------------Lab Test Cart Screen End-------------------------*/
  /*--------------------------Wallet Screen Start--------------------------*/
  .wallet_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .wallet_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .wallet_card_body_box {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .wallet_card_body_value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #424448;
    margin-bottom: 5px;
  }

  .wallet_card_body_for {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*---------------------------Wallet Screen End---------------------------*/
  /*--------------------------Address Screen Start--------------------------*/
  .address_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .address_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  /*---------------------------Address Screen End---------------------------*/
  /*--------------------------My Subscription Screen Start--------------------------*/
  .my_subscription_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_subscription_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .address_body_card_head_add_icon {
    margin-right: 10px;
    margin-top: -2px;
  }

  .address_body_card_head_new_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    margin-top: 15px;
    float: right;
  }

  .my_subscription_card_inner {
    display: grid;
    justify-content: space-between;
    row-gap: 25px;
  }

  /*---------------------------My Subscription Screen End---------------------------*/
  .payment_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 10px;
  }

  /*--------------------------Subscription Package Screen Start--------------------------*/
  .subscription_package_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .subscription_package_option_box {
    display: flex;
    float: inherit;
    margin-top: 33px;
    justify-content: left;
  }

  .subscription_package_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #424448;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .subscription_package_members_box {
    display: grid;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .subscription_package_members_profile_box {
    display: grid;
    column-gap: 25px;
    grid-template-columns: 50% 50%;
    row-gap: 20px;
    margin-bottom: 25px;
  }

  .subscription_package_body_card_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    float: left;
    margin-top: 5px;
  }

  .subscription_package_consultation_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  .subscription_package_consultation_complete_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #199A8E;
    margin-bottom: 0;
  }

  .subscription_package_consultation_cancel_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #DB202B;
    margin-bottom: 0;
  }

  .subscription_package_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_rating_star_box {
    float: left;
    margin-top: 20px;
    margin-right: 26px;
  }

  /*---------------------------Subscription Package Screen End---------------------------*/
  /*--------------------------Follow Up Consultation Screen Start--------------------------*/
  .follow_up_upcoming_consultation_box {
    display: grid;
    column-gap: 24px;
    row-gap: 25px;
  }

  .follow_up_consult_width {
    width: -webkit-fill-available;
  }

  .follow_up_test_width {
    width: auto;
  }
  .follow_up_test_widths {
    width: auto;
  }

  .follow_up_upcoming_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .follow_up_upcoming_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  /*---------------------------Follow Up Consultation Screen End---------------------------*/
  /*--------------------------Follow Up Subscription Plan Screen Start--------------------------*/
  .follow_up_subscription_plan_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .follow_up_subscription_plan_body_box {
    padding: 0;
    display: inline-grid;
  }

  .follow_up_subscription_plan_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .follow_up_subscription_plan_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .follow_up_subscription_plan_book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .follow_up_subscription_plan_box_inner {
    width: 225px;
  }

  .popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  /*---------------------------Follow Up Subscription Plan Screen End---------------------------*/
  /*--------------------------medicine details Screen Start--------------------------*/
  .medicine_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: 100%;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .medicine_slider {
    display: flex;
    float: left;
    grid-column-gap: 0px;
    border-right: 0;
    border-bottom: 1px solid #ECEFF4;
    padding: 0px;
  }

  .medicine_slider_box_active {
    width: 70px;
    height: 70px;
    background: #FFFFFF;
    border: 1px solid #199A8E;
    border-radius: 6px;
    margin-top: 10px;
  }

  .medicine_slider_box {
    width: 70px;
    height: 70px;
    background: #FFFFFF;
    border-radius: 6px;
    margin-top: 10px;
  }

  .product_details {
    padding: 0;
  }

  .product_details_para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #67696C;
    align-items: center;
    text-align: justify;
  }

  .medicine_slider_img {
    width: 100%;
    height: 100%;
  }

  /*---------------------------medicine details Screen End---------------------------*/
  /*---------------------------Test completed details Screen start---------------------------*/
  .order-tracking {
    text-align: center;
    width: 24.33%;
    position: relative;
    display: block;
  }

  .booking_placed_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #909196;
    margin-top: -40px;
  }

  .booking_placed_title.active {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #199A8E;
    margin-top: -40px;
  }

  .booking_cancelled_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #DB202B;
    margin-top: -40px;
  }

  .order-tracking.completed p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #909196;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .order-tracking p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #A4A4A4;
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 14px;
  }

  .cancellation_reason_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #15181E;
    margin-bottom: 0;
  }

  .your_refund_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ef861a;
    margin-bottom: 0;
  }

  /*---------------------------Test completed details Screen End---------------------------*/

}

@media only screen and (min-width: 300px) and (max-width: 430px) {
  .ourservices_section_box {
    /* display: block; */
    -webkit-column-count: 2;
    column-count: 2;
    display: block;
    margin: 0 auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: none!important;
  }
  .consult_now_services_box {
    width: 140px!important;
    height: 244px;
  }


  .heath_problem_list_box_modal {
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    /* column-gap: 23px; */
    row-gap: 20px;
    grid-template-columns: 50% 50%;
  }

  .hrLine {
    border-right: none !important;
  }

  /* .radiology_booked_test_for_name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
    margin-left:-10px;
} */
  .prescription_btn {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    width: 185px;
    height: 36px;
    background: #E6F4F3;
    border-radius: 5px;
    color: #424448;
    margin-top: 10px;
    margin-left: 90px;
    /* float: right;  */
  }

  .bill_btn {
    width: 185px;
    margin-left: 90px;
    margin-top: 5px;
  }

  .message_box {
    position: fixed;
    bottom: 0;
  }

  .ourservices_section_box .consult_now_services_box {
    margin-bottom: 20px;
  }

  

  .heath_problem_list_box_map {
    display: block;
    margin: 10px auto;
    width: fit-content;
    column-count: 1;
    padding: 10px 0px;
    column-gap: 8px;
    display: grid;
    grid-template-columns: 44% 44%;
  }

  .heath_problem_list_box_map_sub {
    flex: 0 0 16.33%;
    max-width: 100%;
    padding: 0 10px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 431px) and (max-width: 767px) {
  .ourservices_section_box {
    display: block;
    margin: 0 auto;
    width: fit-content;
    column-count: 2;
  }

  .hrLine {
    border-right: none !important;
  }

  .ourservices_section_box .consult_now_services_box {
    margin-bottom: 20px;
  }

  .heath_problem_list_box_map {
    display: block;
    margin: 0 auto;
    width: fit-content;
    column-count: 2;
    padding: 10px 0px;
  }

  .heath_problem_list_box_map_sub {
    flex: 0 0 16.33%;
    max-width: 100%;
    padding: 0 10px;
    margin-bottom: 30px;
  }
}

/*---------------------------------------------------------------Mobile Media Query End---------------------------------------------------------------*/
/*-------------------------------------------------------------Desktop and Laptop Media Query Start-------------------------------------------------------------*/
@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .ourservices_section_box {
    display: block;
    margin: 0 auto;
    width: fit-content;
    column-count: 3;
  }

  .hrLines {
    border-right: none !important;
  }

  .ourservices_section_box .consult_now_services_box {
    margin-bottom: 20px;
  }

  .heath_problem_list_box_map {
    display: block;
    margin: 10px auto;
    width: fit-content;
    column-count: 3;
    padding: 10px 0px;
  }

  .heath_problem_list_box_map_sub {
    flex: 0 0 16.33%;
    max-width: 100%;
    padding: 0 10px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 870px) {
  .sub_section_1 {
    position: relative;
    width: 100%;
    float: left;
  }

  .sub_section_2 {
    width: 100%;
    float: right;
  }

  .logo_box {
    padding: 50px 15px;
    margin-left: 3px;
  }

  .logo_box {
    padding: 0px 0px;
    margin: -650px;
  }

  .slider_1 {
    padding: 10px 30px 50px 30px;
  }

  .owl_banner_img_box {
    padding: 20px 0px;
    width: 400px !important;
    height: 440px;
    margin-top: 100px;
  }

  .signup_box {
    padding: 90px 125px;
  }

  .signup_card {
    padding: 30px 30px;
    background: #fff;
    border-radius: 14px;
  }

  .signup_in_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #15181E;
    margin-bottom: 15px;
  }

  .signup_in_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-top: 0;
  }

  .i_agree_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #4D5764;
    position: absolute;
    margin-top: 1px;
    margin-left: 10px;
  }

  .term_condition_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #199A8E;
    text-decoration: none;
  }

  .request_otp {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    width: -webkit-fill-available;
  }

  .slider_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    color: #fff;
  }

  .gender_active {
    background: #199A8E;
    border-radius: 14px;
    width: 90px;
    position: absolute;
    margin-top: -32px;
    margin-left: -29px;
  }

  .gender_active_2 {
    background: #199A8E;
    border-radius: 14px;
    width: 90px;
    position: absolute;
    margin-top: -32px;
    margin-left: -45px;
  }

  .gender_active_3 {
    background: #199A8E;
    border-radius: 14px;
    width: 90px;
    position: absolute;
    margin-top: -32px;
    margin-left: -26px;
  }

  .common_pagination .next {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    /* width: -webkit-fill-available;
    margin-top: 500px; */
  }

  /*-----------------------Dashboard Home Start-------------------------*/
  .three_section_box {
    display: inline-grid;
    column-gap: 95px;
    row-gap: 20px;
    width: 99.5%;
  }

  .heath_problem_list_box {
    margin-top: 45px;
    display: grid;
    column-gap: 5.3%;
    grid-template-columns: 21% 21% 21% 21%;
    row-gap: 25px;
  }

  .healthproblem_icon_bg {
    background: rgba(25, 154, 142, 0.07);
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 16px;
    padding: 30px 25px;
  }

  .healthproblem_icon {
    width: 50px;
    Height: 50px;
  }

  .home_bannerslide_img {
    width: -webkit-fill-available;
  }

  .btn-outline-secondary {
    border-right: 1px solid #DFDFDF;
    border-left: none;
    border-bottom: none;
    border-top: none;
    padding: 16px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #424448;
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-hover-bg: #F0F0F4;
    --bs-btn-active-color: #424448;
    --bs-btn-hover-color: #424448;
  }

  .city_search_dropdown {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 51px;
    color: #1d1929;
    background: #F0F0F4;
    border-radius: 14px 0 0 14px;
    width: 135px;
  }

  .city_search_input_box {
    background: #F0F0F4;
    border-radius: 14px;
    display: inline-flex;
    width: 100%;
  }

  .city_search_icon_input {
    padding: 18px 13px;
    position: absolute;
  }

  .city_searchicon_box {
    width: 235px;
  }

  .consult_now_box {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_2 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_3 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .health_problem_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
    margin-left: 10px;
    margin-top: 5px;
  }

  .sec_1_slider_text_box {
    padding: 30px 10px 25px 10px;
  }

  .healthproblem_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.3px;
    color: #424448;
    margin-top: 17px;
  }

  .city_search_input {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 54px;
    color: #1d1929;
    padding: 0px 14px 0px 40px;
    background: #F0F0F4;
    border-radius: 0 14px 14px 0;
  }

  .headerNav {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    font-family: "Inter", sans-serif;
    height: auto;
  }

  .login_signup_btn {
    display: none;
  }

  .nav_options_2 {
    margin-left: 10px;
  }

  .medicines_img {
    width: 64px;
    height: 78px;
    float: right;
  }

  .labtests_img {
    width: 61px;
    height: 83px;
    float: right;
  }

  .consult_now_img {
    width: 119px;
    height: 95px;
    float: right;
  }

  /*-----------------------Dashboard Home End-------------------------*/
  /*------------------------------------Chat Screen Start------------------------------------*/
  .chat_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
  }

  .yesno_box {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    width: 220px;
  }

  .yes_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 14px 14px 0 0;
    display: flex;
    justify-content: space-between;
  }

  .no_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 0 0 14px 14px;
    display: flex;
    justify-content: space-between;
  }

  .chat_body_box_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .chat-message-list-inner {
    height: 100vh;
    overflow-y: scroll;
    padding: 30px 5px 24px 5px;
  }

  .chat_modal_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
  }

  .chat_tab_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #909196;
  }

  .chat_tab_option_bg {
    background: #ffffff;
    border-radius: 12px;
    padding: 13px 5px;
    margin-bottom: 20px;
  }

  .chat_tab_option_active_bg {
    background: rgba(25, 154, 142, 0.11);
    border-radius: 8px;
    padding: 13px 10px;
    margin-bottom: 20px;
  }

  .consult_time_btn_box {
    display: inline-flex;
    column-gap: 20px;
    margin-top: 50px;
    margin-bottom: 15px;
    margin-left: 0px;
  }

  .consultant_doc_img {
    Width: 70px;
    Height: 80px;
  }

  .consult_info_box {
    margin-top: 0px;
    padding-left: 40px;
  }

  .consultant_info {
    display: block;
  }

  .available_time {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #199A8E;
    padding: 4px 10px;
    /* background: #FFECDA; */
    border-radius: 6px;
    margin-left: 0px;
  }

  .consultant_qualification_content {
    margin-left: 0px;
  }

  .consult_now_btn {
    background: #199A8E;
    border-radius: 18px;
    border: none;
    text-transform: uppercase;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    padding: 12px 15px;
  }

  /*.consult_info_box {
      padding-left: 15px;
      margin-top: 10px;
    }*/
  /*-------------------------------------Chat Screen End-------------------------------------*/
  .subscription_body_box {
    padding: 0;
    display: inline-grid;
  }

  .subscription_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .subscription_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .start_consultation_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .start_consultation_card_body {
    background: #ffffff;
    box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .start_consultation_card_head_2 {
    background: #ffffff;
    box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .start_consultation_card_body_2 {
    background: #ffffff;
    box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .start_consultation_right_pading {
    padding-right: calc(var(--bs-gutter-x) * .5);
  }

  .start_consultation_left_pading {
    padding-left: calc(var(--bs-gutter-x) * .5);
  }

  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-------------------------Own Profile Consultation Start-------------------------*/
  .upcoming_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .past_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .upcoming_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .past_consultation_card_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DB202B;
  }

  .past_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #199A8E;
  }

  .consultation_option_box_inner_2 {
    padding-left: 10px;
    padding-right: 0px;
    text-align: center;
  }

  .consultation_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 10px;
    text-align: center;
    display: inline-grid;
    justify-items: center;
  }

  .cancel_appointment {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #DB202B;
  }

  .reschedule_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #199A8E;
    cursor: pointer;
  }

  .cancel_icon {
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .rating_star_box {
    float: right;
    /*margin-top: 20px;*/
    margin-right: 26px;
    display: flex;
  }

  .consultation_option_box {
    display: inline-flex;
    float: right;
    margin-top: 33px;
    margin-right: 4px;
  }

  .consultation_cancel_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consultation_cancel_modal_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*--------------------------Own Profile Consultation End--------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 0px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 0px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 0px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 0px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 12px 6px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 12px 14px;
    width: 100%;
  }

  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  .completed_chat_message_list_inner {
    height: 243.6vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  /*------------------------Completed Consult Screen End------------------------*/
  /*-----------------------Canceled Consult Screen Start-----------------------*/
  .canceled_consultation_right_pading {
    padding-right: 0;
  }

  .canceled_consultation_left_pading {
    padding-left: 0;
  }

  .canceled_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .canceled_consultation_chat_card_body {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .canceled_consultation_chat_card_body_2 {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    border-right: 1px solid #ECEFF4;
    padding: 24px 24px;
  }

  .canceled_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .canceled_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: #BE2121;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .canceled_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .canceled_chat_message_list_inner {
    height: 111.9vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  .canceled_chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  /*------------------------Canceled Consult Screen End------------------------*/
  /*----------------------------Profile Test booking Screen Start----------------------------*/
  .profile_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
  }

  .upcoming_test_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .consultation_test_option_box_1 {
    display: inline-flex;
    float: right;
    margin-top: 14px;
    margin-left: 3px;
  }

  .consultation_test_complete_option_box_inner {
    padding-left: 10px;
    padding-right: 0;
  }

  .consultation_test_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 20px;
  }

  .e_report_bg {
    background: #E3F3FB;
    border-radius: 5px;
    padding: 8px 11px 8px 6px;
    display: inline-flex;
    column-gap: 10px;
  }

  .profile_test_tab_option_bg {
    background: #ffffff;
    border-radius: 12px;
    padding: 13px 5px;
    margin-bottom: 20px;
  }

  .profile_test_icon {
    margin-top: -4px;
    width: 14px;
  }

  .profile_test_tab_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #909196;
    margin-left: 6px;
    margin-bottom: 0;
  }

  .profile_test_tab_option_active_bg {
    background: rgba(25, 154, 142, 0.11);
    border-radius: 8px;
    padding: 13px 10px;
    margin-bottom: 20px;
  }

  .profile_test_card_box {
    background: #ffffff;
    padding: 15px 10px 15px 10px;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px;
  }

  .profile_test_arrow_active {
    margin-top: 7px;
    float: right;
  }

  .e-report_dowmload_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #15181E;
    margin-bottom: 0;
    margin-top: 2px;
  }

  .e_report_width {
    width: 70px;
  }

  /*-----------------------------Profile Test booking Screen End-----------------------------*/
  /*----------------------------Test Completed details Screen Start----------------------------*/
  .test_completed_option_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 15px;
  }

  .tracking-item {
    border-left: 2px dashed #199A8E;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .track_dot_bg {
    background: rgba(25, 154, 142, 0.22);
    padding: 3px;
    width: 21px;
    height: 21px;
    border-radius: 20px;
    margin-left: -1px;
  }

  .track_dot_bg_last {
    background: rgba(25, 154, 142, 0.22);
    padding: 3px;
    width: 21px;
    height: 21px;
    border-radius: 20px;
    margin-left: 0px;
  }

  .tracking-item-pending {
    border-left: 2px dashed #C4C4C4;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item-pending .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .track_dot_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -29px;
  }

  .track_dot_last_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -30px;
  }

  .track_dot {
    margin-left: 0px;
    width: 13px;
    margin-top: -2px;
  }

  /*-----------------------------Test Completed details Screen End-----------------------------*/
  /*-------------------------------My Consultation details Screen Start-------------------------------*/
  .my_consultation_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
  }

  .my_consultation_tab_option_text_active {
    color: #199A8E !important;
  }

  .my_consultation_tab_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #909196;
    margin-left: 10px;
    margin-bottom: 0;
  }

  .my_consultation_arrow_active {
    margin-top: 7px;
    float: right;
  }

  /*--------------------------------My Consultation details Screen End--------------------------------*/
  /*-----------------------My Test Booking Screen Start-----------------------*/
  .my_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 23.5px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 10px;
  }

  .radiology_booked_test_profile_box {
    display: inline-grid;
    column-gap: 10px;
  }

  .radiology_booked_test_for_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;

  }

  /*------------------------My Test Booking Screen End------------------------*/
  /*-----------------------Medicine Orders Screen Start-----------------------*/
  .medicine_orders_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
  }

  .medicine_order_box_inner {
    text-align: right;
    margin-top: 10px;
  }

  /*------------------------Medicine Orders Screen End------------------------*/
  /*-----------------------Health Package Screen Start-----------------------*/
  .health_package_Recommended {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #15181E;
  }

  .popular_package_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    margin-bottom: 20px;
    height: 240px;
  }

  .health_package_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: auto;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .health_package_right_owl_slider_img {
    Width: 100%;
    Height: auto;
    border-radius: 14px;
  }

  .popular_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #15181E;
  }

  .health_package_container {
    background: #F5F5F5;
    padding: 30px 0px 50px 0;
  }

  .health_package_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .book_test_btn {
    background: #199A8E;
    border: 1px solid rgba(137, 152, 189, 0.2);
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 8px 10px;
    margin-top: 28px;
  }

  .lab_text_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 8px;
  }

  .lab_test_subtext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #424448;
    margin-bottom: 0;
  }

  .search input {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .search .results {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    width: -webkit-fill-available;
    padding: 20px 16px;
  }

  /*------------------------Health Package Screen End------------------------*/
  /*--------------------------Wallet Screen Start--------------------------*/
  .wallet_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .wallet_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .wallet_card_body_box {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .wallet_card_body_value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #424448;
    margin-bottom: 5px;
  }

  .wallet_card_body_for {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  .profiledash_tab_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #909196;
    margin-left: 10px;
    margin-bottom: 0;
  }

  .my_consultation_tab_option_text_active {
    color: #199A8E !important;
  }

  /*---------------------------Wallet Screen End---------------------------*/
  .payment_body_box {
    width: 100%;
    height: 365px;
    padding: 280px 135px;
  }

  .appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 60px;
    margin-top: 7px;
  }

  /*--------------------------Subscription Package Screen Start--------------------------*/
  .subscription_package_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .subscription_package_option_box {
    display: flex;
    float: inherit;
    margin-top: 33px;
    justify-content: left;
  }

  .subscription_package_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #424448;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .subscription_package_members_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .subscription_package_members_profile_box {
    display: flex;
    column-gap: 25px;
    row-gap: 20px;
    margin-bottom: 10px;
  }

  .subscription_package_body_card_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    float: left;
    margin-top: 5px;
  }

  .subscription_package_consultation_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  .subscription_package_consultation_complete_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #199A8E;
    margin-bottom: 0;
  }

  .subscription_package_consultation_cancel_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #DB202B;
    margin-bottom: 0;
  }

  .subscription_package_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 0px;
    text-align: center;
  }

  /*---------------------------Subscription Package Screen End---------------------------*/
  /*--------------------------Follow Up Consultation Screen Start--------------------------*/
  .follow_up_upcoming_consultation_box {
    display: flex;
    column-gap: 24px;
    row-gap: 25px;
  }

  .follow_up_consult_width {
    width: -webkit-fill-available;
  }

  .follow_up_test_width {
    width: 290px;
  }
  .follow_up_test_widths {
    width: auto;
  }

  .follow_up_upcoming_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 15px;
    text-align: center;
  }

  .follow_up_upcoming_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .follow_up_upcoming_cancel_icon {
    margin-right: 5px;
    margin-top: -3px;
    cursor: pointer;
  }

  .follow_up_upcoming_cancel_appointment {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #DB202B;
    cursor: pointer;
  }

  /*---------------------------Follow Up Consultation Screen End---------------------------*/
  /*--------------------------Follow Up Subscription Plan Screen Start--------------------------*/
  .follow_up_subscription_plan_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .follow_up_subscription_plan_body_box {
    padding: 0;
    display: inline-grid;
  }

  .follow_up_subscription_plan_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .follow_up_subscription_plan_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .follow_up_subscription_plan_book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .follow_up_subscription_plan_box_inner {
    width: 225px;
  }

  .popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  .follow_up_subscription_plan_tab_option_active_bg {
    background: rgba(25, 154, 142, 0.11);
    border-radius: 8px;
    padding: 13px 15px;
    margin-bottom: 20px;
  }

  .follow_up_subscription_plan_box {
    background: #ffffff;
    padding: 20px 10px 20px 10px;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px;
    height: 100%;
  }

  .follow_up_subscription_plan_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #909196;
  }

  .chat_arrow_active {
    margin-top: 8px;
    float: right;
  }

  .follow_up_subscription_plan_tab_option_text_active {
    color: #199A8E;
  }

  /*---------------------------Follow Up Subscription Plan Screen End---------------------------*/
  /*--------------------------medicine details Screen Start--------------------------*/
  .medicine_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: 100%;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .medicine_slider {
    display: flex;
    float: left;
    grid-column-gap: 0px;
    border-right: 1px solid #ECEFF4;
    border-bottom: 1px solid #ECEFF4;
    padding: 0px;
  }

  .medicine_slider_box_active {
    width: 70px;
    height: 70px;
    background: #FFFFFF;
    border: 1px solid #199A8E;
    border-radius: 6px;
    margin-top: 10px;
  }

  .medicine_slider_box {
    width: 70px;
    height: 70px;
    background: #FFFFFF;
    border-radius: 6px;
    margin-top: 10px;
  }

  .product_details {
    padding: 0;
  }

  .product_details_para {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #67696C;
    align-items: center;
    text-align: justify;
  }

  .medicine_slider_img {
    width: 100%;
    height: 100%;
  }

  /*---------------------------medicine details Screen End---------------------------*/
  /*---------------------------Test completed details Screen start---------------------------*/
  .booking_placed_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #909196;
    margin-top: -40px;
  }

  .booking_placed_title.active {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #199A8E;
    margin-top: -40px;
  }

  .order-tracking.completed p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #909196;
    margin-top: 40px;
    margin-bottom: 0;
  }

  .order-tracking p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #909196;
    margin-top: 40px;
    margin-bottom: 0;
  }

  .cancellation_reason_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #15181E;
    margin-bottom: 0;
  }

  .booking_cancelled_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #DB202B;
    margin-top: -40px;
  }

  /*---------------------------Test completed details Screen End---------------------------*/

}

@media only screen and (min-width: 871px) and (max-width: 991px) {
  .sub_section_1 {
    position: relative;
    width: 50%;
    float: left;
  }

  .sub_section_2 {
    width: 50%;
    float: right;
  }

  .logo_box {
    padding: 50px 15px;
    margin-left: 3px;
  }

  .logo_box {
    padding: 0px 0px;
    margin-left: -590px;
  }

  .slider_1 {
    padding: 10px 30px 50px 30px;
  }

  .owl_banner_img_box {
    padding: 20px 0px;
    width: 340px !important;
    height: 380px;
  }

  .signup_box {
    padding: 350px 20px;
  }

  .signup_card {
    padding: 25px 30px;
    background: #fff;
    border-radius: 14px;
  }

  .signup_in_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #15181E;
    margin-bottom: 15px;
  }

  .signup_in_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-top: 0;
  }

  .i_agree_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4D5764;
    position: absolute;
    margin-top: 1px;
    margin-left: 10px;
  }

  .term_condition_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #199A8E;
    text-decoration: none;
  }

  .request_otp {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    width: -webkit-fill-available;
  }

  .slider_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    color: #fff;
  }

  /*----------------------------------------------------------------------------------*/
  .verification_code_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 135%;
    text-align: center;
    color: #15181E;
    margin-bottom: 0;
  }

  .verification_code_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  .verification_number {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #199A8E;
    margin-bottom: 0;
    margin-top: 12px;
  }

  .receive_the_code {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    align-items: center;
    color: #67696C;
    text-align: center;
  }

  .resend {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    align-items: center;
    color: #199A8E;
    text-decoration: none;
  }

  /*----------------------------------------------------------------------------------*/
  .signup_details_form_box {
    padding: 80px 10px;
  }

  .gender_option {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 14px 20px;
    justify-content: left;
    width: 78px;
    height: 80px;
  }

  .gender_icon {
    width: 22px;
    height: 22px;
    margin-top: 5px;
    margin-bottom: 3px;
  }

  .signup_details_box {
    padding: 20px 8px;
  }

  .gender_option_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #909196;
  }

  .gender_active {
    background: #199A8E !important;
    border-radius: 14px;
    width: 78px;
    position: absolute;
    margin-top: -32px;
    margin-left: -25px;
  }

  .gender_active_2 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 78px;
    position: absolute;
    margin-top: -32px;
    margin-left: -39px;
  }

  .gender_active_3 {
    background: #199A8E !important;
    border-radius: 14px;
    width: 78px;
    position: absolute;
    margin-top: -32px;
    margin-left: -22px;
  }

  .gender_option_active_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
  }

  .common_pagination .next {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    /* width: -webkit-fill-available;
    margin-top: 500px; */
  }

  /*-----------------------Dashboard Home Start-------------------------*/
  .three_section_box {
    display: inline-flex;
    column-gap: 13px;
    row-gap: 20px;
    width: 100%;
  }

  .heath_problem_list_box {
    margin-top: 45px;
    display: grid;
    column-gap: 5.3%;
    grid-template-columns: 21% 21% 21% 21%;
    row-gap: 25px;
  }

  .healthproblem_icon_bg {
    background: rgba(25, 154, 142, 0.07);
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 16px;
    padding: 30px 25px;
  }

  .healthproblem_icon {
    width: 50px;
    Height: 50px;
  }

  .home_bannerslide_img {
    width: -webkit-fill-available;
  }

  .btn-outline-secondary {
    border-right: 1px solid #DFDFDF;
    border-left: none;
    border-bottom: none;
    border-top: none;
    padding: 16px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #424448;
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-hover-bg: #F0F0F4;
    --bs-btn-active-color: #424448;
    --bs-btn-hover-color: #424448;
  }

  .city_search_dropdown {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 51px;
    color: #1d1929;
    background: #F0F0F4;
    border-radius: 14px 0 0 14px;
    width: 135px;
  }

  .city_search_input_box {
    background: #F0F0F4;
    border-radius: 14px;
    display: inline-flex;
    width: 100%;
  }

  .city_search_icon_input {
    padding: 18px 13px;
    position: absolute;
  }

  .city_searchicon_box {
    width: 235px;
  }

  .consult_now_box {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_2 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_3 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .health_problem_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .sec_1_slider_text_box {
    padding: 30px 10px 25px 10px;
  }

  .healthproblem_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.3px;
    color: #424448;
    margin-top: 17px;
  }

  .city_search_input {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 54px;
    color: #1d1929;
    padding: 0px 14px 0px 40px;
    background: #F0F0F4;
    border-radius: 0 14px 14px 0;
  }

  .headerNav {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    font-family: "Inter", sans-serif;
    height: auto;
  }

  .login_signup_btn {
    display: none;
  }

  .nav_options_2 {
    margin-left: 10px;
  }

  .medicines_img {
    width: 64px;
    height: 78px;
    float: right;
  }

  .labtests_img {
    width: 61px;
    height: 83px;
    float: right;
  }

  .consult_now_img {
    width: 119px;
    height: 95px;
    float: right;
  }

  .consult_now_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #424448;
  }

  .consult_text_box {
    padding: 22px 10px 22px 13px;
  }

  /*-----------------------Dashboard Home End-------------------------*/
  /*------------------------------------Chat Screen Start------------------------------------*/
  .chat_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
  }

  .yesno_box {
    background: #FFFFFF;
    border: 1px solid #E2E2E6;
    border-radius: 14px;
    width: 220px;
  }

  .yes_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 14px 14px 0 0;
    display: flex;
    justify-content: space-between;
  }

  .no_box {
    background: #FFFFFF;
    padding: 14px;
    border-bottom: 0.8px dashed #E2E2E6;
    width: 215px;
    border-radius: 0 0 14px 14px;
    display: flex;
    justify-content: space-between;
  }

  .chat_body_box_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .chat-message-list-inner {
    height: 100vh;
    overflow-y: scroll;
    padding: 30px 5px 24px 5px;
  }

  .chat_modal_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
  }

  .chat_tab_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #909196;
  }

  .chat_tab_option_bg {
    background: #ffffff;
    border-radius: 12px;
    padding: 13px 5px;
    margin-bottom: 20px;
  }

  .chat_tab_option_active_bg {
    background: rgba(25, 154, 142, 0.11);
    border-radius: 8px;
    padding: 13px 10px;
    margin-bottom: 20px;
  }

  .consult_time_btn_box {
    display: inline-flex;
    column-gap: 20px;
    margin-top: 50px;
    margin-bottom: 15px;
    margin-left: 0px;
  }

  .consultant_doc_img {
    Width: 70px;
    Height: 80px;
  }

  .consult_info_box {
    margin-top: 0px;
    padding-left: 40px;
  }

  .consult_now_btn {
    background: #199A8E;
    border-radius: 18px;
    border: none;
    text-transform: uppercase;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    padding: 12px 15px;
  }

  .consult_info_box {
    padding-left: 15px;
    margin-top: 10px;
  }

  /*-------------------------------------Chat Screen End-------------------------------------*/
  .subscription_body_box {
    padding: 0;
    display: inline-grid;
  }

  .subscription_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .subscription_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-------------------------Own Profile Consultation Start-------------------------*/
  .upcoming_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .past_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .upcoming_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .past_consultation_card_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DB202B;
  }

  .past_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #199A8E;
  }

  .consultation_option_box_inner_2 {
    padding-left: 10px;
    padding-right: 0px;
    text-align: center;
  }

  .consultation_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 10px;
    text-align: center;
    display: inline-grid;
    justify-items: center;
  }

  .cancel_appointment {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #DB202B;
  }

  .cancel_icon {
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .rating_star_box {
    float: right;
    margin-top: 20px;
    margin-right: 26px;
  }

  .consultation_option_box {
    display: inline-flex;
    float: right;
    margin-top: 33px;
    margin-right: 4px;
  }

  .consultation_cancel_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consultation_cancel_modal_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*--------------------------Own Profile Consultation End--------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 0px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 0px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 0px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 0px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 12px 6px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 12px 14px;
    width: 100%;
  }

  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  .completed_chat_message_list_inner {
    height: 243.6vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  /*------------------------Completed Consult Screen End------------------------*/
  /*-----------------------Canceled Consult Screen Start-----------------------*/
  .canceled_consultation_right_pading {
    padding-right: 0;
  }

  .canceled_consultation_left_pading {
    padding-left: 0;
  }

  .canceled_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .canceled_consultation_chat_card_body {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .canceled_consultation_chat_card_body_2 {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    border-right: 1px solid #ECEFF4;
    padding: 24px 24px;
  }

  .canceled_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .canceled_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #BE2121;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .canceled_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .canceled_chat_message_list_inner {
    height: 111.9vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  .canceled_chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  /*------------------------Canceled Consult Screen End------------------------*/
  /*----------------------------Profile Test booking Screen Start----------------------------*/
  .profile_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
  }

  .upcoming_test_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .consultation_test_option_box_1 {
    display: inline-flex;
    float: right;
    margin-top: 14px;
    margin-left: 3px;
  }

  .consultation_test_complete_option_box_inner {
    padding-left: 10px;
    padding-right: 0;
  }

  .consultation_test_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 20px;
  }

  .e_report_bg {
    background: #E3F3FB;
    border-radius: 5px;
    padding: 8px 11px 8px 6px;
    display: inline-flex;
    column-gap: 10px;
  }

  .profile_test_tab_option_bg {
    background: #ffffff;
    border-radius: 12px;
    padding: 13px 5px;
    margin-bottom: 20px;
  }

  .profile_test_icon {
    margin-top: -4px;
    width: 14px;
  }

  .profile_test_tab_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #909196;
    margin-left: 6px;
    margin-bottom: 0;
  }

  .profile_test_tab_option_active_bg {
    background: rgba(25, 154, 142, 0.11);
    border-radius: 8px;
    padding: 13px 10px;
    margin-bottom: 20px;
  }

  .profile_test_card_box {
    background: #ffffff;
    padding: 15px 10px 15px 10px;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px;
  }

  .profile_test_arrow_active {
    margin-top: 7px;
    float: right;
  }

  .e-report_dowmload_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #15181E;
    margin-bottom: 0;
    margin-top: 2px;
  }

  .e_report_width {
    width: 70px;
  }

  /*-----------------------------Profile Test booking Screen End-----------------------------*/
  /*----------------------------Test Completed details Screen Start----------------------------*/
  .test_completed_option_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 15px;
  }

  .tracking-item {
    border-left: 2px dashed #199A8E;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .track_dot_bg {
    background: rgba(25, 154, 142, 0.22);
    padding: 3px;
    width: 21px;
    height: 21px;
    border-radius: 20px;
    margin-left: 0.5px;
  }

  .track_dot_bg_last {
    background: rgba(25, 154, 142, 0.22);
    padding: 3px;
    width: 21px;
    height: 21px;
    border-radius: 20px;
    margin-left: 2px;
  }

  .tracking-item-pending {
    border-left: 2px dashed #C4C4C4;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item-pending .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .track_dot_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -29px;
  }

  .track_dot_last_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -30px;
  }

  .track_dot {
    margin-left: 0px;
    width: 13px;
    margin-top: -2px;
  }

  /*-----------------------------Test Completed details Screen End-----------------------------*/
  /*-------------------------------My Consultation details Screen Start-------------------------------*/
  .my_consultation_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
  }

  .my_consultation_tab_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #909196;
    margin-left: 10px;
    margin-bottom: 0;
  }

  .my_consultation_arrow_active {
    margin-top: 7px;
    float: right;
  }

  /*--------------------------------My Consultation details Screen End--------------------------------*/
  /*-----------------------My Test Booking Screen Start-----------------------*/
  .my_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 23.5px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .my_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 10px;
  }

  .radiology_booked_test_profile_box {
    display: inline-grid;
    column-gap: 10px;
  }

  .radiology_booked_test_for_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
    text-align: center;
  }

  /*------------------------My Test Booking Screen End------------------------*/
  /*-----------------------Health Package Screen Start-----------------------*/
  .health_package_Recommended {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #15181E;
  }

  .popular_package_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    margin-bottom: 20px;
    height: 240px;
  }

  .health_package_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: auto;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .health_package_right_owl_slider_img {
    Width: 100%;
    Height: auto;
    border-radius: 14px;
  }

  .popular_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #15181E;
  }

  .health_package_container {
    background: #F5F5F5;
    padding: 30px 0px 50px 0;
  }

  .health_package_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .book_test_btn {
    background: #199A8E;
    border: 1px solid rgba(137, 152, 189, 0.2);
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 8px 10px;
    margin-top: 28px;
  }

  .lab_text_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 8px;
  }

  .lab_test_subtext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #424448;
    margin-bottom: 0;
  }

  .search input {
    background: #F0F0F4;
    border-radius: 14px;
    width: -webkit-fill-available;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .search .results {
    display: none;
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    width: -webkit-fill-available;
    padding: 20px 16px;
  }

  /*------------------------Health Package Screen End------------------------*/
  /*--------------------------Wallet Screen Start--------------------------*/
  .wallet_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .wallet_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .wallet_card_body_box {
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .wallet_card_body_value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #424448;
    margin-bottom: 5px;
  }

  .wallet_card_body_for {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  .profiledash_tab_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #909196;
    margin-left: 10px;
    margin-bottom: 0;
  }

  .my_consultation_tab_option_text_active {
    color: #199A8E !important;
  }

  /*---------------------------Wallet Screen End---------------------------*/
  .payment_body_box {
    height: 400px;
    width: 100%;
    padding: 280px 190px;
  }

  /*--------------------------Subscription Package Screen Start--------------------------*/
  .subscription_package_container {
    background: #F5F5F5;
    padding: 30px 0px 40px 0;
  }

  .subscription_package_option_box {
    display: flex;
    float: inherit;
    margin-top: 33px;
    justify-content: left;
  }

  .subscription_package_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #424448;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .subscription_package_members_box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .subscription_package_members_profile_box {
    display: flex;
    column-gap: 25px;
    row-gap: 20px;
    margin-bottom: 10px;
  }

  .subscription_package_body_card_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    float: left;
    margin-top: 5px;
  }

  .subscription_package_consultation_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 0;
  }

  .subscription_package_consultation_complete_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #199A8E;
    margin-bottom: 0;
  }

  .subscription_package_consultation_cancel_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #DB202B;
    margin-bottom: 0;
  }

  .subscription_package_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .subscription_package_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 0px;
    text-align: center;
  }

  /*---------------------------Subscription Package Screen End---------------------------*/
  /*--------------------------Follow Up Consultation Screen Start--------------------------*/
  .follow_up_upcoming_consultation_box {
    display: flex;
    column-gap: 24px;
    row-gap: 25px;
  }

  .follow_up_consult_width {
    width: -webkit-fill-available;
  }

  .follow_up_test_width {
    width: 290px;
  }
  .follow_up_test_widths {
    width: auto;
  }

  .follow_up_upcoming_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 15px;
    text-align: center;
  }

  .follow_up_upcoming_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .follow_up_upcoming_cancel_icon {
    margin-right: 5px;
    margin-top: -3px;
    cursor: pointer;
  }

  .follow_up_upcoming_cancel_appointment {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #DB202B;
    cursor: pointer;
  }

  .subscription_package_reschedule_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #199A8E;
    cursor: pointer;
  }

  /*---------------------------Follow Up Consultation Screen End---------------------------*/
  /*--------------------------Follow Up Subscription Plan Screen Start--------------------------*/
  .follow_up_subscription_plan_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
    margin-top: 20px;
  }

  .follow_up_subscription_plan_body_box {
    padding: 0;
    display: inline-grid;
  }

  .follow_up_subscription_plan_inner_1 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 30px 0px 15px 0;
  }

  .follow_up_subscription_plan_inner_2 {
    width: -webkit-fill-available;
    height: 100%;
    padding: 15px 0px 30px 0px;
    border-left: none;
  }

  .follow_up_subscription_plan_book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .follow_up_subscription_plan_box_inner {
    width: 225px;
  }

  .popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  .follow_up_subscription_plan_tab_option_active_bg {
    background: rgba(25, 154, 142, 0.11);
    border-radius: 8px;
    padding: 13px 15px;
    margin-bottom: 20px;
  }

  .follow_up_subscription_plan_box {
    background: #ffffff;
    padding: 20px 10px 20px 10px;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px;
    height: 100%;
  }

  .follow_up_subscription_plan_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #909196;
  }

  .chat_arrow_active {
    margin-top: 8px;
    float: right;
  }

  .follow_up_subscription_plan_tab_option_text_active {
    color: #199A8E;
  }

  /*---------------------------Follow Up Subscription Plan Screen End---------------------------*/
}

@media (max-width: 991px) {}

@media (max-width: 1200px) {}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .sub_section_1 {
    position: relative;
    width: 50%;
    float: left;
  }

  .sub_section_2 {
    width: 50%;
    float: right;
  }

  .logo_box {
    padding: 0px 0px;
    /* margin-left: 0px; */
  }

  .slider_1 {
    padding: 10px 30px 50px 30px;
  }

  .owl_banner_img_box {
    padding: 20px 0px;
    width: 340px !important;
    height: 380px;
  }

  /* .center_logo{
    margin-left:600px;
  } */
  .signup_box {
    padding: 280px 20px;
  }

  .signup_card {
    padding: 25px 30px;
    background: #fff;
    border-radius: 14px;
  }

  .signup_in_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #15181E;
    margin-bottom: 15px;
  }

  .signup_in_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-top: 0;
  }

  .i_agree_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4D5764;
    position: absolute;
    margin-top: 1px;
    margin-left: 10px;
  }

  .term_condition_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #199A8E;
    text-decoration: none;
  }

  .request_otp {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    width: -webkit-fill-available;
  }

  .slider_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    color: #fff;
  }

  .signup_details_form_box {
    padding: 80px 10px;
  }

  .previous {
    /* background: #199A8E; */
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #FFFFFF !important;
    border: 0;
    padding: 7px 15px;
    /* width: -webkit-fill-available; */
    /* margin-top: 500px; */
  }

  .pagination li {
    background: #fff;
    border: 1px solid #199A8E !important;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #199A8E;
    border: 0;
    padding: 7px 15px;
    margin-right: 10px;
  }

  .common_pagination .next {
    background: #199A8E !important;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #FFFFFF !important;
    border: 0;
    padding: 7px 15px;
    /* width: -webkit-fill-available; */
    /* margin-top: 500px; */
  }

  /*-----------------------Dashboard Home Start-------------------------*/
  .three_section_box {
    display: inline-flex;
    column-gap: 13px;
    row-gap: 20px;
    width: 100%;
  }

  .heath_problem_list_box {
    margin-top: 45px;
    display: grid;
    column-gap: 5%;
    grid-template-columns: 16% 16% 16% 16% 16%;
    row-gap: 25px;
  }

  .healthproblem_icon_bg {
    background: rgba(25, 154, 142, 0.07);
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 16px;
    padding: 30px 25px;
  }

  .healthproblem_icon {
    width: 50px;
    Height: 50px;
  }

  .home_bannerslide_img {
    width: -webkit-fill-available;
  }

  .btn-outline-secondary {
    border-right: 1px solid #DFDFDF;
    border-left: none;
    border-bottom: none;
    border-top: none;
    padding: 16px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #424448;
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-hover-bg: #F0F0F4;
    --bs-btn-active-color: #424448;
    --bs-btn-hover-color: #424448;
  }

  .city_search_dropdown {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 51px;
    color: #1d1929;
    background: #F0F0F4;
    border-radius: 14px 0 0 14px;
    width: 135px;
  }

  .city_search_input_box {
    background: #F0F0F4;
    border-radius: 14px;
    display: inline-flex;
    width: 100%;
  }

  .city_search_icon_input {
    padding: 21px 13px;
    margin-left: 7px;
    position: absolute;
  }

  .city_searchicon_box {
    width: 235px;
  }

  .consult_now_box {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_2 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_3 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .health_problem_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .sec_1_slider_text_box {
    padding: 30px 10px 25px 10px;
  }

  .healthproblem_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.3px;
    color: #424448;
    margin-top: 17px;
  }

  .city_search_input {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 54px;
    color: #1d1929;
    padding: 0px 14px 0px 40px;
    background: #F0F0F4;
    border-radius: 0 14px 14px 0;
  }

  .headerNav {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    font-family: "Inter", sans-serif;
    height: auto;
  }

  .login_signup_btn {
    display: none;
  }

  .nav_options_2 {
    margin-left: 90px;
  }

  .medicines_img {
    width: 64px;
    height: 78px;
    float: right;
  }

  .labtests_img {
    width: 61px;
    height: 83px;
    float: right;
  }

  .consult_now_img {
    width: 119px;
    height: 95px;
    float: right;
  }

  .consult_now_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #424448;
  }

  .consult_text_box {
    padding: 22px 10px 22px 13px;
  }

  /*-----------------------Dashboard Home End-------------------------*/
  /*------------------------------------Chat Screen Start------------------------------------*/
  .consult_time_btn_box {
    display: inline-flex;
    column-gap: 30px;
    margin-top: 50px;
    margin-bottom: 15px;
    margin-left: 0px;
  }

  .consultant_doc_img {
    Width: 70px;
    Height: 80px;
  }

  .consult_info_box {
    margin-top: 0px;
    padding-left: 15px;
  }

  .consult_now_btn {
    background: #199A8E;
    border-radius: 18px;
    border: none;
    text-transform: uppercase;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    padding: 12px 20px
  }

  .chat_tab_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #909196;
  }

  .chat_arrow_active {
    margin-top: 7px;
    float: right;
  }

  .consult_info_box {
    padding-left: 15px;
    margin-top: 10px;
  }

  /*-------------------------------------Chat Screen End-------------------------------------*/
  .wating_box {
    margin-top: 447px;
    margin-bottom: 360px;
  }

  .appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 140px;
    margin-top: 7px;
  }

  .subscription_plan_box {
    width: 230px;
  }

  /*-------------------------------------Profile Dashboard Screen Start-------------------*/
  .addnewmember {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 35px;
    cursor: pointer;
  }

  /*-------------------------------------Profile Dashboard Screen End-------------------*/
  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-------------------------Own Profile Consultation Start-------------------------*/
  .upcoming_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .past_consultation_card_icon {
    margin-top: -3px;
    margin-right: 5px;
  }

  .upcoming_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .past_consultation_card_title_2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #DB202B;
  }

  .past_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #199A8E;
  }

  .consultation_option_box_inner_2 {
    padding-left: 10px;
    padding-right: 0px;
    text-align: center;
  }

  .consultation_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 10px;
    text-align: center;
  }

  .cancel_appointment {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #DB202B;
  }

  .cancel_icon {
    margin-right: 5px;
    margin-top: 3px;
    margin-bottom: 5px;
  }

  .rating_star_box {
    float: right;
    margin-top: 0px;
    margin-right: 26px;
    display: block;
  }

  .prescription_btn {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    width: 146px;
    height: 36px;
    background: #E6F4F3;
    border-radius: 5px;
    color: #424448;
    margin-top: 0px;
    margin-left: 10px;
    float: right;
  }

  .consultation_option_box {
    display: inline-flex;
    float: right;
    margin-top: 33px;
    margin-right: 4px;
  }

  .consultation_cancel_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #424448;
    margin-bottom: 0;
  }

  .consultation_cancel_modal_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
  }

  /*--------------------------Own Profile Consultation End--------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 0px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 0px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 0px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 0px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 12px 6px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 12px 14px;
    width: 100%;
  }

  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  .completed_chat_message_list_inner {
    height: 229.8vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  /*------------------------Completed Consult Screen End------------------------*/
  /*-----------------------Canceled Consult Screen Start-----------------------*/
  .canceled_consultation_right_pading {
    padding-right: 0;
  }

  .canceled_consultation_left_pading {
    padding-left: 0;
  }

  .canceled_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .canceled_consultation_chat_card_body {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .canceled_consultation_chat_card_body_2 {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    border-right: 1px solid #ECEFF4;
    padding: 24px 24px;
  }

  .canceled_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .canceled_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #BE2121;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .canceled_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .canceled_chat_message_list_inner {
    height: 104.9vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  .canceled_chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  /*------------------------Canceled Consult Screen End------------------------*/
  /*----------------------------Profile Test booking Screen Start----------------------------*/
  .profile_test_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
  }

  .upcoming_test_consultation_card_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #FFFFFF;
  }

  .consultation_test_option_box_1 {
    display: inline-flex;
    float: right;
    margin-top: 14px;
    margin-left: 3px;
  }

  .consultation_test_complete_option_box_inner {
    padding-left: 10px;
    padding-right: 0;
  }

  .consultation_test_option_box_inner_2 {
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box_inner_1 {
    border-right: 1px solid #E2E2E6;
    padding-right: 22px;
    text-align: center;
  }

  .consultation_test_option_box {
    display: inline-flex;
    float: right;
    margin-top: 20px;
  }

  .e_report_bg {
    background: #E3F3FB;
    border-radius: 5px;
    padding: 8px 11px 8px 6px;
    display: inline-flex;
    column-gap: 10px;
  }

  .profile_test_tab_option_bg {
    background: #ffffff;
    border-radius: 12px;
    padding: 13px 5px;
    margin-bottom: 20px;
  }

  .profile_test_icon {
    margin-top: -4px;
    width: 14px;
  }

  .profile_test_tab_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #909196;
    margin-left: 6px;
    margin-bottom: 0;
  }

  .profile_test_tab_option_active_bg {
    background: rgba(25, 154, 142, 0.11);
    border-radius: 8px;
    padding: 13px 10px;
    margin-bottom: 20px;
  }

  .profile_test_card_box {
    background: #ffffff;
    padding: 15px 10px 15px 10px;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px;
  }

  .profile_test_arrow_active {
    margin-top: 7px;
    float: right;
  }

  .e-report_dowmload_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    color: #15181E;
    margin-bottom: 0;
    margin-top: 2px;
  }

  .e_report_width {
    width: 70px;
  }

  /*-----------------------------Profile Test booking Screen End-----------------------------*/

  /*----------------------------Test Completed details Screen Start----------------------------*/
  .test_completed_option_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 15px;
  }

  .tracking-item {
    border-left: 2px dashed #199A8E;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .track_dot_bg {
    background: rgba(25, 154, 142, 0.22);
    padding: 3px;
    width: 21px;
    height: 21px;
    border-radius: 20px;
    margin-left: 0.5px;
  }

  .track_dot_bg_last {
    background: rgba(25, 154, 142, 0.22);
    padding: 3px;
    width: 21px;
    height: 21px;
    border-radius: 20px;
    margin-left: 2px;
  }

  .tracking-item-pending {
    border-left: 2px dashed #C4C4C4;
    position: relative;
    padding: 0rem 1.5rem 0.5rem 1.5rem;
    font-size: 0.9rem;
    margin-left: 5px;
    min-height: 4rem;
  }

  .tracking-item-pending .tracking-content {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #424448;
    position: absolute;
    margin-top: -20px;
  }

  .test_completed_status_box {
    display: inline-flex;
    column-gap: 12px;
    float: right;
  }

  .track_dot_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -29px;
  }

  .track_dot_last_notactive {
    margin-left: 0px;
    width: 13px;
    margin-top: -30px;
  }

  .track_dot {
    margin-left: 0px;
    width: 13px;
    margin-top: -2px;
  }

  /*-----------------------------Test Completed details Screen End-----------------------------*/
  /*-----------------------Health Package Screen Start-----------------------*/
  .health_package_Recommended {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #15181E;
  }

  .popular_package_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    margin-bottom: 20px;
    height: 270px;
  }

  .health_package_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: auto;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .health_package_right_owl_slider_img {
    Width: 100%;
    Height: auto;
    border-radius: 14px;
  }

  .popular_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #15181E;
  }

  .health_package_container {
    background: #F5F5F5;
    padding: 30px 0px 50px 0;
  }

  .health_package_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .book_test_btn {
    background: #199A8E;
    border: 1px solid rgba(137, 152, 189, 0.2);
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 8px 10px;
    margin-top: 28px;
  }

  .lab_text_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 8px;
  }

  .lab_test_subtext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #424448;
    margin-bottom: 0;
  }

  /*------------------------Health Package Screen End------------------------*/
  .payment_body_box {
    height: 400px;
    width: 100%;
    padding: 280px 190px;
  }

  /*--------------------------My Subscription Screen Start--------------------------*/
  .my_subscription_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
  }

  .my_subscription_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .address_body_card_head_add_icon {
    margin-right: 10px;
    margin-top: -3px;
  }

  .address_body_card_head_new_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    margin-top: 15px;
    float: right;
  }

  .my_subscription_card_inner {
    display: flex;
    justify-content: space-between;
    row-gap: 25px;
  }

  /*---------------------------My Subscription Screen End---------------------------*/
  /*--------------------------Follow Up Subscription Plan Screen Start--------------------------*/
  .follow_up_subscription_plan_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
  }

  .follow_up_subscription_plan_book_consult_btn {
    background: #199A8E;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    padding: 17px 25px 17px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #FBFCFC;
  }

  .follow_up_subscription_plan_box_inner {
    width: 225px;
  }

  .popup_modal_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 0;
  }

  .follow_up_subscription_plan_tab_option_active_bg {
    background: rgba(25, 154, 142, 0.11);
    border-radius: 8px;
    padding: 13px 15px;
    margin-bottom: 20px;
  }

  .follow_up_subscription_plan_box {
    background: #ffffff;
    padding: 20px 10px 20px 10px;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px;
    height: 100%;
  }

  .follow_up_subscription_plan_option_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #909196;
  }

  .chat_arrow_active {
    margin-top: 5px;
    float: right;
  }

  .follow_up_subscription_plan_tab_option_text_active {
    color: #199A8E;
  }

  /*---------------------------Follow Up Subscription Plan Screen End---------------------------*/

}

@media only screen and (min-width: 1200px) and (max-width: 1359px) {
  .sub_section_1 {
    position: relative;
    width: 50%;
    float: left;
  }

  .sub_section_2 {
    width: 50%;
    float: right;
  }

  .logo_box {
    padding: 50px 15px;
    /* margin-left: 3px; */
  }

  .slider_1 {
    padding: 10px 30px 50px 30px;
  }

  .owl_banner_img_box {
    padding: 20px 0px;
    width: 340px !important;
    height: 380px;
  }

  .signup_box {
    padding: 350px 70px;
  }

  .signup_card {
    padding: 25px 30px;
    background: #fff;
    border-radius: 14px;
  }

  .signup_in_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #15181E;
    margin-bottom: 15px;
  }

  .signup_in_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-top: 0;
  }

  .i_agree_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4D5764;
    position: absolute;
    margin-top: 1px;
    margin-left: 10px;
  }

  .term_condition_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #199A8E;
    text-decoration: none;
  }

  .request_otp {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    width: -webkit-fill-available;
  }

  .slider_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #fff;
  }

  .signup_details_form_box {
    padding: 80px 10px;
  }

  /*-----------------------Dashboard Home Start-------------------------*/
  .three_section_box {
    display: inline-flex;
    column-gap: 13px;
    row-gap: 20px;
    width: 100%;
  }

  .heath_problem_list_box {
    margin-top: 45px;
    display: grid;
    column-gap: 2%;
    grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11%;
    row-gap: 25px;
  }

  .healthproblem_icon_bg {
    background: rgba(25, 154, 142, 0.07);
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 16px;
    padding: 30px 25px;
  }

  .healthproblem_icon {
    width: 50px;
    Height: 50px;
  }

  .home_bannerslide_img {
    width: -webkit-fill-available;
  }

  .btn-outline-secondary {
    border-right: 1px solid #DFDFDF;
    border-left: none;
    border-bottom: none;
    border-top: none;
    padding: 16px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #424448;
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-hover-bg: #F0F0F4;
    --bs-btn-active-color: #424448;
    --bs-btn-hover-color: #424448;
  }

  .city_search_dropdown {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 51px;
    color: #1d1929;
    background: #F0F0F4;
    border-radius: 14px 0 0 14px;
    width: 135px;
  }

  .city_search_input_box {
    background: #F0F0F4;
    border-radius: 14px;
    display: inline-flex;
    width: 100%;
  }

  .city_search_icon_input {
    padding: 21px 13px;
    margin-left: 7px;
    position: absolute;
  }

  .city_searchicon_box {
    width: 235px;
  }

  .consult_now_box {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_2 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_3 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .health_problem_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .sec_1_slider_text_box {
    padding: 30px 10px 25px 10px;
  }

  .healthproblem_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.3px;
    color: #424448;
    margin-top: 17px;
  }

  .city_search_input {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 54px;
    color: #1d1929;
    padding: 0px 14px 0px 40px;
    background: #F0F0F4;
    border-radius: 0 14px 14px 0;
  }

  .headerNav {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    font-family: "Inter", sans-serif;
    height: auto;
  }

  .login_signup_btn {
    display: block;
  }

  .nav_options_2 {
    margin-left: 90px;
  }

  .medicines_img {
    width: 64px;
    height: 86px;
    float: right;
  }

  .labtests_img {
    width: 61px;
    height: 83px;
    float: right;
  }

  .consult_now_img {
    width: 119px;
    height: 96px;
    float: right;
  }

  .consult_now_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #424448;
  }

  .consult_text_box {
    padding: 29px 10px 29px 19px;
  }

  .consult_info_box {
    margin-top: 0px;
    padding-left: 15px;
  }

  /*-----------------------Dashboard Home End-------------------------*/
  /*-------------------------------------Profile Dashboard Screen Start-------------------*/
  .addnewmember {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 35px;
    cursor: pointer;
  }

  /*-------------------------------------Profile Dashboard Screen End-------------------*/
  .subscription_plan_box {
    width: 230px;
  }

  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-------------------------------start consultant Screen Start-------------------------------*/
  .wating_box {
    margin-top: 374px;
    margin-bottom: 374px;
  }

  /*-------------------------------start consultant Screen End-------------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 0px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 0px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 0px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 0px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 12px 6px;
    border-radius: 5px;
    background: #fff;
    width: 100%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 12px 14px;
    width: 100%;
  }

  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  .completed_chat_message_list_inner {
    height: 210.6vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  .completed_appointment_detail_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 2px;
  }

  /*------------------------Completed Consult Screen End------------------------*/
  /*-----------------------Canceled Consult Screen Start-----------------------*/
  .canceled_consultation_right_pading {
    padding-right: 0;
  }

  .canceled_consultation_left_pading {
    padding-left: 0;
  }

  .canceled_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .canceled_consultation_chat_card_body {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .canceled_consultation_chat_card_body_2 {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    border-right: 1px solid #ECEFF4;
    padding: 24px 24px;
  }

  .canceled_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .canceled_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #BE2121;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .canceled_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .canceled_chat_message_list_inner {
    height: 94.9vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  .canceled_chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  .canceled_appointment_detail_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 2px;
  }

  /*------------------------Canceled Consult Screen End------------------------*/
  /*-----------------------Health Package Screen Start-----------------------*/
  .health_package_Recommended {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #15181E;
  }

  .popular_package_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    margin-bottom: 20px;
    height: 280px;
  }

  .health_package_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: auto;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .health_package_right_owl_slider_img {
    Width: 100%;
    Height: auto;
    border-radius: 14px;
  }

  .popular_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #15181E;
  }

  .health_package_container {
    background: #F5F5F5;
    padding: 30px 0px 50px 0;
  }

  .health_package_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .book_test_btn {
    background: #199A8E;
    border: 1px solid rgba(137, 152, 189, 0.2);
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 8px 10px;
    margin-top: 28px;
  }

  .lab_text_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 8px;
  }

  .lab_test_subtext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #424448;
    margin-bottom: 0;
  }

  /*------------------------Health Package Screen End------------------------*/
  .payment_body_box {
    height: 400px;
    width: 100%;
    padding: 280px 190px;
  }

  /*--------------------------My Subscription Screen Start--------------------------*/
  .my_subscription_body_box_card_head {
    background: #ffffff;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 12px 12px 0 0;
    padding: 12px 21px;
    border-bottom: 1px solid #eceff4;
  }

  .my_subscription_body_card_head_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    color: #424448;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .address_body_card_head_add_icon {
    margin-right: 10px;
    margin-top: -3px;
  }

  .address_body_card_head_new_add {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #DF6129;
    margin-bottom: 0;
    margin-top: 15px;
    float: right;
  }

  .my_subscription_card_inner {
    display: flex;
    justify-content: space-between;
    row-gap: 25px;
  }

  /*---------------------------My Subscription Screen End---------------------------*/
}

@media only screen and (min-width: 1360px) and (max-width: 1440px) {
  .sub_section_1 {
    position: relative;
    width: 50%;
    float: left;
  }

  .sub_section_2 {
    width: 50%;
    float: right;
  }

  .logo_box {
    padding: 50px 15px;
    /* margin-left: 850px;  */
  }

  .slider_1 {
    padding: 10px 30px 50px 30px;
  }

  .owl_banner_img_box {
    padding: 20px 0px;
    width: 340px !important;
    height: 380px;
  }

  .signup_box {
    padding: 350px 100px;
  }

  .signup_card {
    padding: 25px 30px;
    background: #fff;
    border-radius: 14px;
  }

  .signup_in_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    color: #15181E;
    margin-bottom: 15px;
  }

  .signup_in_subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #67696C;
    margin-top: 0;
  }

  .i_agree_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4D5764;
    position: absolute;
    margin-top: 1px;
    margin-left: 10px;
  }

  .term_condition_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #199A8E;
    text-decoration: none;
  }

  .request_otp {
    background: #199A8E;
    border-radius: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: 0;
    padding: 13px 15px;
    width: -webkit-fill-available;
  }

  .slider_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #fff;
  }

  .signup_details_form_box {
    padding: 80px 50px;
  }

  /*-----------------------Dashboard Home Start-------------------------*/
  .three_section_box {
    display: inline-flex;
    column-gap: 13px;
    row-gap: 20px;
    width: 100%;
  }

  .heath_problem_list_box {
    margin-top: 45px;
    display: grid;
    column-gap: 2%;
    grid-template-columns: 11% 11% 11% 11% 11% 11% 11% 11%;
    row-gap: 25px;
  }

  .healthproblem_icon_bg {
    background: rgba(25, 154, 142, 0.07);
    /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
    border-radius: 16px;
    padding: 30px 25px;
  }

  .healthproblem_icon {
    width: 50px;
    Height: 50px;
  }

  .home_bannerslide_img {
    width: -webkit-fill-available;
  }

  .btn-outline-secondary {
    border-right: 1px solid #DFDFDF;
    border-left: none;
    border-bottom: none;
    border-top: none;
    padding: 16px 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #424448;
    --bs-btn-focus-shadow-rgb: transparent;
    --bs-btn-hover-bg: #F0F0F4;
    --bs-btn-active-color: #424448;
    --bs-btn-hover-color: #424448;
  }

  .city_search_dropdown {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 51px;
    color: #1d1929;
    background: #F0F0F4;
    border-radius: 14px 0 0 14px;
    width: 135px;
  }

  .city_search_input_box {
    background: #F0F0F4;
    border-radius: 14px;
    display: inline-flex;
    width: 100%;
  }

  .city_search_icon_input {
    padding: 21px 13px;
    margin-left: 7px;
    position: absolute;
  }

  .city_searchicon_box {
    width: 235px;
  }

  .consult_now_box {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_2 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .consult_now_box_3 {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 5px 19px rgb(18 18 18 / 7%);
    border-radius: 16px;
    width: -webkit-fill-available;
  }

  .health_problem_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 135%;
    letter-spacing: 0.3px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 5px;
  }

  .sec_1_slider_text_box {
    padding: 30px 10px 25px 10px;
  }

  .healthproblem_text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.3px;
    color: #424448;
    margin-top: 17px;
  }

  .city_search_input {
    border: none;
    border-color: transparent;
    line-height: 1.9;
    width: -webkit-fill-available;
    transition: all 0.28s ease;
    box-shadow: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    height: 54px;
    color: #1d1929;
    padding: 0px 14px 0px 40px;
    background: #F0F0F4;
    border-radius: 0 14px 14px 0;
  }

  .headerNav {
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
    font-family: "Inter", sans-serif;
    height: auto;
  }

  .login_signup_btn {
    display: block;
  }

  .nav_options_2 {
    margin-left: 90px;
  }

  .medicines_img {
    width: 64px;
    height: 86px;
    float: right;
  }

  .labtests_img {
    width: 61px;
    height: 83px;
    float: right;
  }

  .consult_now_img {
    width: 119px;
    height: 96px;
    float: right;
  }

  .consult_now_head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #424448;
  }

  .consult_text_box {
    padding: 29px 10px 29px 19px;
  }

  /*-----------------------Dashboard Home End-------------------------*/
  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-------------------------------------Profile Dashboard Screen Start-------------------*/
  .addnewmember {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 35px;
    cursor: pointer;
  }

  /*-------------------------------------Profile Dashboard Screen End-------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 0px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 0px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 0px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 0px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 23px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 12px 6px;
    border-radius: 5px;
    background: #fff;
    width: 90%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 12px 14px;
    width: 90%;
  }

  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  .completed_chat_message_list_inner {
    height: 210.8vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  .completed_appointment_detail_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 2px;
  }

  /*------------------------Completed Consult Screen End------------------------*/
  /*-----------------------Canceled Consult Screen Start-----------------------*/
  .canceled_consultation_right_pading {
    padding-right: 0;
  }

  .canceled_consultation_left_pading {
    padding-left: 0;
  }

  .canceled_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .canceled_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .canceled_consultation_chat_card_body {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    padding: 24px 24px;
  }

  .canceled_consultation_chat_card_body_2 {
    background: #ffffff;
    border-radius: 0 0 12px 12px;
    border-right: 1px solid #ECEFF4;
    padding: 24px 24px;
  }

  .canceled_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .canceled_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 21px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #BE2121;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .canceled_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .canceled_chat_message_list_inner {
    height: 88.9vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  .canceled_chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  .canceled_appointment_detail_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 2px;
  }

  /*------------------------Canceled Consult Screen End------------------------*/
  /*-----------------------Health Package Screen Start-----------------------*/
  .health_package_Recommended {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #15181E;
  }

  .popular_package_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    margin-bottom: 20px;
    height: 300px;
  }

  .health_package_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: 403px;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .health_package_right_owl_slider_img {
    Width: 100%;
    Height: auto;
    border-radius: 14px;
  }

  .popular_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #15181E;
  }

  .health_package_container {
    background: #F5F5F5;
    padding: 30px 0px 50px 0;
  }

  .health_package_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .book_test_btn {
    background: #199A8E;
    border: 1px solid rgba(137, 152, 189, 0.2);
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    color: #FFFFFF;
    padding: 8px 10px;
    margin-top: 28px;
  }

  .lab_text_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 8px;
  }

  .lab_test_subtext {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #424448;
    margin-bottom: 0;
  }

  /*------------------------Health Package Screen End------------------------*/
  .payment_body_box {
    padding: 280px 280px;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 1600px) {
  .signup_box {
    padding: 350px 100px;
  }

  .signup_details_form_box {
    padding: 80px 50px;
  }

  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 0px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 0px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 0px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 0px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 23px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 12px 6px;
    border-radius: 5px;
    background: #fff;
    width: 90%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 12px 14px;
    width: 90%;
  }

  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #67696C;
  }

  .completed_chat_message_list_inner {
    height: 205.9vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  .completed_appointment_detail_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 2px;
  }

  /*------------------------Completed Consult Screen End------------------------*/
  /*-----------------------Health Package Screen Start-----------------------*/
  .health_package_Recommended {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #15181E;
  }

  .popular_package_card {
    background: #FFFFFF;
    box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
    border-radius: 14px;
    margin-bottom: 20px;
    height: 300px;
  }

  .health_package_input_search {
    background: #F0F0F4;
    border-radius: 14px;
    width: 403px;
    height: 53px;
    border: none;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
    padding: 0px 14px 0px 50px;
  }

  .health_package_right_owl_slider_img {
    Width: 100%;
    Height: auto;
    border-radius: 14px;
  }

  .popular_package_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #15181E;
  }

  .health_package_container {
    background: #F5F5F5;
    padding: 30px 0px 50px 0;
  }

  .health_package_radiological_test_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 5px;
    margin-top: 15px;
  }

  /*------------------------Health Package Screen End------------------------*/
}

@media only screen and (min-width: 1601px) and (max-width: 1919px) {
  .signup_box {
    padding: 350px 100px;
  }

  .signup_details_form_box {
    padding: 80px 80px;
  }

  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 0px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 0px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 0px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 0px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 23px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 12px 6px;
    border-radius: 5px;
    background: #fff;
    width: 90%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 12px 14px;
    width: 90%;
  }

  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  .completed_chat_message_list_inner {
    height: 205.9vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  .completed_appointment_detail_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 2px;
  }

  /*------------------------Completed Consult Screen End------------------------*/
}

@media only screen and (min-width: 1920px) and (max-width: 2020px) {
  .signup_box {
    padding: 370px 180px;
  }

  .slider_1 {
    padding: 50px 80px 88px 80px;
  }

  .signup_details_form_box {
    padding: 100px 150px;
  }

  .logo_box {
    padding: 50px 20px;
    /* margin-left: 1250px; */
    width: 350px;
  }

  /*-------------------------------Own Profile Screen Start-------------------------------*/
  .ownprofile_container {
    background: #F5F5F5;
    padding: 30px 0;
    height: 100%;
  }

  .addnewmember {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 24px;
    cursor: pointer;
  }

  /*-------------------------------Own Profile Screen End-------------------------------*/
  /*-----------------------Completed Consult Screen Start-----------------------*/
  .completed_appointment_detail_inner_box_1 {
    display: inline-flex;
    float: right;
    column-gap: 20px;
    margin-top: 7px;
    margin-right: 5px;
  }

  .completed_consultation_chat_card_head {
    background: #ffffff;
    border-radius: 12px 0px 0 0;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body {
    background: #ffffff;
    /*box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 0px 12px;
    padding: 24px 24px;
    border-right: 1px solid #ECEFF4;
  }

  .completed_consultation_chat_card_body_2 {
    background: #ffffff;
    /*box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);*/
    border-radius: 0 0 12px 0px;
    padding: 24px 24px;
  }

  .completed_consultation_chat_card_head_2 {
    background: #ffffff;
    border-radius: 0px 12px 0px 0px;
    padding: 24px 24px;
    border-bottom: 1px solid #eceff4;
  }

  .completed_appointment_detail_inner_box {
    display: inline-flex;
    float: right;
    column-gap: 130px;
    margin-top: 7px;
  }

  .completed_scheduled_tag {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 23px 14px 21px 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #199A8E;
    text-align: center;
    border: 1px solid #E2E2E6;
  }

  .completed_chat_appoint_card_consult_name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #000000;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .view_details {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #15181E;
    border: 1px solid #E2E2E6;
    padding: 12px 6px;
    border-radius: 5px;
    background: #fff;
    width: 90%;
  }

  .lab_book_now {
    background: #199A8E;
    border-radius: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 12px 14px;
    width: 90%;
  }

  .consult_msg_box {
    display: grid;
    width: 100%;
    justify-content: inherit;
  }

  .chat_appoint_details_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #67696C;
  }

  .completed_chat_message_list_inner {
    height: 186.9vh;
    overflow-y: scroll;
    padding-right: 20px;
  }

  .completed_appointment_detail_title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #424448;
    margin-bottom: 0;
    margin-top: 2px;
  }

  /*------------------------Completed Consult Screen End------------------------*/

}

@media only screen and (min-width: 2021px) and (max-width: 2100px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 1300px; */
  }

  .signup_box {
    padding: 350px 78px;
  }
}

@media only screen and (min-width: 2101px) and (max-width: 2199px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 1350px; */
    width: 450px;
  }

  .signup_box {
    padding: 450px 78px;
  }
}

@media only screen and (min-width: 2200px) and (max-width: 2300px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 1400px; */
    width: 260px;
  }

  .signup_box {
    padding: 400px 78px;
  }
}

@media only screen and (min-width: 2301px) and (max-width: 2400px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 1550px; */
    width: 260px;
  }

  .signup_box {
    padding: 400px 78px;
  }
}

@media only screen and (min-width: 2401px) and (max-width: 2560px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 1300px; */
    width: 260px;
  }

  .signup_box {
    padding: 400px 78px;
  }
}

@media only screen and (min-width: 2561px) and (max-width: 2660px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 1650px; */
    /* width:550px; */
  }

  .signup_box {
    padding: 450px 78px;
  }
}

@media only screen and (min-width: 2661px) and (max-width: 2800px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 1800px; */
    /* width:550px; */
  }

  .signup_box {
    padding: 450px 78px;
  }
}

/*--------------------------------------------------------------------------------------------------*/
@media only screen and (min-width: 2801px) and (max-width: 3000px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 1800px; */
    /* width:550px; */
  }

  .signup_box {
    padding: 450px 78px;
  }
}

@media only screen and (min-width: 3001px) and (max-width: 3300px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left:2100px; */
    /* width:550px; */
  }

  .signup_box {
    padding: 450px 78px;
  }
}

@media only screen and (min-width: 3301px) and (max-width: 3500px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 2200px; */
    /* width:650px; */
  }

  .signup_box {
    padding: 550px 78px;
  }
}

@media only screen and (min-width: 3501px) and (max-width: 3700px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 2200px; */
    /* width:650px; */
  }

  .signup_box {
    padding: 550px 78px;
  }
}

@media only screen and (min-width: 3701px) and (max-width: 3900px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 2400px; */
    /* width:650px; */
  }

  .signup_box {
    padding: 550px 78px;
  }
}

@media only screen and (min-width: 3901px) and (max-width: 4100px) {
  .logo_box {
    padding: 50px 20px;
    /* margin-left: 2500px; */
    /* width:650px; */
  }

  .signup_box {
    padding: 550px 78px;
  }
}

@media only screen and (min-width: 4101px) and (max-width: 4200px) {}

@media only screen and (min-width: 4201px) and (max-width: 4300px) {}

@media only screen and (min-width: 4301px) and (max-width: 4400px) {}

/*--------------------------------------------------------------------------------------------------*/
@media only screen and (min-width: 4401px) and (max-width: 4500px) {}

@media only screen and (min-width: 4501px) and (max-width: 4600px) {}

@media only screen and (min-width: 4601px) and (max-width: 4700px) {}

@media only screen and (min-width: 4701px) and (max-width: 4800px) {}

@media only screen and (min-width: 4801px) and (max-width: 4900px) {}

@media only screen and (min-width: 4901px) and (max-width: 5000px) {}

@media only screen and (min-width: 5001px) and (max-width: 5400px) {}

@media only screen and (min-width: 5401px) and (max-width: 5600px) {}

@media only screen and (min-width: 5601px) and (max-width: 5800px) {}

@media only screen and (min-width: 5801px) and (max-width: 6000px) {}

@media only screen and (min-width: 6001px) and (max-width: 6200px) {}

@media only screen and (min-width: 6201px) and (max-width: 6400px) {}

@media only screen and (min-width: 6401px) and (max-width: 6600px) {}

@media only screen and (min-width: 6601px) and (max-width: 6800px) {}

@media only screen and (min-width: 6801px) and (max-width: 7000px) {}

@media only screen and (min-width: 7001px) and (max-width: 7200px) {}

@media only screen and (min-width: 7201px) and (max-width: 7400px) {}

@media only screen and (min-width: 7401px) and (max-width: 7600px) {}

@media only screen and (min-width: 7601px) and (max-width: 7800px) {}

/*@media only screen and (min-width: 7801px)  and (max-width:8000px)
{
}
@media only screen and (min-width: 8001px)  and (max-width:8200px)
{
}
@media only screen and (min-width: 8201px)  and (max-width:8400px)
{
}
@media only screen and (min-width: 8401px)  and (max-width:8600px)
{
}
@media only screen and (min-width: 8601px)  and (max-width:8800px)
{
}
@media only screen and (min-width: 8801px)  and (max-width:9000px)
{
}*/
/*------------------------------------------------Desktop and Laptop Media Query End-------------------------------------------------------------*/