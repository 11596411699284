/*------------------------------------------Margin Top Start------------------------------------------------*/
.mt_5 {
  margin-top: 5px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_25 {
  margin-top: 25px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_35 {
  margin-top: 35px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_45 {
  margin-top: 45px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_55 {
  margin-top: 55px;
}

.mt_60 {
  margin-top: 60px;
}

.mt_65 {
  margin-top: 65px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_75 {
  margin-top: 75px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_85 {
  margin-top: 85px;
}

.mt_90 {
  margin-top: 90px;
}

.mt_95 {
  margin-top: 95px;
}

.mt_100 {
  margin-top: 100px;
}

.mt_105 {
  margin-top: 105px;
}

.mt_110 {
  margin-top: 110px;
}

.mt_115 {
  margin-top: 115px;
}

.mt_120 {
  margin-top: 120px;
}

.mt_125 {
  margin-top: 125px;
}

.mt_130 {
  margin-top: 130px;
}

.mt_135 {
  margin-top: 135px;
}

.mt_140 {
  margin-top: 140px;
}

.mt_145 {
  margin-top: 145px;
}

.mt_150 {
  margin-top: 150px;
}

.mt_155 {
  margin-top: 155px;
}

.mt_160 {
  margin-top: 160px;
}

.mt_165 {
  margin-top: 165px;
}

.mt_170 {
  margin-top: 170px;
}

.mt_175 {
  margin-top: 175px;
}

.mt_180 {
  margin-top: 180px;
}

.mt_185 {
  margin-top: 185px;
}

.mt_190 {
  margin-top: 190px;
}

.mt_195 {
  margin-top: 195px;
}

.mt_200 {
  margin-top: 200px;
}

.mt_205 {
  margin-top: 205px;
}

.mt_210 {
  margin-top: 210px;
}

.mt_215 {
  margin-top: 215px;
}

.mt_220 {
  margin-top: 220px;
}

.mt_225 {
  margin-top: 225px;
}

.mt_230 {
  margin-top: 230px;
}

.mt_235 {
  margin-top: 235px;
}

.mt_240 {
  margin-top: 240px;
}

.mt_245 {
  margin-top: 245px;
}

.mt_250 {
  margin-top: 250px;
}

.mt_255 {
  margin-top: 255px;
}

.mt_260 {
  margin-top: 260px;
}

.mt_265 {
  margin-top: 265px;
}

.mt_270 {
  margin-top: 270px;
}

.mt_275 {
  margin-top: 275px;
}

.mt_280 {
  margin-top: 280px;
}

.mt_285 {
  margin-top: 285px;
}

.mt_290 {
  margin-top: 290px;
}

.mt_295 {
  margin-top: 295px;
}

.mt_300 {
  margin-top: 300px;
}

.mt_350 {
  margin-top: 350px;
}

.mt_400 {
  margin-top: 400px;
}

.mt_5 {
  margin-top: 5px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_15 {
  margin-top: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_25 {
  margin-top: 25px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_35 {
  margin-top: 35px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_45 {
  margin-top: 45px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_55 {
  margin-top: 55px;
}

.mt_60 {
  margin-top: 60px;
}

.mt_65 {
  margin-top: 65px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_75 {
  margin-top: 75px;
}

.mt_80 {
  margin-top: 80px;
}

.mt_85 {
  margin-top: 85px;
}

.mt_90 {
  margin-top: 90px;
}

.mt_95 {
  margin-top: 95px;
}

.mt_100 {
  margin-top: 100px;
}

.mt_105 {
  margin-top: 105px;
}

.mt_110 {
  margin-top: 110px;
}

.mt_115 {
  margin-top: 115px;
}

.mt_120 {
  margin-top: 120px;
}

.mt_125 {
  margin-top: 125px;
}

.mt_130 {
  margin-top: 130px;
}

.mt_135 {
  margin-top: 135px;
}

.mt_140 {
  margin-top: 140px;
}

.mt_145 {
  margin-top: 145px;
}

.mt_150 {
  margin-top: 150px;
}

.mt_155 {
  margin-top: 155px;
}

.mt_160 {
  margin-top: 160px;
}

.mt_165 {
  margin-top: 165px;
}

.mt_170 {
  margin-top: 170px;
}

.mt_175 {
  margin-top: 175px;
}

.mt_180 {
  margin-top: 180px;
}

.mt_185 {
  margin-top: 185px;
}

.mt_190 {
  margin-top: 190px;
}

.mt_195 {
  margin-top: 195px;
}

.mt_200 {
  margin-top: 200px;
}

.mt_205 {
  margin-top: 205px;
}

.mt_210 {
  margin-top: 210px;
}

.mt_215 {
  margin-top: 215px;
}

.mt_220 {
  margin-top: 220px;
}

.mt_225 {
  margin-top: 225px;
}

.mt_230 {
  margin-top: 230px;
}

.mt_235 {
  margin-top: 235px;
}

.mt_240 {
  margin-top: 240px;
}

.mt_245 {
  margin-top: 245px;
}

.mt_250 {
  margin-top: 250px;
}

.mt_255 {
  margin-top: 255px;
}

.mt_260 {
  margin-top: 260px;
}

.mt_265 {
  margin-top: 265px;
}

.mt_270 {
  margin-top: 270px;
}

.mt_275 {
  margin-top: 275px;
}

.mt_280 {
  margin-top: 280px;
}

.mt_285 {
  margin-top: 285px;
}

.mt_290 {
  margin-top: 290px;
}

.mt_295 {
  margin-top: 295px;
}

.mt_300 {
  margin-top: 300px;
}

.mt_350 {
  margin-top: 350px;
}

.mt_400 {
  margin-top: 400px;
}

/*------------------------------------------Margin Top End--------------------------------------------------*/
/*------------------------------------------Margin Bottom Start------------------------------------------------*/
.mb_5 {
  margin-bottom: 5px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_25 {
  margin-bottom: 25px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_35 {
  margin-bottom: 35px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_45 {
  margin-bottom: 45px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb_55 {
  margin-bottom: 55px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_65 {
  margin-bottom: 65px;
}

.mb_70 {
  margin-bottom: 70px;
}

.mb_75 {
  margin-bottom: 75px;
}

.mb_80 {
  margin-bottom: 80px;
}

.mb_85 {
  margin-bottom: 85px;
}

.mb_90 {
  margin-bottom: 90px;
}

.mb_95 {
  margin-bottom: 95px;
}

.mb_100 {
  margin-bottom: 100px;
}

.mb_105 {
  margin-bottom: 105px;
}

.mb_110 {
  margin-bottom: 110px;
}

/*------------------------------------------Margin Bottom End--------------------------------------------------*/
/*----------------------------------------------Margin Left Start------------------------------------------------------*/
.ml_0 {
  margin-left: 0px;
}

.ml_5 {
  margin-left: 5px;
}

.ml_10 {
  margin-left: 10px;
}

.ml_15 {
  margin-left: 15px;
}

.ml_20 {
  margin-left: 20px;
}

.ml_25 {
  margin-left: 25px;
}

.ml_30 {
  margin-left: 30px;
}

.ml_35 {
  margin-left: 35px;
}

.ml_40 {
  margin-left: 40px;
}

.ml_45 {
  margin-left: 45px;
}

.ml_50 {
  margin-left: 50px;
}

.ml_55 {
  margin-left: 55px;
}

.ml_60 {
  margin-left: 60px;
}

.ml_65 {
  margin-left: 65px;
}

.ml_70 {
  margin-left: 70px;
}

.ml_75 {
  margin-left: 75px;
}

.ml_80 {
  margin-left: 80px;
}

.ml_85 {
  margin-left: 85px;
}

.ml_90 {
  margin-left: 90px;
}

.ml_95 {
  margin-left: 95px;
}

.ml_100 {
  margin-left: 100px;
}

/*----------------------------------------------Margin Left end--------------------------------------------------------*/
/*----------------------------------------------Margin right Start------------------------------------------------------*/
.mr_5 {
  margin-right: 5px;
}

.mr_10 {
  margin-right: 10px;
}

.mr_15 {
  margin-right: 15px;
}

.mr_20 {
  margin-right: 10px;
}

.mr_25 {
  margin-right: 25px;
}

.mr_30 {
  margin-right: 30px;
}

.mr_35 {
  margin-right: 35px;
}

.mr_40 {
  margin-right: 40px;
}

.mr_45 {
  margin-right: 45px;
}

.mr_50 {
  margin-right: 50px;
}

.mr_55 {
  margin-right: 55px;
}

.mr_60 {
  margin-right: 60px;
}

.mr_65 {
  margin-right: 65px;
}

.mr_70 {
  margin-right: 70px;
}

.mr_75 {
  margin-right: 75px;
}

.mr_80 {
  margin-right: 80px;
}

.mr_85 {
  margin-right: 85px;
}

.mr_90 {
  margin-right: 90px;
}

.mr_95 {
  margin-right: 95px;
}

.mr_100 {
  margin-right: 100px;
}

/*----------------------------------------------Margin right end--------------------------------------------------------*/
/*------------------------------------------Padding Top Start------------------------------------------------*/
.pt_5 {
  padding-top: 5px;
}

.pt_10 {
  padding-top: 10px;
}

.pt_15 {
  padding-top: 15px;
}

.pt_20 {
  padding-top: 20px;
}

.pt_25 {
  padding-top: 25px;
}

.pt_30 {
  padding-top: 30px;
}

.pt_35 {
  padding-top: 35px;
}

.pt_40 {
  padding-top: 40px;
}

.pt_45 {
  padding-top: 45px;
}

.pt_50 {
  padding-top: 50px;
}

.pt_55 {
  padding-top: 55px;
}

.pt_60 {
  padding-top: 60px;
}

.pt_65 {
  padding-top: 65px;
}

.pt_70 {
  padding-top: 70px;
}

.pt_75 {
  padding-top: 75px;
}

.pt_80 {
  padding-top: 80px;
}

.pt_85 {
  padding-top: 85px;
}

.pt_90 {
  padding-top: 90px;
}

.pt_95 {
  padding-top: 95px;
}

.pt_100 {
  padding-top: 100px;
}

.pt_105 {
  padding-top: 105px;
}

.pt_110 {
  padding-top: 110px;
}

.pt_115 {
  padding-top: 115px;
}

.pt_120 {
  padding-top: 120px;
}

.pt_125 {
  padding-top: 125px;
}

.pt_130 {
  padding-top: 130px;
}

.pt_135 {
  padding-top: 135px;
}

.pt_140 {
  padding-top: 140px;
}

.pt_145 {
  padding-top: 145px;
}

.pt_150 {
  padding-top: 150px;
}

.pt_155 {
  padding-top: 155px;
}

.pt_160 {
  padding-top: 160px;
}

.pt_165 {
  padding-top: 165px;
}

.pt_170 {
  padding-top: 170px;
}

.pt_175 {
  padding-top: 175px;
}

.pt_180 {
  padding-top: 180px;
}

.pt_185 {
  padding-top: 185px;
}

.pt_190 {
  padding-top: 190px;
}

.pt_195 {
  padding-top: 195px;
}

.pt_200 {
  padding-top: 200px;
}

/*------------------------------------------padding Top End--------------------------------------------------*/
.mb_5 {
  margin-bottom: 5px;
}

.mb_10 {
  margin-bottom: 10px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_25 {
  margin-bottom: 25px;
}

.mb_30 {
  margin-bottom: 30px;
}

.mb_35 {
  margin-bottom: 35px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_45 {
  margin-bottom: 45px;
}

.mb_50 {
  margin-bottom: 50px;
}

.mb_55 {
  margin-bottom: 55px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_65 {
  margin-bottom: 65px;
}

.mb_70 {
  margin-bottom: 70px;
}

.mb_75 {
  margin-bottom: 75px;
}

.mb_80 {
  margin-bottom: 80px;
}

.mb_85 {
  margin-bottom: 85px;
}

.mb_90 {
  margin-bottom: 90px;
}

.mb_95 {
  margin-bottom: 95px;
}

.mb_100 {
  margin-bottom: 100px;
}

/*------------------------------------------Margin Bottom End--------------------------------------------------*/
/*----------------------------------------------Margin Left Start------------------------------------------------------*/
.ml_0 {
  margin-left: 0px;
}

.ml_5 {
  margin-left: 5px;
}

.ml_10 {
  margin-left: 10px;
}

.ml_15 {
  margin-left: 15px;
}

.ml_20 {
  margin-left: 20px;
}

.ml_25 {
  margin-left: 25px;
}

.ml_30 {
  margin-left: 30px;
}

.ml_35 {
  margin-left: 35px;
}

.ml_40 {
  margin-left: 40px;
}

.ml_45 {
  margin-left: 45px;
}

.ml_50 {
  margin-left: 50px;
}

.ml_55 {
  margin-left: 55px;
}

.ml_60 {
  margin-left: 60px;
}

.ml_65 {
  margin-left: 65px;
}

.ml_70 {
  margin-left: 70px;
}

.ml_75 {
  margin-left: 75px;
}

.ml_80 {
  margin-left: 80px;
}

.ml_85 {
  margin-left: 85px;
}

.ml_90 {
  margin-left: 90px;
}

.ml_95 {
  margin-left: 95px;
}

.ml_100 {
  margin-left: 100px;
}

/*----------------------------------------------Margin Left end--------------------------------------------------------*/
/*----------------------------------------------Margin right Start------------------------------------------------------*/
.mr_5 {
  margin-right: 5px;
}

.mr_10 {
  margin-right: 10px;
}

.mr_15 {
  margin-right: 15px;
}

.mr_20 {
  margin-right: 10px;
}

.mr_25 {
  margin-right: 25px;
}

.mr_30 {
  margin-right: 30px;
}

.mr_35 {
  margin-right: 35px;
}

.mr_40 {
  margin-right: 40px;
}

.mr_45 {
  margin-right: 45px;
}

.mr_50 {
  margin-right: 50px;
}

.mr_55 {
  margin-right: 55px;
}

.mr_60 {
  margin-right: 60px;
}

.mr_65 {
  margin-right: 65px;
}

.mr_70 {
  margin-right: 70px;
}

.mr_75 {
  margin-right: 75px;
}

.mr_80 {
  margin-right: 80px;
}

.mr_85 {
  margin-right: 85px;
}

.mr_90 {
  margin-right: 90px;
}

.mr_95 {
  margin-right: 95px;
}

.mr_100 {
  margin-right: 100px;
}

/*----------------------------------------------Margin right end--------------------------------------------------------*/
/*------------------------------------------Padding Top Start------------------------------------------------*/
.pt_5 {
  padding-top: 5px;
}

.pt_10 {
  padding-top: 10px;
}

.pt_15 {
  padding-top: 15px;
}

.pt_20 {
  padding-top: 20px;
}

.pt_25 {
  padding-top: 25px;
}

.pt_30 {
  padding-top: 30px;
}

.pt_35 {
  padding-top: 35px;
}

.pt_40 {
  padding-top: 40px;
}

.pt_45 {
  padding-top: 45px;
}

.pt_50 {
  padding-top: 50px;
}

.pt_55 {
  padding-top: 55px;
}

.pt_60 {
  padding-top: 60px;
}

.pt_65 {
  padding-top: 65px;
}

.pt_70 {
  padding-top: 70px;
}

.pt_75 {
  padding-top: 75px;
}

.pt_80 {
  padding-top: 80px;
}

.pt_85 {
  padding-top: 85px;
}

.pt_90 {
  padding-top: 90px;
}

.pt_95 {
  padding-top: 95px;
}

.pt_100 {
  padding-top: 100px;
}

.pt_105 {
  padding-top: 105px;
}

.pt_110 {
  padding-top: 110px;
}

.pt_115 {
  padding-top: 115px;
}

.pt_120 {
  padding-top: 120px;
}

.pt_125 {
  padding-top: 125px;
}

.pt_130 {
  padding-top: 130px;
}

.pt_135 {
  padding-top: 135px;
}

.pt_140 {
  padding-top: 140px;
}

.pt_145 {
  padding-top: 145px;
}

.pt_150 {
  padding-top: 150px;
}

.pt_155 {
  padding-top: 155px;
}

.pt_160 {
  padding-top: 160px;
}

.pt_165 {
  padding-top: 165px;
}

.pt_170 {
  padding-top: 170px;
}

.pt_175 {
  padding-top: 175px;
}

.pt_180 {
  padding-top: 180px;
}

.pt_185 {
  padding-top: 185px;
}

.pt_190 {
  padding-top: 190px;
}

.pt_195 {
  padding-top: 195px;
}

.pt_200 {
  padding-top: 200px;
}

/*------------------------------------------padding Top End--------------------------------------------------*/
/*------------------------------------------Padding Top Start------------------------------------------------*/
.pl_5 {
  padding-left: 5px;
}

.pl_10 {
  padding-left: 10px;
}

.pl_15 {
  padding-left: 15px;
}

.pl_20 {
  padding-left: 20px;
}

.pl_25 {
  padding-left: 25px;
}

.pl_30 {
  padding-left: 30px;
}

.pl_35 {
  padding-left: 35px;
}

.pl_40 {
  padding-left: 40px;
}

.pl_45 {
  padding-left: 45px;
}

.pl_50 {
  padding-left: 50px;
}

.pl_55 {
  padding-left: 55px;
}

.pl_60 {
  padding-left: 60px;
}

.pl_65 {
  padding-left: 65px;
}

.pl_70 {
  padding-left: 70px;
}

.pl_75 {
  padding-left: 75px;
}

.pl_80 {
  padding-left: 80px;
}

.pl_85 {
  padding-left: 85px;
}

.pl_90 {
  padding-left: 90px;
}

.pl_95 {
  padding-left: 95px;
}

.pl_100 {
  padding-left: 100px;
}

.pl_105 {
  padding-left: 105px;
}

.pl_110 {
  padding-left: 110px;
}

.pl_115 {
  padding-left: 115px;
}

.pl_120 {
  padding-left: 120px;
}

.pl_125 {
  padding-left: 125px;
}

.pl_130 {
  padding-left: 130px;
}

.pl_135 {
  padding-left: 135px;
}

.pl_140 {
  padding-left: 140px;
}

.pl_145 {
  padding-left: 145px;
}

.pl_150 {
  padding-left: 150px;
}

.pl_155 {
  padding-left: 155px;
}

.pl_160 {
  padding-left: 160px;
}

.pl_165 {
  padding-left: 165px;
}

.pl_170 {
  padding-left: 170px;
}

.pt_175 {
  padding-top: 175px;
}

.pt_18 {
  padding-left: 180px;
}

.pl_185 {
  padding-left: 185px;
}

.pl_190 {
  padding-left: 190px;
}

.pl_195 {
  padding-left: 195px;
}

.pl_200 {
  padding-left: 200px;
}

/*------------------------------------------padding Top End--------------------------------------------------*/
/*------------------------------------------Common Section style Start------------------------------------------------*/
.section_1_bg {
  background-image: url("../img/png/page_bg.png");
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.section_1_container {
  width: 100%;
  height: 100vh;
  float: right;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  display: inline-block;
  display: -moz-box;
  display: -ms-flexbox;
}

.sub_section_1 {
  position: relative;
  width: 50%;
  float: left;
}

.section_2 {
  margin-top: -110px;
}

.sub_section_2 {
  width: 50%;
  float: right;
}

.display_t {
  display: block;
  min-height: 700px;
  margin: 0px 30px 0px 30px;
}

.display_inline {
  display: inline-flex;
}

.page_item_list {
  padding-right: 10px;
}

.pagination_result_text {
  font-size: 18px;
  margin-bottom: 14px;

}

.pagination_content {
  justify-content: center;
}

/*-------------------------------Radio Button Start--------------------------------*/
.yes_box input[type="radio"] {
  opacity: 1;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.yes_box [type="radio"]:checked,
.yes_box [type="radio"]:not(:checked) {
  /* position: absolute; */
  width: 26px;
  height: 26px;
  margin-left: -27px;
}

.yes_box [type="radio"]:checked+label,
.yes_box [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.yes_box [type="radio"]:checked+label:before,
.yes_box [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.yes_box [type="radio"]:checked+label:after,
.yes_box [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #199a8e;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.yes_box [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.yes_box [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*-----------------------------*/
.no_box input[type="radio"] {
  opacity: 1;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.no_box [type="radio"]:checked,
.no_box [type="radio"]:not(:checked) {
  position: absolute;
  width: 26px;
  height: 26px;
  margin-left: -27px;
}

.no_box [type="radio"]:checked+label,
.no_box [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.no_box [type="radio"]:checked+label:before,
.no_box [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.no_box [type="radio"]:checked+label:after,
.no_box [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #199a8e;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.no_box [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.no_box [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*--------------------------------Radio Button End---------------------------------*/
/*-------------------------------------------Common Section style End-------------------------------------------------*/
/*------------------------------------------Signup Form Start------------------------------------------------*/
.logo_box {
  padding: 50px 20px;
  /* margin-left: 850px; */
  width: 260px;
}

.signup_box {
  padding: 204px 78px;
}

.slider_1 {
  padding: 10px 80px 88px 80px;
}

.slider_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #fff;
}

.owl_banner_img_box {
  padding: 20px 0px;
  width: 364px !important;
  height: 404px;
}

.owl-theme .owl-dots .owl-dot span {
  width: 15px !important;
  height: 5px !important;
  margin: 5px 7px;
  background: rgba(255, 255, 255, 0.5);
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #fff !important;
}

.signup_card {
  padding: 50px;
  background: #fff;
  border-radius: 14px;
}

.signup_in_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #15181e;
  margin-bottom: 15px;
}

.signup_in_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #67696c;
  margin-top: 0;
}

.signup_form_box {
  padding: 30px 0;
}

label.field {
  border-radius: 2px;
  color: #666;
  display: block;
  margin: 0px 0 0 7px;
  padding: 8px;
  opacity: 0;
  position: relative;
  transition-property: opacity;
  z-index: 1;
}

label.field span {
  color: inherit;
  display: block;
  font-size: 14px;
  font-weight: 300;
  height: 20px;
  line-height: 20px;
  left: 9px;
  pointer-events: none;
  position: absolute;
  top: 23px;
  transform: scale(1) translateY(0);
  transition-property: color, font-size, top;
  z-index: 1;
}

label.field span.required::after {
  color: inherit;
  content: "*";
  display: block;
  height: 20px;
  left: -20px;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 20px;
}

.error label.field span {
  color: #f02318;
}

label.field .psuedo_select {
  background: #ffffff00;
  position: relative;
  color: #666;
  cursor: pointer;
  font-size: 20px;
  height: 24px;
  line-height: 24px;
  margin: 24px 32px 0 0;
  min-width: 100%;
  /*min-width: 325px;*/
  padding-top: 24px;
  outline: 0;
  z-index: 1;
}

label.field .psuedo_select::after {
  background: url("data:image/svg+xml;utf8,<svg fill='#666' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'> <path d='M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z'/> <path d='M0-.75h24v24H0z' fill='none'/> </svg>"),
    no-repeat;
  content: "";
  height: 24px;
  width: 24px;
  position: absolute;
  top: 0;
  right: 0;
  transition-property: background;
}

label.field .psuedo_select .selected {
  font-family: "Inter";
  color: #000;
  font-weight: 600;
  height: 24px;
  left: 1px;
  line-height: 24px;
  opacity: 0;
  position: absolute;
  top: -2px;
  transform: translateY(24px);
  transition-property: opacity, transform;
  will-change: transform;
}

label.field .psuedo_select ul {
  background: #fbf3f3;
  /* box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%); */
  display: block;
  margin-left: -10px;
  height: 100px !important;
  width: 100%;
  list-style: none;
  margin-top: 2px;
  opacity: 0;
  overflow: hidden;
  padding: 5px 5px;
  pointer-events: none;
  transition-property: height, opacity;
  z-index: 2;
}

label.field .psuedo_select ul li {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
  height: 32px;
  padding: 8px 4px;
  text-align: left;
}

label.field .deselect {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -1;
}

label.field.focused {
  color: #007bed;
}

label.field.focused .psuedo_select {
  border-color: #007bed;
  color: #007bed;
}

label.field.focused .psuedo_select {
  border-color: #007bed;
}

label.field.focused .psuedo_select ul {
  opacity: 1;
  pointer-events: all;
}

.arrow_icon {
  position: absolute;
  top: 32px;
  right: 25px;
  color: #908e8e;
}

.dropdown-toggle::after {
  /*display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;*/
  /*content: "";*/
  /*border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;*/
  display: none;
}

input:valid~label,
textarea:valid~label,
input:focus~label,
textarea:focus~label {
  font-size: 0.75em;
  top: -1rem;
}

.styled-input {
  background-color: #ffffffcc;
  width: 100%;
  border-radius: 8px;
  position: relative;
  margin-bottom: 20px;
  position: relative;
  font-weight: 300;
  border: solid 1px #ede8e9;
  height: 66px;
}

.styled-input label {
  color: #999;
  padding: 23px 1px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.25s cubic-bezier(0.2, 0, 0.03, 1);
  pointer-events: none;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 300;
  left: 15px;
}

input.input_field,
textarea.input_textarea {
  padding: 2.3rem 1rem 1rem 2rem;
  border: none;
  /* border-radius: 10px; */
  width: 100%;
  font-size: 14px;
  height: 61px;
  font-family: "Inter";
  font-weight: 600;
  /* position: relative; */
  z-index: 2;
  font-size: 15px;
  color: #333;
}

input[type="text"]:focus {
  border-color: #66afe9 !important;
}

input[type="email"]:focus {
  border-color: #66afe9 !important;
}

input[type="email"]:focus {
  border-color: #66afe9 !important;
}

input.input_field:pattern {
  padding: 2.3rem 1rem 1rem 1.7rem;
  border: none;
  /* border-radius: 10px; */
  width: 100%;
  font-size: 14px;
  height: 64px;
  font-family: "Inter";
  font-weight: 600;
  /* position: relative; */
  z-index: 2;
  font-size: 15px;
  color: #333;
}

.form-radio,
.form_group {
  position: relative;
  margin-bottom: 10px;
}

.form-inline>.form_group,
.form-inline>.btn {
  display: inline-block;
  margin-bottom: 0;
}

.form_group input {
  height: 6.2rem;
}

.form_group textarea {
  resize: none;
}

.form_group select {
  width: 100%;
  font-size: 1rem;
  height: 1.6rem;
  padding: 0.125rem 0.125rem 0.0625rem;
  background: none;
  border: none;
  line-height: 1.6;
  box-shadow: none;
}

.form_group .control-label {
  position: absolute;
  top: 2.1rem;
  pointer-events: none;
  padding-left: 2rem !important;
  z-index: 1;
  color: #999;
  transition: all 0.28s ease;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
}

.form_group input,
.form_group textarea,
.form_group select {
  display: block;
  padding: 0.5rem 1.5rem 0.5rem 20px;
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: -webkit-fill-available;
  color: transparent;
  -webkit-transition: all 0.28s ease;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  height: 50px !important;
  color: #1d1929;
  /* border-radius: 10px; */
  border-bottom: 1px solid #e2e2e6;


}

.form_group select:focus,
.form_group input:focus,
.form_group textarea:focus {
  outline: none;
  /*border-radius: 10px;*/
}

.form_group select:focus~.control-label,
.form_group input:focus~.control-label,
.form_group textarea:focus~.control-label {
  color: #337ab7;
}

.form_group select,
.form_group input:focus,
.form_group input[data-value]:not([data-value=""]),
/*.form_group input:valid,*/
.form_group input.form-file,
.form_group input.has-value,
.form_group textarea:focus,
.form_group textarea:valid,
.form_group textarea.form-file,
.form_group textarea.has-value {
  color: #333;
  height: 50px;
  padding-top: 16px;
}

.form_group select~.control-label,
.form_group input:focus~.control-label,
.form_group input[data-value]:not([data-value=""])~.control-label,
/*.form_group input:valid ~ .control-label,*/
.form_group input.form-file~.control-label,
.form_group input.has-value~.control-label,
.form_group textarea:focus~.control-label,
.form_group textarea.form-file~.control-label,
.form_group textarea.has-value~.control-label {
  font-size: 1.2rem !important;
  color: gray;
  top: 1.2rem !important;
  left: 0;
}

input:valid~label,
textarea:valid~label,
input:focus~label,
textarea:focus~label {
  font-size: 1.2rem;
  color: gray;
  top: 1.2rem;
  left: 0;
}

input:valid~label,
textarea:valid~label,
input:focus~label,
textarea:focus~label {
  font-size: 0.75em;
  top: -0.4rem;
}

.signup_form_input {
  padding: 8px 20px 8px 50px !important;
}

.signup_call_icon {
  left: 17px;
  position: absolute;
  margin-top: -33px;
}

.request_otp {
  background: #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #ffffff;
  border: 0;
  padding: 13px 15px;
  width: -webkit-fill-available;
}

.checkbox_icon {
  width: 18px;
  height: 18px;
}

.i_agree_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 16px;
  color: #4d5764 !important;
  margin-top: 3px;
  margin-left: 1px;
  cursor: pointer !important;
  position: absolute;
  width: max-content;
}

.term_condition_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #199a8e;
  text-decoration: none;
}

.i_agree_text_box {
  float: left;
}

.i_agree_box {
  margin-bottom: 30px;
  margin-top: 5px;
}

.i_agree_box input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.i_agree_box label {
  position: relative;
  cursor: pointer;
}

.i_agree_box label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #c4c4c4;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
}

.i_agree_box input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: 0.4px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.i_agree_box input[type="checkbox"]:checked~label:before {
  background: #199a8e no-repeat center;
  background-size: 12px 12px;
  border: 1px solid #199a8e;
  border-radius: 5px;
  width: 20px;
  height: 20px;
}

.i_agree_box input[type="checkbox"]:focus~label {
  color: #20bf6b;
}




/*-------------------------------------------Signup Form End-------------------------------------------------*/
/*-------------------------------------------Signup OTP Form Start-------------------------------------------------*/
.verification_code_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
  text-align: center;
  color: #15181e;
  margin-bottom: 0;
}

.verification_code_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #67696c;
  margin-bottom: 0;
  margin-top: 10px;
}

.verification_number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #199a8e;
  margin-bottom: 0;
  margin-top: 0;
}

.otpContainer {
  display: inline-flex;
  margin-bottom: 31px;
}

.otpInput {
  width: 37px;
  height: 37px;
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  padding: 10px;
  text-align: center;
  font-family: "Inter";
  font-weight: 700;
  background: #f9fafb;
}

.receive_the_code {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  align-items: center;
  color: #67696c;
  text-align: center;
}

.resend {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  align-items: center;
  color: #199a8e;
  text-decoration: none;
}

.verify {
  background: #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #ffffff;
  border: 0;
  padding: 13px 15px;
  width: -webkit-fill-available;
}

.otp_box {
  padding: 27px 0;
}

/*--------------------------------------------Signup OTP Form End-------------------------------------------------*/
/*--------------------------------------------Signup Details Form Start-------------------------------------------------*/
.signup_details_form_box {
  padding: 80px 78px;
}

.signup_details_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
  text-align: center;
  color: #15181e;
}

.signup_details_head_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #67696c;
}

.signup_details_box {
  padding: 20px 0px;
}

.next-city-btn {
  width: 100%;
}

.signup_details_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  color: #3e4046;
  margin-top: 0;
  margin-bottom: 0;
}

.personal_box {
  background: #fff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  margin-top: 15px;
  border: 0;
}

.personal_input {
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: -webkit-fill-available;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  height: 51px;
  color: #1d1929;
  border-radius: 14px 14px 0px 0px;
  border: none;
  padding: 0px 14px;
}

.personal_input_1 {
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: -webkit-fill-available;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  height: 51px;
  color: #1d1929;
  border-top: 0.8px dashed #e2e2e6;
  border-bottom: 0.8px dashed #e2e2e6;
  padding: 0px 14px;
}

.personal_input_2 {
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: -webkit-fill-available;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  height: 51px;
  color: #1d1929;
  /* border-radius: 0px 0px 14px 14px; */
  border: none;
  padding: 0px 14px;
}

textarea:focus,
input:focus {
  outline: none;
}

*:focus {
  outline: none;
}

.dob_input {
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: -webkit-fill-available;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  height: 51px;
  color: #1d1929;
  border-radius: 14px 14px 0px 0px;
  border: none;
  padding: 0px 14px;
  border-bottom: 0.8px dashed #e2e2e6;
}

.dob_input_2 {
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: -webkit-fill-available;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  height: 51px;
  color: #1d1929;
  /* border-radius: 0px 0px 14px 14px; */
  border: none;
  padding: 0px 14px;
}

.signup_details_verify {
  background: #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #ffffff;
  border: 0;
  padding: 13px 15px;
  width: -webkit-fill-available;
}

.radio_box {
  padding: 10px 0;
  display: inline-flex;
  float: left;
  column-gap: 10px;
}

.gender_option {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 14px;
  padding: 14px 23px;
  justify-content: left;
  width: 90px;
  height: 90px;
}

.gender_option label {
  /* display: flex; */
  /* width: 90px;
  height: 90px; */
}

.gender_active {
  background: #199a8e;
  border-radius: 14px;
  width: 90px;
  position: absolute;
  margin-top: -32px;
  margin-left: -29px;
  height: 90px;
}

.gender_active_2 {
  background: #199a8e;
  border-radius: 14px;
  width: 90px;
  position: absolute;
  margin-top: -32px;
  margin-left: -45px;
}

.gender_active_3 {
  background: #199a8e;
  border-radius: 14px;
  width: 90px;
  position: absolute;
  margin-top: -32px;
  margin-left: -26px;
}

/*.gender_icon{
  width: 30px;
  height: 30px;
}*/
.gender_icon {
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-bottom: 3px;
}

.gender_option_active_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.gender_option_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  color: #909196;
}

/*.gender_option_box{
  display: grid;
  row-gap: 10px;
}*/
.gender_option_box {
  display: grid;
  row-gap: 10px;
  position: absolute;
  margin-left: -23px;
  margin-top: -14px;
  padding: 13px 30px;
}

input[type="radio"] {
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.medical input[type="radio"] {
  top: 0;
  opacity: 30 !important;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px !important;
  width: 20px !important;
}

.medical {
  display: flex;
  gap: 10px;
}

/*.radio_box label {
  background-repeat: no-repeat;
  width: 45px;
  padding: 14px 23px;
  background-position: center center;
  box-shadow: 0px 10px 20px rgb(29 161 242 / 30%);
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  background: #fff;
}*/
.radio_box label input[type="radio"]:checked {
  --bs-btn-bg: #2ee2ae;
  background: #000;
}

.error_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #df6129;
  text-align: left !important;
  margin-top: -5px;
  margin-left: 15px;
  margin-bottom: 0;
  padding-bottom: 14px;
}

.select_your_city {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
  text-align: center;
  color: #15181e;
}

.search_input {
  border-width: 0;
  border-color: transparent;
  line-height: 1.9;
  width: -webkit-fill-available;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  height: 51px;
  color: #1d1929;
  border: none;
  padding: 0px 14px 0px 45px;
  background: #f0f0f4;
  border-radius: 14px;
}

.select_city_search_icon {
  position: absolute;
  padding: 18px 15px;
}

.common_pagination .previous {
  background: #199A8E !important;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #FFFFFF !important;
  border: 0;
  padding: 7px 15px;
  /* width: -webkit-fill-available; */
  /* margin-top: 500px; */
}

.common_pagination .previous a {
  text-decoration: none;
  color: white;
}

.common_pagination .next a {
  text-decoration: none;
  color: white;
}

.common_pagination .pagination li {
  background: #fff;
  border: 1px solid #199A8E !important;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #199A8E;
  border: 0;
  padding: 7px 15px;
  margin-right: 10px;
}

.common_pagination .next {
  background: #199A8E !important;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #FFFFFF !important;
  border: 0;
  padding: 7px 15px;
  /* width: -webkit-fill-available; */
  /* margin-top: 500px; */
}

.css-1s2u09g-control {
  border: none !important;
  border-color: transparent;
  line-height: 1.9;
  width: -webkit-fill-available;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  height: 51px;
  color: #1d1929;
  padding: 0px 14px 0px 20px;
  background: #f0f0f4 !important;
  border-radius: 14px !important;
}

.css-tlfecz-indicatorContainer {
  display: none !important;
}

.css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}

.css-1pahdxg-control {
  border: none !important;
  border-color: transparent;
  line-height: 1.9;
  width: -webkit-fill-available;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  height: 51px;
  color: #1d1929;
  padding: 0px 14px 0px 20px;
  background: #f0f0f4 !important;
  border-radius: 14px !important;
}

.css-1s2u09g-control::before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../img/png/search.png");
  background-repeat: no-repeat;
  margin-right: 5px;
}

.css-1pahdxg-control::before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("../img/png/search.png");
  background-repeat: no-repeat;
  margin-right: 5px;
}

/*--------------------------------------------Signup Details Form End-------------------------------------------------*/
/*-------------------------------------------------------Dashboard Header Start-------------------------------------------------------------*/
.topheaderNav {
  background: #ffffff;
  border: 0.7px solid #e2e2e6;
  height: 43px;
}

.topheaderNavbox {
  display: inline-flex;
  column-gap: 25px;
}

.topheaderNavicon {
  margin-top: -2px;
}

.topheraderNavLinkbox {
  margin-top: 2px;
}

.headerNav {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  font-family: "Inter", sans-serif;
  height: 64px;
}

.nav_options {
  margin-left: 100px;
}

.nav_options_2 {
  margin-left: 90px;
}

.topnav_links {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #424448;
  margin-left: 10px;
}

.nav_links {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #909196;
  /* margin-left: 10px; */
}

.nav-link {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  padding: 6px 5px 6px 5px;
}

.nav-link img {
  padding-left: 15px;
}

.nav-profile-mob-div {
  display: none;
}

.nav-link .active {
  color: #199a8e !important;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #199a8e;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #199a8e;
  --bs-btn-hover-border-color: #199a8e;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #199a8e;
  --bs-btn-active-border-color: #199a8e;
  --bs-btn-active-shadow: inset 0 3px 5pxrgba (0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.login_signup_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  background: #199a8e;
  border: 1px solid rgba(137, 152, 189, 0.2);
  border-radius: 8px;
  padding: 8px 16px;
}

.nav_logo {
  width: 80px;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.profile_name {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #1e1f20;
  margin-right: 4px;
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.dropdownarrow {
  padding: 10px;
  cursor: pointer;
}

.header {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-top: 0.7px solid #e2e2e6;
  padding: 10px;
}

.home_text {
  font-family: "Inter";
  font-weight: 500;
  font-size: 13px;
  color: #909196;
  display: inline-flex;
  line-height: 24px;
}

.bones_text {
  font-family: "Inter";
  font-weight: 600;
  font-size: 13px;
  color: #000;
  line-height: 24px;
  padding-left: 5px;
  margin-bottom: 0;
}

/*--------------------------------------------------------Dashboard Header End--------------------------------------------------------------*/
/*--------------------------------------------------------Dashboard Body Start--------------------------------------------------------------*/
.section_1 {
  background: #ffffff;
}

.sec_1_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 45px;
  line-height: 126.34%;
  color: #07070a;
}

.sec_1_slider_text_box {
  padding: 80px 70px 60px 0px;
}

.sec_1_head_word {
  color: #199a8e;
}

.sec_1_para_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 143.34%;
  letter-spacing: 0.01em;
  color: #424448;
}

.city_search_input {
  border: none;
  border-color: transparent;
  line-height: 1.9;
  width: -webkit-fill-available;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  height: 54px;
  color: #1d1929;
  padding: 0px 14px 0px 45px;
  background: #f0f0f4;
  border-radius: 0 14px 14px 0;
}

.city_search_icon_input {
  padding: 18px 16px;
  margin-left: 7px;
  position: absolute;
}

.city_search_input_box {
  background: #f0f0f4;
  border-radius: 14px;
  display: inline-flex;
}

.city_search_dropdown {
  border: none;
  border-color: transparent;
  line-height: 1.9;
  width: -webkit-fill-available;
  transition: all 0.28s ease;
  box-shadow: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  height: 51px;
  color: #1d1929;
  background: #f0f0f4;
  border-radius: 14px 0 0 14px;
  width: 149px;
  display: contents;
}

.btn-outline-secondary {
  border-right: 1px solid #dfdfdf;
  border-left: none;
  border-bottom: none;
  border-top: none;
  padding: 16px 27px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #424448;
  --bs-btn-focus-shadow-rgb: transparent;
  --bs-btn-hover-bg: #f0f0f4;
  --bs-btn-active-color: #424448;
  --bs-btn-hover-color: #424448;
}

#input-group-dropdown-1 {
  border-right: 1px solid #dfdfdf;
  border-radius: 14px 0 0 14px;
}

#input-group-dropdown-1::before {
  content: " ";
  background-image: url("../img/png/location.png");
  padding: 3px 15px;
  background-repeat: no-repeat;
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:active {
  background: #f0f0f4;
  color: #424448;
}

.btn-check:focus+.btn,
.btn:focus {
  background: #f0f0f4;
  color: #424448;
}

.input-group .btn:focus {
  border: none;
  outline: 0;
}

.city_searchicon_box {
  width: 347px;
}

.home_bannerslide_img {
  width: auto;
}

.section_2 {
  background: #fff;
  padding-top: 60px;
  padding-bottom: 30px;
}

.three_section_box {
  display: inline-flex;
  column-gap: 95px;
}

.consult_now_box {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 5px 19px rgba(18, 18, 18, 0.07);
  border-radius: 16px;
  width: 367px;
}

.consult_now_box_2 {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 5px 19px rgba(18, 18, 18, 0.07);
  border-radius: 16px;
  width: 367px;
}

.consult_now_box_3 {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 5px 19px rgba(18, 18, 18, 0.07);
  border-radius: 16px;
  width: 367px;
}

.consult_now_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #424448;
  text-align: center !important;
}

.consult_now_subhead {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.03em;
  color: #67696c;
  text-align: center !important;
}

.consult_now_img {
  width: 119px;
  height: 96px;
  float: right;
}

.medicines_img {
  width: 64px;
  height: 86px;
  float: right;
}

.labtests_img {
  width: 61px;
  height: 84px;
  float: right;
}

.consult_text_box {
  padding: 15px;
}


.health_problem_box {
  padding-top: 45px;
}

.health_problem_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  letter-spacing: 0.3px;
  color: #424448;
  margin-bottom: 0;
}

.health_problem_view {
  background: #ffffff;
  border: 1px solid rgba(137, 152, 189, 0.2);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  float: right;
}

.heath_problem_list_box {
  margin-top: 45px;
  display: inline-flex;
  column-gap: 50px;
}

.healthproblem_icon_bg {
  background: rgba(25, 154, 142, 0.07);
  /* box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06); */
  border-radius: 16px;
  padding: 30px 35px;
}

.healthproblem_icon {
  width: 45px;
  height: 45px;
}

.healthproblem_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.3px;
  color: #424448;
  margin-top: 17px;
}

.healthproblem_modal_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  letter-spacing: 0.3px;
  color: #424448;
  margin-bottom: 0;
}

.heath_problem_list_box_modal {
  margin-top: 20px;
  margin-bottom: 20px;
  display: inline-flex;
  flex-wrap: wrap;
  row-gap: 20px;
}

.healthmodal {
  display: inline-flex;
  justify-content: center !important;
}

.healthproblem_icon_bg_modal {
  background: rgba(25, 154, 142, 0.07);
  /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
  border-radius: 16px;
  padding: 25px 30px;
}

/*---------------------------------------------------------Dashboard Body End---------------------------------------------------------------*/
/*---------------------------------------------------------Chat Screen Start---------------------------------------------------------*/
.chat_container {
  background: #f5f5f5;
  padding: 30px 0;
}

.back_btn {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 8px 19px;
}

.back_btn_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-left: 7px;
  margin-bottom: 0;
}

.back_btn_icon {
  width: 14px;
  height: 14px;
  margin-top: -2px;
}

.chat_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #424448;
  margin-top: 20px;
  margin-bottom: 20px;
}

.chat_card_box {
  background: #ffffff;
  padding: 20px 16px 20px 16px;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  height: 100%;
}

.chat_ul {
  padding: 0px;
  margin-bottom: 0;
}

.chat_tab_option_bg.active {
  background: rgba(25, 154, 142, 0.11);
}

.chat_tab_option_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #909196;
}

.chat_tab_option_text.active {
  color: #199a8e !important;
}

.chat_arrow_active {
  margin-top: 5px;
  float: right;
}

.chat_tab_option_bg {
  background: #ffffff;
  border-radius: 12px;
  padding: 13px 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  /* border-radius: 12px 12px 0 0; */
  padding: 12px 21px;
  border-bottom: 2px solid #eceff4;
}

.chat_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 0 0 12px 12px;
  padding: 25px;
}

.chat_body_box_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
}

.chat_body_box_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #909196;
  margin-bottom: 0;
}

/*-------------------------------------------------------------*/
#chat-container {
  display: grid;
  grid:
    "search-container chat-title" 71px "conversation-list chat-message-list" 1fr;
  min-width: 1000px;
  max-height: 800px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px;
}

#chat-container ::-webkit-scrollbar {
  display: none;
}

#search-container {
  grid-area: search-container;
  border-right: 1px solid #eceff4;
}

#search-container input {
  display: flex;
  flex-direction: row;
  padding: 5px 20px 5px 50px;
  align-items: center;
  height: 44px;
  max-width: 278px;
  width: 278px;
  margin: 23px 13px;
  background: #f8fbff;
  border-radius: 8px;
  border: none;
}

#conversation-list {
  grid-area: conversation-list;
  overflow-y: scroll;
  border-right: 1px solid #eceff4;
  height: 108.1vh;
}

.conversation {
  display: grid;
  grid-template-columns: 40px 1fr max-content;
  grid-gap: 7px;
  border-bottom: 1px solid #eceff4;
  padding: 15px;
  align-items: center;
}

.conversation.active,
.conversation:hover {
  background: #eceff4;
  cursor: pointer;
}

.conversation>img {
  grid-row: span 2;
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.conversation .title-text {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.conversation .created-date {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
}

.conversation .conversation-message {
  grid-column: span 2;
  padding-left: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
}

#new-message-container {
  display: grid;
  grid: 40px / 40px;
  align-content: center;
  border-top: 1px solid;
  border-radius: 0 0 0 10px;
  padding: 0 15px;
}

#new-message-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  text-decoration: none;
  font-size: 3.6rem;
  line-height: 3.6rem;
  outline: none;
  border: none;
  cursor: pointer;
}

#chat-title {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  grid-area: chat-title;
  padding: 10px 20px;
  border-bottom: 1px solid #eceff4;
}

#chat-title .title-text {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.created-gender {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
}

#chat-message-list .created-date {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
  margin: 10px auto;
  margin-bottom: 32px;
  text-align: center;
}

#chat-title .conversation-online {
  grid-column: span 2;
  padding-left: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
}

.rightchat-title {
  position: relative;
  display: flex;
  gap: 15px;
  align-items: center;
}

.dropdown-container a {
  text-decoration: none;
  color: #1e1f20;
}

.dropdown-container a div {
  padding: 2px;
}

.dropdown-container .dropdown {
  padding: 20px 0px 20px 16px;
  width: 173px;
  position: absolute;
  top: 30px;
  right: 10px;
  background: #ffffff;
  /* card shadow */
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  outline: none;
  opacity: 0;
  z-index: 9;
  max-height: 0;
  transition: opacity 0.1s;
  max-height: 5s;
}

.imagfuil {
  height: 30px;
  width: 30px;
}

.dropdown-container .dropdown2 {
  padding: 20px 0px 20px 16px;
  width: 173px;
  position: absolute;
  top: 30px;
  right: 10px;
  background: #ffffff;
  /* card shadow */
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  outline: none;
  opacity: 0;
  z-index: 9;
  max-height: 0;
  transition: opacity 0.1s;
  max-height: 5s;
}

.dropdown-container:focus {
  outline: none;
}

.dropdown-container:focus .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
  transition: opacity 0.2s;
  max-height: 0.2s;
}

#chat-message-list {
  grid-area: chat-message-list;
  display: flex;
  flex-direction: column;
  padding: 0 0px;
}

.chat-message-list-inner {
  /*height: 100%;*/
  height: 100vh;
  overflow-y: scroll;
  padding: 30px 20px 24px 20px;
}

.message-row {
  display: grid;
  grid-template-columns: 55%;
  margin-bottom: 10px;
}

.message-row>.message-content {
  display: grid;
}

.message-row>.message-content>.message-time {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #9393aa;
}

.message-row.you-message {
  justify-content: end;
}

.message-row.you-message>.message-content {
  justify-items: end;
}

.message-row.you-message>.message-content>.message-text {
  background: #f8fbff;
  border-radius: 12px;
  padding: 16px 24px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.message-row.other-message {
  justify-items: start;
}

.message-row.other-message>.message-content>.message-text {
  background: #3093bb;
  border-radius: 12px;
  color: #fff;
  padding: 16px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.content__footer {
  padding-top: 10px;
}

.sendNewMessage {
  background: #f8fbff;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
}

.sendNewMessage button {
  width: 36px;
  height: 36px;
  border: none;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
  color: #96a0b5;
  padding: 0;
  background-color: none;
  border-radius: 5px;
  line-height: 36px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}

.sendNewMessage button i {
  display: block;
}

.sendNewMessage input {
  flex-grow: 1;
  padding: 0 15px;
  background-color: #f8fbff;
  border: none;
  outline: none;
}

#sendMsgBtn {
  color: #96a0b5;
}

.attach-dropdown {
  background-color: #f8fbff !important;
  color: #96a0b5 !important;
}

.attach-dropdown:hover {
  background-color: #f8fbff;
  color: #96a0b5;
}

.attach-dropdown::after {
  content: unset;
}

.upcomming_consult_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
}

.chat_box_bg {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e8e6e6;
}

.upcomming_consult_chat_list {
  border-right: 1px solid #eceff4;
}

.chat_list_box {
  padding: 14px 20px;
}

.chat_list_bottom_border {
  border-bottom: 1px solid #eceff4;
}

.chat_list_box_active {
  padding: 14px 20px;
  background: rgba(36, 178, 178, 0.07);
  border-radius: 12px 0px 0px 0px;
  border: 1px solid #eceff4;
}

.chat_list_id {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #9393aa;
  margin-bottom: 10px;
}

.chat_list_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #1e1f20;
  margin-right: 10px;
}

.chat_list_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #9393aa;
}

.chat_title_box {
  display: inline-flex;
}

.date_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 12px;
  color: #1e1f20;
  margin-top: 7px;
  margin-bottom: 0;
}

.time_left_box {
  background: rgba(248, 68, 108, 0.1);
  border-radius: 4px;
  padding: 7px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #f8446c;
  text-align: center;
  margin-top: 23px;
}

.profile_name_box {
  border: 1px solid #eceff4;
  padding: 18px 20px;
}

.profile_card_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #1e1f20;
  margin-right: 12px;
}

.profile_name_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  color: #9393aa;
  margin-top: 7px;
}

.profile_namesubtitle_box {
  display: inline-flex;
}

.padding_left_0 {
  padding-left: 0px;
}

.padding_right_0 {
  padding-right: 0px;
}

.online_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #9393aa;
}

.chat_call_btn {
  background: #24b2b2;
  border-radius: 8px;
  border: none;
  padding: 6px 11px 10px 11px;
  float: right;
  margin-top: 7px;
  margin-right: 24px;
}

.chat_video_btn {
  background: #24b2b2;
  border-radius: 8px;
  border: none;
  padding: 6px 11px 10px 11px;
  float: right;
  margin-top: 7px;
  margin-right: 4px;
}

.mark_complete {
  background: #ffffff;
  border: 1px solid rgba(137, 152, 189, 0.2);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #f8446c;
  padding: 8px 16px;
  margin-top: 7px;
  float: right;
  margin-right: 75px;
}

.upcomming_consult_chat_message_box {
  border-right: 1px solid #eceff4;
}

.sender_msg_box {
  background: #199a8e;
  border-radius: 12px;
  color: #fff;
  padding: 7px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 10px;
}

.sender_name_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #f9f871;
  margin-bottom: 0;
}

.sender_name_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 3px;
}

.sender_message_time {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #9393aa;
  text-align: right;
  margin-top: 6px;
}

.sender_text_title {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0px;
}

.client_msg_box {
  background: #f0f0f4;
  border-radius: 12px;
  padding: 5px 10px;
  border-radius: 10px;
}

.client_text_title {
  font-family: "Inter";
  padding: 3px 10px 7px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1e1f20;
  margin-bottom: 0;
  text-align: right;
}

.client_mess_text_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  text-align: right;
  color: #909196;
  margin-bottom: 0;
}

.client_message_time {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #9393aa;
  text-align: left;
  margin-top: 6px;
}

.yesno_box {
  background: #ffffff;
  border: 1px solid #e2e2e6;
  border-radius: 14px;
}

.yes_box {
  background: #ffffff;
  padding: 14px;
  /* border-bottom: 0.8px dashed #e2e2e6; */
  /* width: 275px; */
  border-radius: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.yes_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.no_box {
  background: #ffffff;
  padding: 14px;
  border-bottom: 0.8px dashed #e2e2e6;
  width: 275px;
  border-radius: 0 0 14px 14px;
  display: flex;
  justify-content: space-between;
}

.no_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

/*-------------------------------------------------------------*/
.modal_476 {
  --bs-modal-width: 476px;
}

.modal_500 {
  --bs-modal-width: 500px;
}

.chat_modal_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
}

.chat_teaxtarea {
  background: #ffffff;
  border: 1px solid #e2e2e6;
  border-radius: 11px;
  width: 100%;
  height: 156px;
  padding: 13px 14px;
}

.chat_modal_footer {
  display: inline-flex;
  column-gap: 30px;
}

.chat_modal_skip_btn {
  background: #ffffff;
  border: 1.5px solid #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #199a8e;
  padding: 9px 50px;
}

.chat_modal_submit_btn {
  background: #e2e2e6;
  border: 1.5px solid #e2e2e6;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #909196;
  padding: 9px 50px;
}

/*----------------------------------------------------------Chat Screen End----------------------------------------------------------*/
/*----------------------------------------------------------Consultation Screen Start----------------------------------------------------------*/
.nav-tabs {
  grid-column-gap: 20px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  --bs-nav-tabs-border-width: 0px;
  grid-column-gap: 22px;
  border: none;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  --bs-nav-tabs-border-width: 0px;
  border-bottom: 3px solid #df6129;
}

/* .nav-item .nav-link{
  background: none!important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181E!important; 
} */
.consultation_id_box {
  border: 1px solid #e2e2e6;
  border-radius: 20px;
  padding: 18px;
}

.consultant_doc_img {
  width: 90px;
  height: 100px;
  border-radius: 11px;
  object-fit: cover;
}

.consultant_doc_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
}

.consultant_doc_designation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
}

.consultant_doc_experience {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0px;
}

.consultant_count {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.consultant_qualification_content {
  margin-left: 10px;
}

.consultant_count_icon {
  margin-top: -2px;
  display: none;
}

.consultant_qualification_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
  margin-bottom: 0px;
  margin-top: 5px;
}

.consultant_qualification_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #424448;
}

.available_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #199A8E;
  padding: 4px 10px;
  /* background: #ffecda; */
  border-radius: 6px;
  margin-left: 5px;
}

.consult_now_btn {
  background: #199a8e;
  border-radius: 9px;
  border: none;
  text-transform: uppercase;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 12px 25px;
}

.consult_time_btn_box {
  display: inline-flex;
  column-gap: 30px;
  float: right;
  margin-top: 45px;
}

.consult_bottom_border {
  border-bottom: 1px solid #eceff4;
}

.consult_info_box {
  padding-left: 15px;
}

.consultant_info {
  display: flex;
}

.book_date_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 5px;
}

.book_modal_531 {
  --bs-modal-width: 531px;
}

.book_date_time_box {
  padding: 5px 10px;
  margin-top: -40px;
}

.date_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3e4046;
}

.book_modal_close {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 11px;
}

.book_date_card_box {
  display: inline-flex;
  /* column-gap: 15px; */
  column-gap: 5px;
}

.book_date_cun_card_box {
  display: inline-flex;
  column-gap: 15px;
}

.book_date_card {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  padding: 10px 17px;
  cursor: pointer;
}

.book_date_card_active {
  background: #fcefe6;
  border: 1px solid #df6128;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  cursor: pointer;
}

.book_date_card_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #424448;
  margin-bottom: 8px;
}

.time_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3e4046;
  margin-top: 25px;
}

.select_time_box {
  background: #ffffff;
  border: 1px solid #e2e2e6;
  border-radius: 14px;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border-radius: 14px 14px 0 0 !important;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border-bottom: 0.8px dashed #e2e2e6;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448 !important;
}

.accordion {
  --bs-accordion-border-radius: 14px;
  --bs-accordion-btn-focus-border-color: #e2e2e6;
}

.accordion-button:focus {
  background: #fff;
  box-shadow: none;
}

.accordion-item:first-of-type .accordion-button {
  background: #fff;
}

.time_avilable_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #424448;
  margin-left: 10px;
  margin-top: 4px;
}

.accordion-item:last-of-type .accordion-button {
  background: #fff;
  border-radius: 0 0 14px 14px;
}

.accordion-button:not(.collapsed) {
  background: #fff;
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
  border-bottom: none;
}

.accordion-item:not(:first-of-type) {
  border-top: none;
  border-bottom: none;
}

.accordion-header {
  margin-bottom: 0;
  border-top: none;
}

.accordion-item:last-of-type .accordion-button {
  border-top: none;
  border-radius: 14px 14px !important;
}

.time_slot_card_box {
  display: inline-grid;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 8px;
  grid-template-columns: 25% 25% 25% 25%;
  row-gap: 15px;
}

.time_slot_card {
  background: #ffffff;
  border: 1px solid #e2e2e6;
  border-radius: 14px;
  padding: 5px 15px;
  cursor: pointer;
}

.sign-up-city_card {
  background: #ffffff;
  border-radius: 14px;
  padding: 5px 15px;
  cursor: pointer;
}

.sign-up-city_card_active h5 {
  color: #ffffff;
}

.city-label-with {
  align-items: center;
  padding: 5px 0px;
  width: 100%;
  cursor: pointer;
  display: contents;
}

.sign-up-city_card_active {
  /* primary */
  color: #ffffff !important;
  background: #199a8e;
  border-radius: 10px;
}

.sign-up-city_card_text_active {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  color: #df6129;
}

.sign-up-city_card_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  padding: inherit;

  margin-bottom: 0;
}

.time_slot_card_active {
  background: #fcefe6;
  border: 1px solid #df6128;
  border-radius: 14px;
  padding: 5px 15px;
  cursor: pointer;
}

.time_slot_card_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.time_slot_card_text_active {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  /* color: #df6129; */
}

.time_slot_btn {
  background: #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 13px 73.5px;
}

/*-----------------------------------------------------------Consultation Screen End----------------------------------------------------------*/
/*-----------------------------------------------------------Subscription plan Screen Start----------------------------------------------------------*/
.subscription_container {
  background: #f5f5f5;
  padding: 30px 0;
  margin-top: 10px;
}

.subscription_body_box {
  padding: 0px 15px 0px 0px;
  display: flex;
}

.subscription_inner_1 {
  width: 50%;
  height: 100%;
  padding: 30px 20px 30px 0;
}

.subscription_inner_2 {
  width: 50%;
  height: 100%;
  padding: 30px 0px 30px 20px;
  border-left: 1px solid #eceff4;
}

.subscription_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.subscription_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 0px 0px 0px 0px;
  padding: 0 25px;
}

.subscription_plan_card {
  /* background:rgb(204, 204, 228); */
  /*box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);*/
  border-radius: 14px;
  padding: 14px;
  /* display: inline-flex; */
  width: 200px !important;
  height: 154px !important;
  /* justify-content: space-between; */
  cursor: pointer;


}

.subscription_plan_cards {
  border-radius: 14px;
  background-image: url('../img/png/Group\ 34657.png');
  background-size: 200px 170px !important;
  background-repeat: no-repeat !important;
  padding: 14px !important;
  width: 200px !important;
  height: 154px !important;
  cursor: pointer;
}

.price {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
  padding:15px!important;
}
.paginationsss li a {
  color: #777777 !important;
  text-decoration: none;
  /* margin: 10px; */

  /* background-color: #3490DD; */
  padding: 10px 15px;
  border-radius: 50px;
  display: flex;
  border: 1px solid #B9B9B9;
}
.paginationsss li a:hover{
  background-color: #189a8e;  
  color: #fff!important;
}
.paginationsss .page-item.active a {
  background-color: #189a8e; /* Replace with your desired background color */
  color: #fff; /* Replace with your desired text color */
}

.paginationsss {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.subscription_plan_card_active {
  background: #fcefe6;
  border: 1px solid #df6129;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  cursor: pointer;
}

.subscription_plan_modal_card {
  background: #ffffff;
  border: 1px solid #e2e2e6;
  border-radius: 14px;
  padding: 14px;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.subscription_plan_modal_card1 {
  background: #ffffff;
  border: 1px solid #e2e2e6;
  border-radius: 14px;
  padding: 14px;
  justify-content: space-between;
}

.subscription_title {
  font-size: 20px !important;
  font-weight: bold;
}

.subscription_desc {
  font-size: 16px;
  color: gray;
}

#test1 {
  accent-color: #199a8e;
}

#Home {
  accent-color: #199a8e;
}

#Office {
  accent-color: #199a8e;
}

#Other {
  accent-color: #199a8e;
}

#address {
  accent-color: #199a8e;
}


#test2 {
  accent-color: #199a8e;
}

#test3 {
  accent-color: #199a8e;
}

#test4 {
  accent-color: #199a8e;
}

#test5 {
  accent-color: #199a8e;
}

.subscription_plan_box {
  width: auto;
}

.subscription_plan_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.subscription_plan_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF;
  margin-bottom: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subscription_plan_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 0;
  /* margin-top: 5px; */
}


.subscription_plan_allprice {
  text-decoration: line-through;
  font-size: 13px;
  color: #FFFFFF;
}


.subscription_consultation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #3e4046;
  margin-bottom: 25px;
}

.subscription_plan_consultant_left_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #db202b;
}

.subscription_consultation_2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #3e4046;
  margin-top: 25px;
}

.subscrip_consult_card {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 14px;
  padding: 17px 20px 17px 14px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.subscrip_consult_card_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #909196;
  margin-bottom: 0;
}

.subscrip_consult_card_arrow {
  margin-top: 4px;
  float: right;
}

.offer_benifit_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);*/
  border-radius: 14px;
  padding: 17px 20px 17px 14px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
  cursor: pointer;
  border: 1px solid #e2e2e6;
}

.apply_coupon_box {
  display: inline-flex;
}

.apply_coupon_icon {
  width: 20px;
  height: 20px;
  margin-right: 13px;
  margin-top: 3px;
}

.offer_benifit_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #424448;
  margin-bottom: 0;
}

.offer_benifit_text_active {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  margin-bottom: 0;
}

.offer_remove {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #f68047;
  margin-bottom: 0;
  cursor: pointer;
}

.payment_summary {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3e4046;
}

.payment_summary_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 14px 17px 14px;
  border: 1px solid #e2e2e6;
}

.payment_summary_card_inner {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.payment_summary_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.payment_summary_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.payment_hr {
  border: 0.8px dashed #909196;
}

.payment_summary_price_discount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #48988e;
  margin-bottom: 0;
}

.payment_summary_total_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.payment_summary_total_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.wallet_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 14px;
  margin-top: 8px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  border: 1px solid #e2e2e6;
}

.wallet_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 24px;
  color: #df6129 !important;
  margin-bottom: 0;
  border-radius: 50% !important;
}

.wallet_subtext {
  margin-top: 10px;
  margin-left: 5px;
}

.available_bal_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #909196;
  margin-bottom: 0;
}

.available_bal_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.form-group {
  margin-bottom: -10px;
  margin-top: 5px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #df6129;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  background: #fcefe6;
  border-radius: 4px;
  margin-top: -2px;
}

.form-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 6px;
  height: 10px;
  border: 0.4px solid #df6129;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.T_C_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3e4046;
  margin-bottom: 15px;
}

.T_C_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 14px;
  margin-bottom: 30px;
  border: 1px solid #e2e2e6;
}

.T_C_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #424448;
  margin-bottom: 10px;
}

.view_T_C_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  margin-bottom: 0;
}

.book_consult_card {
  background: #ffffff;
  box-shadow: 0px -6px 21px rgba(4, 29, 66, 0.06);
  border-radius: 0px 0px 10px 10px;
  padding: 15px 10px;
  justify-content: end;
  display: flex;
}

.book_consult_card_box {
  display: inline-flex;
  column-gap: 28px;
}

.book_consult_price_box {
  margin-top: 8px;
}

.book_consult_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
}

.book_consult_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
  margin-bottom: 0;
}

.book_consult_btn {
  background: #199a8e;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  padding: 17px 45px 17px 45px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fbfcfc;
}

.select_consult_member_modal_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
}

.consult_members_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.consult_members_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #959595;
}

/*---------------------------------------------------------------*/
.consult_member_box {
  /*justify-content: space-evenly;
  display: flex;*/
}

/*.consult_member_box_inner {
  display: inline-flex;
  column-gap: 235px;
}*/
/*.consult_member_box_inner_2 {
  display: inline-flex;
  column-gap: 250px;
}*/
.consult_member_box input[type="radio"] {
  opacity: 1;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.consult_member_box [type="radio"]:checked,
.consult_member_box [type="radio"]:not(:checked) {
  position: absolute;
  width: 26px;
  height: 26px;
  margin-left: -27px;
}

.consult_member_box [type="radio"]:checked+label,
.consult_member_box [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.consult_member_box [type="radio"]:checked+label:before,
.consult_member_box [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.consult_member_box [type="radio"]:checked+label:after,
.consult_member_box [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #199a8e;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.consult_member_box [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.consult_member_box [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*---------------------------------------------------------------------------------------------*/
[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #999999;
  border-radius: 50%;
  background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #ea7d44;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.consult_member_box_1 {
  display: unset;
  /*padding: 0px 40px;*/
}

.consult_member_box_inner {
  display: flex;
  justify-content: space-between;
}

.consult_member_box_inner_2 {
  display: flex;
  justify-content: space-between;
}

.consult_member_box_1 input[type="radio"] {
  opacity: 1;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.consult_member_box_1 [type="radio"]:checked,
.consult_member_box_1 [type="radio"]:not(:checked) {
  position: absolute;
  width: 26px;
  height: 26px;
  margin-left: -27px;
}

.consult_member_box_1 [type="radio"]:checked+label,
.consult_member_box_1 [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.consult_member_box_1 [type="radio"]:checked+label:before,
.consult_member_box_1 [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.consult_member_box_1 [type="radio"]:checked+label:after,
.consult_member_box_1 [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #199a8e;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.consult_member_box_1 [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.consult_member_box_1 [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.consult_members_title_1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 17px;
  margin-top: 1px;
}

.consult_members_subtitle_1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #df6129;
}

/*---------------------------------------------------------------------------------------------*/
.consult_member_hr {
  border: 0.8px dashed #909196;
  margin-top: 15px;
  margin-bottom: 15px;
}

.add_member_box {
  display: inline-flex;
  column-gap: 15px;
  margin-top: 25px;
  /*margin-left: 25px;*/
}

.add_member_box_2 {
  display: inline-flex;
  column-gap: 15px;
  margin-top: 25px;
  margin-left: 25px;
}

.add_member_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #df6129;
  cursor: pointer;
}

.add_member_icon {
  width: 22px;
  height: 23px;
}

.consult_modal_btn {
  background: #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 13px 48px;
  margin-top: 30px;
}

.select_member_box {
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.select_member_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  background: linear-gradient(180deg, #f78047 0%, #eb6b30 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.select_member_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
}

.select_member_box_2 {
  margin-bottom: 15px;
  margin-left: 25px;
  margin-right: 10px;
  margin-top: 0px;
}

/*---------------------------------------------------------------*/

.consult_mem {
  margin-bottom: -10px;
  margin-top: 5px;
}

.consult_mem input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.consult_mem label {
  position: relative;
  cursor: pointer;
}

.consult_mem label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #c4c4c4;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
}

.consult_mem input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 7px;
  left: 8px;
  width: 6px;
  height: 10px;
  border: 0.4px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.consult_member_modal_box_inner_img {
  display: flex;
  align-items: center;
  gap: 10px;

}

.consult_member_modal_box {
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center !important;
}

.consult_member_modal_box_inner {
  display: inline-flex;
  /* column-gap: 210px; */
}

.consult_member_modal_box_inner_2 {
  display: inline-flex;
  /* column-gap: 225px; */
}

.vh {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.consult_mem input[type="checkbox"]:checked~label:before {
  background: #199a8e no-repeat center;
  background-size: 12px 12px;
  border: 1px solid #199a8e;
  border-radius: 5px;
}

.consult_mem input[type="checkbox"]:focus~label {
  color: #20bf6b;
}

.add_member_modal_box {
  padding: 0 10px;
}

.add_member_input {
  padding: 8px 20px 8px 20px !important;
}

.checkbox {
  margin: 20px !important;
}

.add_member_save {
  background: #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #ffffff;
  border: 0;
  padding: 13px 48px 13px 48px;
  margin-top: 30px;
}

.select_subscription_plan {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 6px;
}

.select_subscription_plan_subtext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #3e4046;
  margin-left: 5px;
}

.select_subscription_plan_option_box {
  padding: 0px 0px 10px 0px;
}

.select_subscription_plan_continue_btn {
  background: #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 13px 48px;
  margin-top: 30px;
}

.form-select {
  border-radius: 10px;
  height: 50px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  color: #1d1929;
}

.medical_history_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-top: 10px;
}

/*---------------------------------------------------------------*/
.medical_history_box {
  display: inline-flex;
  column-gap: 24px;
}

.medical_history_box input[type="radio"] {
  opacity: 1;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.medical_history_box [type="radio"]:checked,
.medical_history_box [type="radio"]:not(:checked) {
  width: 24px;
  height: 24px;
  accent-color: #199a8e;
}

.medical_history_box [type="radio"]:checked+label,
.medical_history_box [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.medical_history_box [type="radio"]:checked+label:before,
.medical_history_box [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.medical_history_box [type="radio"]:checked+label:after,
.medical_history_box [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #199a8e;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.medical_history_box [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.medical_history_box [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.medical_history_title_1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 12px;
  margin-top: 10px;
}

.medical_history_box_inner {
  display: inline-flex;
}

.medical_search_icon {
  margin-top: -35px;
  position: absolute;
  right: 15px;
  cursor: pointer;
}

.tag_name_box {
  background: #199a8e;
  border-radius: 4px;
  padding: 8px 5px 9px 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
}

.close_icon {
  padding: 0 5px 0 10px;
  margin-top: -2px;
}

.add_member_save_2 {
  background: #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #ffffff;
  border: 0;
  padding: 13px 60px 13px 60px;
}

/*---------------------------------------------------------------*/
/*------------------------------------------------------------Subscription plan Screen End-----------------------------------------------------------*/
/*------------------------------------------------------------Payment Screen Start-----------------------------------------------------------*/
.modal_455 {
  --bs-modal-width: 455px;
}

.modal_bg {
  background: #199a8e;
  border: none;
}

.payment {
  background: #f5f5f5;
  padding: 30px 0;
}

.payment_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px 12px 0 0;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
}

.payment_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 0px 0px 12px 12px;
  padding: 0 25px;
}

.payment_body_box_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #424448;
  margin-bottom: 0;
}

.payment_body_box {
  padding: 280px 350px;
}

.third_party_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  text-align: center;
}

.popup_modal_head {
  display: block;
  padding: 30px;
}

.popup_modal_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 0;
}

.payment_modal_body {
  background: #199a8e;
  padding: 0 34px;
}

.book_successfully_card {
  background: #ffffff;
  border-radius: 14px;
  padding: 14px 20px;
}

.book_successfully_cards {
  border-radius: 14px;
  padding: 14px 20px;
  margin: 30px !important;
  border: 1px solid #FFFFFF;
  ;
}

.book_successfully_doc_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
  margin-top: 14px;
}

.book_successfully_doc_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
}





.book_successfully_details_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
  margin-bottom: 5px;
  margin-top: 5px;
}

.book_successfully_details_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  text-align: right;
  margin-bottom: 5px;
  margin-top: 5px;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.book_successfully_details_value_active {
  color: #df6129;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  margin-bottom: 5px;
  margin-top: 5px;
}

.payment_info_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
}

.payment_ok_btn {
  background: #ffffff;
  border-radius: 18px;
  padding: 13px 70px 13px 70px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #199a8e;
  margin-top: 30px;
  margin-bottom: 10px;
}

/*-------------------------------------------------------------Payment Screen End------------------------------------------------------------*/
/*-------------------------------------------------------------Start Consultation Screen Start------------------------------------------------------------*/
.start_consultation {
  background: #f5f5f5;
  padding: 30px 0;
}

.start_consultation_left_pading {
  padding-left: 0;
}

.start_consultation_right_pading {
  padding-right: 0;
}

.start_consultation_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px 0px 0 0;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
  border-right: 1px solid #eceff4;
}

.start_consultation_card_head_2 {
  background: #ffffff;
  box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
  border-radius: 0px 12px 0px 0px;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
}

.start_consultation_card_body {
  background: #ffffff;
  box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
  border-radius: 0 0 0px 12px;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
  border-right: 1px solid #eceff4;
}

.start_consultation_card_body_2 {
  background: #ffffff;
  box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
  border-radius: 0 0 12px 0px;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
}

.start_consult_user_box {
  display: inline-flex;
  column-gap: 15px;
}

.start_consult_user_img {
  width: 42px;
  height: 42px;
  border: 2px solid #199a8e;
  border-radius: 40px;
}

.start_consult_user_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
}

.start_consult_user_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.appointment_detail_box {
  display: inline-flex;
  height: 41px;
}

.appointment_detail_inner_box {
  display: inline-flex;
  float: right;
  column-gap: 210px;
  margin-top: 7px;
}

.appointment_detail_inner_box_1 {
  display: inline-flex;
  float: right;
  column-gap: 36px;
  margin-top: 7px;
  margin-right: 50px;
}

.appointment_detail_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 2px;
}

.scheduled_tag {
  background: #199a8e;
  border-radius: 15px;
  padding: 21px 14px 21px 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  text-align: center;
}

.chat_appointment_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 20px;
  border: 1px solid #e2e2e6;
}

.chat_appointment_card_inner {
  display: inline-flex;
  column-gap: 15px;
}

.chat_appoint_card_consult_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #000000;
  margin-bottom: 0;
  margin-top: 3px;
}

.chat_appoint_card_consult_designation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #67696c;
  margin-bottom: 8;
}

.chat_appoint_card_consult_exp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #424448;
  margin-bottom: 0;
}

.chat_appoint_card_consult_count {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #909196;
  margin-bottom: 0;
}

.chat_appoint_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #15181e;
}

.chat_appoint_hr {
  border: 0.8px dashed #909196;
}

.chat_appoint_details_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #67696c;
}

.chat_appoint_details_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  color: #15181e;
}

.chat_appoint_status_tag {
  background: #f2eadc;
  border-radius: 5px;
  padding: 2px 5px;
  width: fit-content;
  float: right;
}

.status_margin {
  margin-top: 3px;
}

.watch_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #909196;
  margin-bottom: 0;
}

.watch_title_1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000;
}

.wating_box {
  margin-top: 360px;
  margin-bottom: 360px;
}

/*--------------------------------------------------------------Start Consultation Screen End-------------------------------------------------------------*/
/*-------------------------------------------------------------Start Consultation Screen Start------------------------------------------------------------*/
.start_consultation_chat {
  background: #f5f5f5;
  padding: 30px 0;
}

.start_consultation_chat_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px 12px 0px 0px;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
}

.start_consultation_chat_head_inner {
  display: inline-flex;
  float: right;
  column-gap: 36px;
  margin-top: 7px;
  margin-right: 0px;
}

.start_consultation_chat_card_body {
  background: #ffffff;
  box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
  border-radius: 0px 0px 12px 12px;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
}

#consult_chat_message_list {
  grid-area: chat-message-list;
  display: flex;
  flex-direction: column;
  padding: 0 0px;
}

.consult_chat_message_list_inner {
  height: 60vh;
  overflow-y: scroll;
  padding-right: 20px;
}

#consult_chat_message_list .created_date {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #3e4046;
  margin: 10px auto;
  margin-bottom: 32px;
  text-align: center;
  background: #e2e2e6;
  border-radius: 100px;
  width: fit-content;
  padding: 8px 30px;
}

.message_row.other_message {
  justify-items: start;
}

.message_row {
  display: grid;
  grid-template-columns: 100%;
  margin-bottom: 10px;
}

.message_row>.message_content {
  display: grid;
}

.consult_sender_msg_box {
  background: #199a8e;
  border-radius: 12px;
  color: #fff;
  padding: 5px 10.5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 10px;
}

.consult_sender_lab_msg_box {
  background: #199a8e;
  border-radius: 12px;
  color: #fff;
  padding: 5px 10.5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 10px;
  width: 275px;
}

.consult_sender_text_title {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0px;
}

.consult_sender_message_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 18px;
  text-align: right;
}

.consult_client_msg_box {
  background: #f0f0f4;
  border-radius: 12px;
  padding: 5px 10px;
  border-radius: 10px;
  height: fit-content;
}

.consult_client_message_time {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #9393aa;
  text-align: left;
  margin-top: 6px;
}

.message_row.you_message {
  justify-content: end;
}

.consult_you_message_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.message_row.you_message {
  justify-items: end;
}

.consult_you_message_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  text-align: right;
  color: #909196;
}

.consult_attach_img_box {
  padding-top: 7px;
  padding-bottom: 7px;
}

.consult_attach_img_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: left;
  color: #ffffff;
  margin-top: 7px;
  margin-bottom: 0px;
}

.consult_attach_img_card {
  background: #000;
}

.consult_attach_img_sty {
  /* opacity: 0.5; */
  max-height: 200px;
  max-width: 200px;
  object-fit: cover;
}

.consult_download_icon {
  background: #cfd0d1;
  width: fit-content;
  border-radius: 50px;
  padding: 3px 5px 8px 5px;
  margin-top: -150px;
  margin-bottom: 123px;
  position: relative;
  margin-left: 110px;
  width: 34px;
  height: 34px;
}

.consult_close_icon {
  margin-top: -150px;
  margin-bottom: 120px;
  position: relative;
  margin-left: 110px;
}

.consult_attach_img_text_download {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #df6129;
  margin-bottom: 7px;
}

.consult_attach_img_text_check {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  color: #424448;
  margin-top: 7px;
}

.consult_attach_doc_box {
  background: #cffaf3;
  border-radius: 5px;
  padding: 11px 13px;
  margin-top: 7px;
  margin-bottom: 6px;
  width: 254px;
}

.consult_attach_doc_box_inner {
  display: inline-flex;
  column-gap: 57px;
}

.consult_attach_doc_box_SUBinner {
  display: inline-flex;
  column-gap: 10px;
}

.consult_attach_doc_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #15181e;
  margin-bottom: 0;
  margin-top: 5px;
}

.consult_attach_doc_pin_icon {
  width: 24px;
  height: 26px;
  margin-top: 3px;
}

.consult_attach_doc_lab_icon {
  width: 24px;
  height: 24px;
  margin-top: 3px;
}

.consult_attach_doc_download_icon {
  margin-top: -6px;
}

.consult_attach_doc_download_box {
  background: #a3dfd5;
  width: fit-content;
  border-radius: 50px;
  padding: 6px 5px 5px 5px;
}

.view_test_btn {
  background: #ffffff;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  padding-top: 13px;
  padding-bottom: 13px;
  margin-top: 15px;
  width: 100%;
}

.book_now {
  background: #ffffff;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  padding-top: 13px;
  padding-bottom: 13px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 13px 48px 13px 48px;
  width: 100%;
}

.missed_video_icon {
  margin-right: 10px;
  margin-top: -3px;
}

.missed_video {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #df3429;
  background: #f1d6d6;
  border-radius: 5px;
  width: fit-content;
  padding: 9.5px 10px;
  margin-bottom: 20px;
}

.call_end {
  padding: 9.5px 10px;
  background: #e9f4ed;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  width: fit-content;
  color: #199a8e;
}

.call_end_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #15181e;
  margin-left: 14px;
}

.message_box {
  padding-bottom: 10px;
  position: fixed;
  bottom: 0;
}

.message_box_inner {
  background: #b4e5de;
  border: 1px solid #b4e5de;
  border-radius: 15px 15px 0px 0px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.message_box_inner_2 {
  background: #f0f0f4;
  border-radius: 15px;
  display: inline-flex;
  width: 100%;
  padding: 0px 5px 0px 5px;
}

.message_input {
  height: 53px;
  background: #f0f0f4;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #909196;
  padding: 14px 16px;
  width: 100%;
  border: none;
  border-radius: 15px;
}

.message_input_attach_icon {
  padding: 11px 10px;
}

.message_input_img_icon {
  padding: 11px 15px 11px 10px;
}

.conversation_remaining_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 0;
}

.conversation_remaining_info {
  margin-top: -2px;
  margin-left: 5px;
  width: 16px;
  height: 16px;
}

.consult_client_attach_doc_box {
  background: #fff;
  border-radius: 5px;
  padding: 11px 13px;
  margin-top: 7px;
  margin-bottom: 6px;
  width: 254px;
}

.consult_client_attach_doc_box_inner {
  display: inline-flex;
  column-gap: 80px;
}

.consult_client_attach_doc_box_SUBinner {
  display: inline-flex;
  column-gap: 10px;
}

.consult_client_attach_img_text_download {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #df6129;
  margin-bottom: 7px;
}

.consult_client_attach_img_text_check {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  color: #424448;
}

.consult_client_attach_doc_box_inner_2 {
  display: inline-flex;
  column-gap: 103px;
}

.consult_client_attach_doc_box_inner_3 {
  display: inline-flex;
  column-gap: 9px;
}

.consult_video_call_ended {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #199a8e;
  margin-bottom: 0;
}

.consultation_video_call_end_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  color: #15181e;
  margin-bottom: 0;
}

.consult_client_close_icon {
  margin-top: 7px;
}

.consult_msg_box {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.consult_chat_call_icon {
  width: 19px;
  height: 19px;
  cursor: pointer;
}

.consult_chat_videocall_icon {
  width: 25px;
  height: 21px;
  cursor: pointer;
}

.consul_chat_more_icon {
  width: 29px;
  height: 29px;
  cursor: pointer;
}

/*--------------------------------------------------------------Start Consultation Screen End-------------------------------------------------------------*/
/*--------------------------------------------------------------Profile Dashboard Screen Start--------------------------------------------------------------*/
.profiledashboard_container {
  background: #f5f5f5;
  padding: 30px 0;
}

.profiledash_card_box {
  background: #ffffff;
  padding: 20px 16px 20px 16px;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  height: 100%;
}

.profiledash_ul {
  padding: 0px;
  margin-bottom: 0;
}

.profiledash_tab_option_active_bg {
  background: rgba(25, 154, 142, 0.11);
  border-radius: 8px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.profiledash_tab_option_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #909196;
  margin-left: 10px;
  margin-bottom: 0;
}

.profiledash_tab_option_text_active {
  color: #199a8e;
}

.profiledash_arrow_active {
  margin-top: 5px;
  float: right;
}

.profiledash_tab_option_bg {
  background: #ffffff;
  border-radius: 12px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.profiledash_icon {
  margin-top: -4px;
}

.profiledash_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.profiledash_body_box_card_body {
  background: #ffffff;
  /* box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04); */
  border-radius: 0 0 12px 12px;
  padding: 25px;
  height: 90%;
}

.profiledash_body_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 8px;
}

.profiledash_body_card_head_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: #ffffff;
  border-radius: 14px;
  border: 1px solid #f68047;
  color: #f68047;
  padding: 10px 15px;
  float: right;
}

.profiledash_member_box {
  display: inline-grid;
  column-gap: 16px;
  grid-template-columns: 33% 33% 33%;
  width: 97%;
  row-gap: 16px;
}

.profiledash_member_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 14px;
  border: 1px solid #e2e2e6;
}

.profiledash_member_cards {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 14px;
  border: 1px solid #e2e2e6;
}

.profiledash_member_card_inner {
  display: inline-flex;
  column-gap: 13px;
}

.addnewmember {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 14px;
  cursor: pointer;
}

.addnewmember_icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  cursor: pointer;
}

.addnewmember_profile_icon {
  background: #a5e9ff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #424448;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  padding: 5px 0px;
}

.addnewmember_profile_icon_2 {
  background: #ffc1f5;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #424448;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  padding: 7px 0px;
}

.addnewmember_profile_icon_3 {
  background: #ffaaa5;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #424448;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  padding: 7px 0px;
}

.addnewmember_profile_icon_4 {
  background: #b9f38c;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #424448;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  padding: 5px 1px;
}

.addnewmember_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
}

.addnewmember_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 8px;
  color: #67696c;
  margin-bottom: 0px;
}

/*---------------------------------------------------------------Profile Dashboard Screen End---------------------------------------------------------------*/
/*---------------------------------------------------------------Members Profile Screen Start---------------------------------------------------------------*/
.membersprofile_container {
  background: #f5f5f5;
  padding: 30px 0;
  height: 830px;
}

.membersprofile_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #424448;
  margin-top: 20px;
  margin-bottom: 20px;
}

.membersprofile_ul {
  padding: 0px;
  margin-bottom: 0;
}

.membersprofile_card_box {
  background: #ffffff;
  padding: 20px 16px 20px 16px;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
}

.membersprofile_tab_option_active_bg {
  background: rgba(25, 154, 142, 0.11);
  border-radius: 8px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.membersprofile_tab_option_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #909196;
  margin-left: 10px;
  margin-bottom: 0;
}

.membersprofile_tab_option_text_active {
  color: #199a8e;
}

.membersprofile_arrow_active {
  margin-top: 5px;
  float: right;
}

.membersprofile_tab_option_bg {
  background: #ffffff;
  border-radius: 12px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.membersprofile_icon {
  margin-top: -4px;
}

.membersprofile_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.membersprofile_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 0 0 12px 12px;
  padding: 25px;
  height: 90%;
}

.membersprofile_body_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 8px;
}

.membersprofile_edit_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  background: #ffffff;
  border: 1.5px solid #e2e2e6;
  border-radius: 14px;
  padding: 10px 34px;
}

.membersprofile_delete_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #df6129;
  background: #ffffff;
  border: 1.5px solid #e2e2e6;
  border-radius: 14px;
  padding: 10px 34px;
  margin-left: 25px;
}

.membersprofile_btn_box {
  float: right;
}

.memnersprofile_info_box {
  background: #ffffff;
  border: 1.5px solid #e2e2e6;
  border-radius: 14px;
  padding: 20px 16px;
}

.memnersprofile_info_box_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
  margin-bottom: 0;
  margin-top: 5px;
}

.memnersprofile_info_box_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
  margin-top: 5px;
}

.membersprofile_info_box_hr {
  border: 1px solid #e2e2e6;
}

/*----------------------------------------------------------------Members Profile Screen End----------------------------------------------------------------*/
/*----------------------------------------------------------------Own Profile Screen Start----------------------------------------------------------------*/
.ownprofile_container {
  background: #f5f5f5;
  padding: 30px 0;
  height: 830px;
}

.ownprofile_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #424448;
  /*margin-top: 20px;*/
  margin-bottom: 20px;
}

.ownprofile_card_box {
  background: #ffffff;
  padding: 20px 16px 20px 16px;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
}

.ownprofile_ul {
  padding: 0px;
  margin-bottom: 0;
}

.ownprofile_tab_option_active_bg {
  background: rgba(25, 154, 142, 0.11);
  border-radius: 8px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.ownprofile_tab_option_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #909196;
  margin-left: 10px;
  margin-bottom: 0;
}

.ownprofile_tab_option_text_active {
  color: #199a8e;
}

.ownprofile_arrow_active {
  margin-top: 5px;
  float: right;
}

.ownprofile_tab_option_bg {
  background: #ffffff;
  border-radius: 12px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.ownprofile_icon {
  margin-top: -4px;
}

.ownprofile_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.ownprofile_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 0 0 12px 12px;
  padding: 25px;
  height: 100%;
}

.ownprofile_body_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 8px;
}

.ownprofile_edit_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  background: #ffffff;
  border: 1.5px solid #e2e2e6;
  border-radius: 14px;
  float: right;
  padding: 10px 34px;
}

.ownprofile_info_box {
  background: #ffffff;
  border: 1px solid #eceff4;
  border-radius: 14px;
  padding: 20px 16px;
}

.ownprofile_info_box_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
  margin-bottom: 0;
  margin-top: 5px;
}

.ownprofile_info_box_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
  margin-top: 5px;
  word-wrap: break-word;
}

.ownprofile_info_box_hr {
  border: 1px solid #e2e2e6;
}

.ownprofile_consultation_container {
  background: #f5f5f5;
  padding: 30px 0;
}

.upcoming_consultation_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 10px;
}

.upcoming_consultation_box {
  display: inline-flex;
  column-gap: 10px;
}

.upcoming_consultation_card_head {
  background: #199a8e;
  border-radius: 20px 20px 0px 0px;
  padding: 12px 15px;
}

.upcoming_consultation_card_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.upcoming_consultation_card_icon {
  margin-top: -3px;
  margin-right: 10px;
}

.upcoming_consultation_card_body {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 0 0 20px 20px;
  padding: 14px;
  border: 1px solid #e2e2e6;
}

.upcoming_consultation_card_body_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
}

.upcoming_consultation_card_body_toptitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.upcoming_consultation_card_body_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424448;
  margin-bottom: 0;
}

.upcoming_consultation_card_body_endtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
  margin-bottom: 0;
}

.consultation_option_box {
  display: inline-flex;
  float: right;
  margin-top: 33px;
}

.consultation_option_box_inner_1 {
  border-right: 1px solid #e2e2e6;
  padding-right: 22px;
}

.consultation_option_box_inner_2 {
  padding-left: 22px;
  padding-right: 22px;
}

.cancel_appointment {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #db202b;
  cursor: pointer;
}

.cancel_icon {
  margin-right: 5px;
  margin-top: -3px;
  cursor: pointer;
}

.reschedule_icon {
  margin-right: 5px;
  cursor: pointer;
}

.reschedule_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  cursor: pointer;
}

.past_consultation_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  margin-top: 25px;
}

.past_card_shadow {
  /*box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);*/
  border-radius: 20px;
  border: 1px solid #e2e2e6;
}

.past_consultation_card_head {
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  padding: 12px 15px;
  border-bottom: 1px solid #eceff4;
}

.past_consultation_card_icon {
  margin-top: -3px;
  margin-right: 10px;
}

.past_consultation_card_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
}

.past_consultation_card_title_2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #db202b;
}

.past_consultation_card_body {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 0 0 20px 20px;
  padding: 14px;
}

.past_consultation_card_body_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  margin-top: 5px;
  margin-bottom: 8px;
}

.past_consultation_card_body_toptitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.past_consultation_card_body_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424448;
  margin-bottom: 0;
}

.past_consultation_card_body_endtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424448;
  margin-bottom: 0;
}

.past_consultation_box {
  display: inline-flex;
  column-gap: 10px;
}

.rating_star_box {
  float: right;
  display: flex;
}

.rating_star_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #67696c;
  margin-bottom: 0;
  margin-right: 20px;
}

.react-stars-wrapper-06252669768569388 {
  height: 30px;
  margin-top: -5px;
}

.billdownload {
  padding-left: 5px;
}

.bill_btn {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 146px;
  height: 36px;
  background: #eeebfa;
  border-radius: 5px;
  color: #424448;
  margin-top: 25px;
  float: right;
}

.bill_btn:focus {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 146px;
  height: 36px;
  background: #eeebfa;
  border-radius: 5px;
  color: #424448;
  border: none;
}

.bill_btn:hover {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 146px;
  height: 36px;
  background: #eeebfa;
  border-radius: 5px;
  color: #424448;
  border: none;
}

.prescription_btn {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  width: 146px;
  height: 36px;
  background: #e6f4f3;
  border-radius: 5px;
  color: #424448;
  margin-top: 25px;
  margin-left: 10px;
  float: right;
}

.msg_view_btn {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  width: 146px;
  height: 36px;
  background: white;
  border: none;
  margin-top: 5px;
  border-radius: 5px;
  color: #424448;
}

.prescription_btn:focus {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  width: 146px;
  height: 36px;
  background: #e6f4f3;
  border-radius: 5px;
  color: #424448;
  margin-top: 25px;
  margin-left: 10px;
  float: right;
  border: none;
}

.prescription_btn:hover {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  width: 146px;
  height: 36px;
  background: #e6f4f3;
  border-radius: 5px;
  color: #424448;
  margin-top: 25px;
  margin-left: 10px;
  float: right;
  border: none;
}

.consultation_cancel_modal_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 7px;
}

.consultation_cancel_modal_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #df6129;
  margin-bottom: 0;
}

.consultation_cancel_modal_head_box {
  display: inline-grid;
}

.consultation_cancel_modal_body_box {
  padding: 0 10px;
}

/*---------------------------------------------------------*/
.consultation_cancel_modal {
  display: inline-flex;
  column-gap: 24px;
}
.labmaintest{
  display: flex;
  justify-content: space-between!important;
}

.consultation_cancel_modal input[type="radio"] {
  opacity: 1;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.consultation_cancel_modal.consulatation-time input[type="radio"] {
  opacity: 0;
  display: none;
}

.consultation_cancel_modal [type="radio"]:checked,
.consultation_cancel_modal [type="radio"]:not(:checked) {
  width: 24px;
  height: 24px;
  margin-left: -12px;
}

.consultation_cancel_modal [type="radio"]:checked+label,
.consultation_cancel_modal [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.consultation_cancel_modal [type="radio"]:checked+label:before,
.consultation_cancel_modal [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.consultation_cancel_modal [type="radio"]:checked+label:after,
.consultation_cancel_modal [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #199a8e;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.consultation_cancel_modal [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.consultation_cancel_modal [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.consultation_cancel_modal_hr {
  border-bottom: 0.8px #909196;
}

.consultation_cancel_box_inner {
  display: inline-flex;
}

.consultation_cancel_modal {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 12px;
  margin-top: 10px;
  cursor: pointer;
  /* width: 415px; */
}

.consult-health-model-width .consultation_cancel_modal {
  width: 100%;
}

.cancellation_policy_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #df6129;
  text-decoration: none;
}

.cancel_booking_btn {
  background: #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #ffffff;
  border: 0;
  padding: 13px 48px 13px 48px;
}

.ok_btn {
  background: #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #ffffff;
  border: 0;
  padding: 13px 82px 13px 82px;
}

/*---------------------------------------------------------*/
.i_agree_cancellation_policy {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 16px;
  color: #4d5764 !important;
  margin-top: 3px;
  margin-left: 1px;
  cursor: pointer !important;
  position: absolute;
  width: max-content;
}

.booking_cancellation_policy_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #67696c;
}

/*-----------------------------------------------------------------Own Profile Screen End-----------------------------------------------------------------*/
/*-----------------------------------------------------------------Upcoming Consult Screen Start-----------------------------------------------------------------*/
.upcoming_consultation_chat_container {
  background: #f5f5f5;
  padding: 30px 0;
  height: 1240px;
}

.upcoming_consultation_left_pading {
  padding-left: 0;
}

.upcoming_consultation_right_pading {
  padding-right: 0;
}

.upcoming_consultation_chat_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px 0px 0 0;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
  border-right: 1px solid #eceff4;
}

.upcoming_consultation_chat_card_head_2 {
  background: #ffffff;
  box-shadow: 10px 3px 14px rgb(4 29 66 / 6%);
  border-radius: 0px 12px 0px 0px;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
}

.upcoming_consultation_chat_card_body {
  background: #ffffff;
  box-shadow: 1px 5px 11px rgb(4 29 66 / 6%);
  border-radius: 0 0 0px 12px;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
  border-right: 1px solid #eceff4;
  height: 100%;
}

.upcoming_consultation_chat_card_body_2 {
  background: #ffffff;
  box-shadow: 5px 6px 13px rgb(4 29 66 / 6%);
  border-radius: 0 0 12px 0px;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
  height: 100%;
}

.upcoming_consult_user_box {
  display: inline-flex;
  column-gap: 15px;
}

.upcoming_consult_user_img {
  width: 42px;
  height: 42px;
  border: 2px solid #199a8e;
  border-radius: 40px;
}

.upcoming_consult_user_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
}

.upcoming_consult_user_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.upcoming_appointment_detail_box {
  display: inline-flex;
  height: 41px;
}

.upcoming_appointment_detail_inner_box {
  display: inline-flex;
  float: right;
  column-gap: 210px;
  margin-top: 7px;
}

.upcoming_appointment_detail_inner_box_1 {
  display: inline-flex;
  float: right;
  column-gap: 36px;
  margin-top: 7px;
  margin-right: 5px;
}

.upcoming_wating_box {
  margin-top: 360px;
  margin-bottom: 360px;
}

.upcoming_watch_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #909196;
  margin-bottom: 0;
}

.upcoming_watch_title_1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000;
}

.upcoming_appointment_detail_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 2px;
}

.upcoming_scheduled_tag {
  background: #199a8e;
  border-radius: 15px;
  padding: 21px 14px 21px 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  text-align: center;
}

.upcoming_chat_appointment_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 20px;
  border: 1px solid #e2e2e6;
}

.upcoming_chat_appointment_card_inner {
  display: inline-flex;
  column-gap: 15px;
}

.upcoming_chat_appoint_card_consult_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #000000;
  margin-bottom: 0;
  margin-top: 3px;
}

.upcoming_chat_appoint_card_consult_designation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #67696c;
  margin-bottom: 8;
}

.upcoming_chat_appoint_card_consult_exp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #424448;
  margin-bottom: 0;
}

.upcoming_chat_appoint_card_consult_count {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #909196;
  margin-bottom: 0;
}

.upcoming_chat_appoint_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #15181e;
}

.upcoming_chat_appoint_hr {
  border: 0.8px dashed #909196;
}

.upcoming_chat_appoint_details_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #67696c;
}

.upcoming_chat_appoint_details_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  color: #15181e;
}

.upcoming_chat_appoint_status_tag {
  background: #f2eadc;
  border-radius: 5px;
  padding: 2px 5px;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
}

/*------------------------------------------------------------------Upcoming Consult Screen End------------------------------------------------------------------*/
/*------------------------------------------------------------------Completed Consult Screen Start------------------------------------------------------------------*/
.completed_consult_chat_container {
  background: #f5f5f5;
  padding: 30px 0;
}

.completed_consult_chat_card_bg {
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  background: #fff;
  max-height: 82vh;
}

.completed_consultation_left_pading {
  padding-left: 0;
}

.completed_consultation_right_pading {
  padding-right: 0;
}

.completed_consultation_chat_card_head {
  background: #ffffff;
  border-radius: 12px 0px 0 0;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
  border-right: 1px solid #eceff4;
}

.completed_consultation_chat_card_head_2 {
  background: #ffffff;
  border-radius: 0px 12px 0px 0px;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
}

.completed_consultation_chat_card_body {
  background: #ffffff;
  border-radius: 0 0 0px 12px;
  padding: 20px 25px;
  border-bottom: 1px solid #eceff4;
  border-right: 1px solid #eceff4;
}

.completed_consultation_chat_card_body_2 {
  background: #ffffff;
  border-radius: 0 0 12px 0px;
  padding: 24px 24px;
  height: 75vh;
  overflow-y: scroll;
  border-bottom: 1px solid #eceff4;
}

.completed_consult_user_box {
  display: inline-flex;
  column-gap: 15px;
}

.completed_consult_user_img {
  width: 42px;
  height: 42px;
  border: 2px solid #199a8e;
  border-radius: 40px;
}

.completed_consult_user_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
}

.completed_consult_user_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.completed_appointment_detail_box {
  height: 34px;
  margin-top: 8px;
}

.completed_appointment_detail_close {
  float: right;
  margin-top: 6px;
  margin-right: 1px;
}

.completed_appointment_detail_inner_box {
  display: inline-flex;
  float: right;
  column-gap: 210px;
  margin-top: 7px;
}

.completed_appointment_detail_inner_box_1 {
  display: inline-flex;
  float: right;
  column-gap: 36px;
  margin-top: 7px;
  margin-right: 5px;
}

.completed_wating_box {
  margin-top: 360px;
  margin-bottom: 360px;
}

.completed_watch_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #909196;
  margin-bottom: 0;
}

.completed_watch_title_1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000;
}

.completed_appointment_detail_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 2px;
}

.completed_scheduled_tag {
  background: #ffffff;
  border-radius: 15px;
  padding: 21px 14px 21px 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #199a8e;
  text-align: center;
  border: 1px solid #e2e2e6;
}

.completed_scheduled_tag_icon {
  margin-right: 10px;
  margin-top: -4px;
}

.completed_chat_appointment_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 20px;
  border: 1px solid #e2e2e6;
}

.completed_chat_appointment_card_inner {
  display: inline-flex;
  column-gap: 15px;
}

.completed_chat_appoint_card_consult_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #000000;
  margin-bottom: 0;
  margin-top: 3px;
}

.completed_chat_appoint_card_consult_designation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #67696c;
  margin-bottom: 8;
}

.completed_chat_appoint_card_consult_exp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #424448;
  margin-bottom: 0;
}

.completed_chat_appoint_card_consult_count {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #909196;
  margin-bottom: 0;
}

.completed_chat_appoint_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #15181e;
}

.completed_chat_appoint_hr {
  border: 0.8px dashed #909196;
}

.completed_chat_appoint_details_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #67696c;
}

.completed_chat_appoint_details_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  color: #15181e;
}

.download_bill {
  background: #6c8ac2;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  padding: 14px 0px 14px 0px;
  width: 100%;
  margin-bottom: 10px;
}

.download_bill_icon {
  margin-left: 12px;
  margin-top: -3px;
}

.download_prescription {
  background: #15181e;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  padding: 14px 0px 14px 0px;
  width: 100%;
}

.download_prescription_icon {
  margin-left: 12px;
  margin-top: -3px;
}

.lab_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #15181e;
  margin-bottom: 0;
}

.recommendation {
  color: #df6129;
}

.precribed_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #67696c;
}

.lab_test_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #15181e;
  margin-bottom: 5px;
}

.lab_test_text_sty {
  list-style-type: disc;
  padding-left: 20px !important;
}

.view_details {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #15181e;
  border: 1px solid #e2e2e6;
  padding: 14px 35px;
  border-radius: 5px;
  background: #fff;
}

.lab_book_now {
  background: #199a8e;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  padding: 14px 41px;
}

.recommended_doc_box {
  display: inline-flex;
  column-gap: 10px;
  margin-bottom: 17px;
}

.doc_consult_now_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #15181e;
  border: 1px solid #e2e2e6;
  padding: 14px 35px;
  border-radius: 5px;
  background: #fff;
  width: 100%;
}

.appoint_tag {
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  padding: 2px 5px;
  width: fit-content;
  float: right;
}

/* .completed {
  background: #199a8e;
} */
.canceled {
  background: rgba(190, 33, 33, 0.1);
  color: #be2121;
}

.completed_chat_message_list_inner {
  height: 206.8vh;
  overflow-y: scroll;
  padding-right: 20px;
}

.completed_client_msg_box {
  background: #f0f0f4;
  border-radius: 12px;
  padding: 5px 10px;
  border-radius: 10px;
  height: fit-content;
}

.completed_you_message_title_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #199a8e;
  margin-bottom: 0;
}

.completed_you_message_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.completed_you_message_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.completed_attach_doc_box_inner {
  display: inline-flex;
  column-gap: 80%;
}

.completed_sender_text_title {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0px;
}

.completedtext_ul_sty {
  list-style-type: disc;
  padding-left: 20px !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.completedtext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
}

.completedtext_li_sty {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 7px;
}

.completedtext_chat_btn {
  background: #ffffff;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  padding-top: 13px;
  padding-bottom: 13px;
  width: 100%;
  margin-bottom: 5px;
}

.completed_sender_lab_msg_box {
  background: #199a8e;
  border-radius: 12px;
  color: #fff;
  padding: 6px 10.5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 10px;
  width: 275px;
}

.completed_attach_doc_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #15181e;
  margin-bottom: 0;
  margin-top: 5px;
  /*text-transform: uppercase;*/
}

.completed_attach_recomm_doc_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 5px;
}

.completed_attach_recomm_doc_title_1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 15px;
}

/*-------------------------------------------------------------------Completed Consult Screen End-------------------------------------------------------------------*/

/*------------------------------------------------------------------Canceled Consult Screen Start------------------------------------------------------------------*/
.canceled_consult_chat_container {
  background: #f5f5f5;
  padding: 30px 0;
}

.canceled_consult_chat_card_bg {
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  background: #fff;
}

.canceled_consultation_left_pading {
  padding-left: 0;
}

.canceled_consultation_right_pading {
  padding-right: 0;
}

.canceled_consultation_chat_card_head {
  background: #ffffff;
  border-radius: 12px 0px 0 0;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
  border-right: 1px solid #eceff4;
}

.canceled_consultation_chat_card_head_2 {
  background: #ffffff;
  border-radius: 0px 12px 0px 0px;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
}

.canceled_consultation_chat_card_body {
  background: #ffffff;
  border-radius: 0 0 0px 12px;
  padding: 24px 24px;
}

.canceled_consultation_chat_card_body_2 {
  background: #ffffff;
  border-radius: 0 0 12px 0px;
  padding: 24px 24px;
  border-left: 1px solid #eceff4;
}

.canceled_consult_user_box {
  display: inline-flex;
  column-gap: 15px;
}

.canceled_consult_user_img {
  width: 42px;
  height: 42px;
  border: 2px solid #199a8e;
  border-radius: 40px;
}

.canceled_consult_user_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
}

.canceled_consult_user_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.canceled_appointment_detail_box {
  height: 34px;
  margin-top: 8px;
}

.canceled_appointment_detail_close {
  float: right;
  margin-top: 6px;
  margin-right: 1px;
}

.canceled_appointment_detail_inner_box {
  display: inline-flex;
  float: right;
  column-gap: 210px;
  margin-top: 7px;
}

.canceled_appointment_detail_inner_box_1 {
  display: inline-flex;
  float: right;
  column-gap: 36px;
  margin-top: 7px;
  margin-right: 5px;
}

.canceled_wating_box {
  margin-top: 360px;
  margin-bottom: 360px;
}

.canceled_watch_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #909196;
  margin-bottom: 0;
}

.canceled_watch_title_1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000;
}

.canceled_appointment_detail_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 2px;
}

.canceled_scheduled_tag {
  background: #ffffff;
  border-radius: 15px;
  padding: 21px 14px 21px 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #be2121;
  text-align: center;
  border: 1px solid #e2e2e6;
}

.canceled_scheduled_tag_icon {
  margin-right: 10px;
  margin-top: -4px;
}

.canceled_chat_appointment_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 20px;
  border: 1px solid #e2e2e6;
}

.canceled_chat_appointment_card_inner {
  display: inline-flex;
  column-gap: 15px;
}

.canceled_chat_appoint_card_consult_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.4px;
  color: #000000;
  margin-bottom: 0;
  margin-top: 3px;
}

.canceled_chat_appoint_card_consult_designation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #67696c;
  margin-bottom: 8;
}

.canceled_chat_appoint_card_consult_exp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #424448;
  margin-bottom: 0;
}

.canceled_chat_appoint_card_consult_count {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #909196;
  margin-bottom: 0;
}

.canceled_chat_appoint_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #15181e;
}

.canceled_chat_appoint_hr {
  border: 0.8px dashed #909196;
}

.canceled_chat_appoint_details_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #67696c;
}

.canceled_chat_appoint_details_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  color: #15181e;
}

.download_bill {
  background: #eeebfa;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #424448;
  padding: 14px 0px 14px 0px;
  width: 100%;
  margin-bottom: 10px;
}

.download_bill_icon {
  margin-left: 12px;
  margin-top: -3px;
}

.download_prescription {
  background: #e6f4f3;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #424448;
  padding: 14px 0px 14px 0px;
  width: 100%;
}

.download_prescription_icon {
  margin-left: 12px;
  margin-top: -3px;
}

.lab_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #15181e;
  margin-bottom: 0;
}

.recommendation {
  color: #df6129;
}

.precribed_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #67696c;
}

.lab_test_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #15181e;
  margin-bottom: 5px;
}

.lab_test_text_sty {
  list-style-type: disc;
  padding-left: 20px !important;
}

.view_details {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #15181e;
  border: 1px solid #e2e2e6;
  padding: 14px 35px;
  border-radius: 5px;
  background: #fff;
}

.lab_book_now {
  background: #199a8e;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  padding: 14px 41px;
}

.recommended_doc_box {
  display: inline-flex;
  column-gap: 10px;
  margin-bottom: 17px;
}

.doc_consult_now_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #424448;
  border: 1px solid #e2e2e6;
  padding: 14px 35px;
  border-radius: 5px;
  background: #fff;
  width: 100%;
}

.appoint_canceled_tag {
  background: rgba(190, 33, 33, 0.1);
  color: #be2121;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  padding: 2px 5px;
  width: fit-content;
  float: right;
  text-transform: capitalize;
}

.canceled_chat_message_list_inner {
  height: 81.9vh;
  overflow-y: scroll;
  padding-right: 20px;
}

.canceled_client_msg_box {
  background: #f0f0f4;
  border-radius: 12px;
  padding: 5px 10px;
  border-radius: 10px;
  height: fit-content;
}

.canceled_you_message_title_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #199a8e;
  margin-bottom: 0;
}

.canceled_you_message_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.canceled_you_message_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.canceled_attach_doc_box_inner {
  display: inline-flex;
  column-gap: 80%;
}

.canceled_sender_text_title {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0px;
}

.canceledtext_ul_sty {
  list-style-type: disc;
  padding-left: 20px !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.canceledtext_li_sty {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 7px;
}

.canceledtext_chat_btn {
  background: #ffffff;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  padding-top: 13px;
  padding-bottom: 13px;
  width: 100%;
  margin-bottom: 5px;
}

.canceled_sender_lab_msg_box {
  background: #199a8e;
  border-radius: 12px;
  color: #fff;
  padding: 6px 10.5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 10px;
  width: 275px;
}

.canceled_attach_doc_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #15181e;
  margin-bottom: 0;
  margin-top: 5px;
  text-transform: uppercase;
}

.canceled_attach_recomm_doc_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 5px;
}

.canceled_attach_recomm_doc_title_1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 15px;
}

/*-------------------------------------------------------------------Canceled Consult Screen End-------------------------------------------------------------------*/

/*------------------------------------------------------------------Profile Test booking Screen Start------------------------------------------------------------------*/
.profile_test_booking_container {
  background: #f5f5f5;
  padding: 30px 0;
}

.profile_test_container {
  background: #f5f5f5;
  padding: 30px 0;
  height: 830px;
}

.profile_test_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #424448;
  margin-top: 20px;
  margin-bottom: 20px;
}

.profile_test_card_box {
  background: #ffffff;
  padding: 20px 16px 20px 16px;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
}

.profile_test_ul {
  padding: 0px;
  margin-bottom: 0;
}

.profile_test_tab_option_active_bg {
  background: rgba(25, 154, 142, 0.11);
  border-radius: 8px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.profile_test_tab_option_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #909196;
  margin-left: 10px;
  margin-bottom: 0;
}

.profile_test_tab_option_text_active {
  color: #199a8e;
}

.profile_test_arrow_active {
  margin-top: 5px;
  float: right;
}

.profile_test_tab_option_bg {
  background: #ffffff;
  border-radius: 12px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.profile_test_icon {
  margin-top: -4px;
}

.profile_test_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.profile_test_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px;
  padding: 25px;
  height: 100%;
}

.profile_test_body_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 8px;
}

.profile_test_edit_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  background: #ffffff;
  border: 1.5px solid #e2e2e6;
  border-radius: 14px;
  float: right;
  padding: 10px 34px;
}

.profile_test_info_box {
  background: #ffffff;
  border: 1.5px solid #e2e2e6;
  border-radius: 14px;
  padding: 20px 16px;
}

.profile_test_info_box_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
  margin-bottom: 0;
  margin-top: 5px;
}

.profile_test_info_box_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
  margin-top: 5px;
}

.profile_test_info_box_hr {
  border: 1px solid #e2e2e6;
}

.upcoming_test_consultation_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 10px;
}

.upcoming_test_consultation_box {
  display: inline-flex;
  column-gap: 10px;
}

.upcoming_test_consultation_card_head {
  background: #199a8e;
  border-radius: 20px 20px 0px 0px;
  padding: 12px 15px;
}

.upcoming_test_consultation_card_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.upcoming_test_consultation_card_icon {
  margin-top: -3px;
  margin-right: 10px;
}

.upcoming_test_consultation_card_body {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 0 0 20px 20px;
  padding: 14px;
}

.upcoming_test_consultation_card_body_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 8px;
  margin-top: 5px;
}

.upcoming_test_consultation_card_body_toptitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.upcoming_test_consultation_card_body_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424448;
  margin-bottom: 0;
}

.upcoming_test_consultation_card_body_note {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #df6129;
}

.upcoming_test_consultation_card_body_endtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424448;
  margin-bottom: 0;
}

.consultation_test_option_box {
  display: inline-flex;
  float: right;
  margin-top: 28px;
}

.consultation_test_option_box_1 {
  display: inline-flex;
  float: right;
  margin-top: 14px;
}

.consultation_test_option_box_inner_1 {
  border-right: 1px solid #e2e2e6;
  padding-right: 22px;
}

.consultation_test_option_box_inner_2 {
  padding-left: 22px;
  padding-right: 22px;
}

.consultation_test_complete_option_box_inner {
  padding-left: 22px;
  padding-right: 22px;
}

.cancel_appointment {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #db202b;
  cursor: pointer;
}

.cancel_icon {
  margin-right: 5px;
  margin-top: -3px;
  cursor: pointer;
}

.reschedule_icon {
  margin-right: 5px;
  cursor: pointer;
}

.info_icon {
  margin-left: 10px;
}

.reschedule_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  cursor: pointer;
}

.past_consultation_card_head {
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  padding: 5px 15px;
  border-bottom: 1px solid #eceff4;
}

.past_consultation_card_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #199a8e;
}

.past_consultation_card_body_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  margin-top: 5px;
  margin-bottom: 8;
}

.past_consultation_card_body_toptitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.past_consultation_card_body_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424448;
  margin-bottom: 0;
}

.past_consultation_card_body_note {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #df6129;
}

.past_consultation_card_body_endtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
  margin-bottom: 0;
}

.past_consultation_box {
  display: inline-flex;
  column-gap: 10px;
}

.e_report_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
}

.e_report_width {
  width: auto;
}

.e_report {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  /*margin-top: 9px;*/
  /*background: #E3F3FB;*/
  border-radius: 5px;
  padding: 10px;
}

.e_report_bg {
  background: #e3f3fb;
  border-radius: 5px;
  padding: 8px 11px;
  display: inline-flex;
  column-gap: 15px;
}

.e_report_download_bg {
  /*background: #A3DFD5;*/
  padding: 7px;
  border-radius: 20px;
}

.e-report_dowmload_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
  margin-top: 2px;
}

.e-report_icon {
  padding-left: 10px;
}

/*-------------------------------------------------------------------Profile Test booking Screen End-------------------------------------------------------------------*/
/*----------------------------------------------------------Test Completed details Screen Start----------------------------------------------------------*/
.test_completed_details_container {
  background: #f5f5f5;
  padding: 30px 0;
}

.test_completed_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #424448;
  /*margin-top: 20px;*/
  margin-bottom: 20px;
}

.test_completed_card_box {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  padding: 24px;
}

.test_completed_lab_test_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 10px;
}

.test_completed_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.test_completed_note {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #df6129;
}

.test_completed_option_box_1 {
  display: inline-flex;
  float: right;
}

.download_e_report_width {
  width: 200px;
}

.download_e_report {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  padding: 10px 11px;
  background: #e3f3fb;
  border-radius: 5px;
  width: 200px;
  text-align: center;
}

.test_completed_hr {
  border: 0.8px dashed #909196;
}

.test_completed_body_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 15px;
}

.test_completed_time_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.test_completed_time_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
}

.test_completed_status_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  margin-bottom: 0;
  margin-top: 7px;
}

.test_completed_status_box {
  display: inline-flex;
  column-gap: 12px;
}

.test_completed_staus_icon {
  width: 18px;
  height: 18px;
  margin-top: 10px;
}

.track_dot {
  margin-left: 0px;
  width: 13px;
  margin-top: -1px;
}

.track_dot_notactive {
  margin-left: 0px;
  width: 13px;
  margin-top: -31px;
}

.track_dot_last {
  margin-left: 0px;
  width: 13px;
  margin-top: -1px;
}

.track_dot_last_notactive {
  margin-left: 0px;
  width: 13px;
  margin-top: -31px;
}

.track_dot_bg_last {
  background: rgba(25, 154, 142, 0.22);
  padding: 3px;
  width: 23px;
  height: 23px;
  border-radius: 20px;
  margin-left: 0px;
}

.track_dot_bg {
  background: rgba(25, 154, 142, 0.22);
  padding: 3px;
  width: 23px;
  height: 23px;
  border-radius: 20px;
  margin-left: -1px;
}

.track_dot_not_active_bg {
  background: rgba(211, 211, 211, 0.33);
  padding: 3px;
  width: 23px;
  height: 23px;
  border-radius: 20px;
  margin-left: -1px;
}

.track_dot_bg_last_notactive {
  background: rgba(211, 211, 211, 0.33);
  padding: 3px;
  width: 23px;
  height: 23px;
  border-radius: 20px;
  margin-left: 0px;
}

.track_dot_bg_last_cancelled {
  background: rgba(219, 32, 43, 0.22);
  padding: 3px;
  width: 23px;
  height: 23px;
  border-radius: 20px;
  margin-left: 0px;
}

.track_dot_last_cancelled {
  fill: #db202b;
}

.tracking_item_height {
  min-height: 23px !important;
}

.tracking-detail {
  padding: 3rem 0;
}

#tracking {
  margin-bottom: 0rem;
}

[class*="tracking-status-"] p {
  margin: 0;
  font-size: 1.1rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

[class*="tracking-status-"] {
  padding: 1.6rem 0;
}

.tracking-list {}

.tracking-item {
  border-left: 2px dashed #199a8e;
  position: relative;
  padding: 0rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 5px;
  min-height: 4rem;
}

.tracking-item:last-child {
  padding-bottom: 0rem;
  border-left: none;
}

.tracking-item .tracking-date {
  margin-bottom: 0.5rem;
}

.tracking-item .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}

.tracking-item .tracking-content {
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
}

.tracking-item .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}

.tracking-item .tracking-icon {
  position: absolute;
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  background-color: #fff;
  color: #fff;
}

.tracking-item-pending {
  border-left: 2px dashed #c4c4c4;
  position: relative;
  padding: 0rem 1.5rem 0.5rem 2.5rem;
  font-size: 0.9rem;
  margin-left: 5px;
  min-height: 4rem;
}

.tracking-item-pending:last-child {
  padding-bottom: 0rem;
  border: none;
}

.tracking-item-pending .tracking-date {
  margin-bottom: 0.5rem;
}

.tracking-item-pending .tracking-date span {
  color: #888;
  font-size: 85%;
  padding-left: 0.4rem;
}

.tracking-item-pending .tracking-content {
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
}




.tracking-item-pending .tracking-content span {
  display: block;
  color: #767676;
  font-size: 13px;
}

.tracking-item-pending .tracking-icon {
  line-height: 2.6rem;
  position: absolute;
  left: -0.7rem;
  width: 1.1rem;
  height: 1.1rem;
  text-align: center;
  border-radius: 50%;
  font-size: 1.1rem;
  color: #d6d6d6;
}

.tracking-item-pending .tracking-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  position: absolute;
  margin-top: -10px;
}

.tracking_content_noactive {
  color: #909196 !important;
}

.tracking_content_cancelled {
  color: #db202b !important;
}

.tracking-item .tracking-icon.status-current {
  width: 1.9rem;
  height: 1.9rem;
  left: -1.1rem;
}

.tracking-item .tracking-icon.status-intransit {
  color: #199a8e;
  font-size: 0.6rem;
}

.tracking-item .tracking-icon.status-current {
  color: #199a8e;
  font-size: 0.6rem;
}

@media (min-width: 992px) {
  .tracking-item .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }

  .tracking-item .tracking-date span {
    display: block;
  }

  .tracking-item .tracking-content {
    padding: 0;
    background-color: transparent;
  }

  .tracking-item-pending .tracking-date {
    position: absolute;
    left: -10rem;
    width: 7.5rem;
    text-align: right;
  }

  .tracking-item-pending .tracking-date span {
    display: block;
  }

  .tracking-item-pending .tracking-content {
    padding: 0;
    background-color: transparent;
  }
}

.tracking-item .tracking-content {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  position: absolute;
  margin-top: -10px;
}

.tracking_content_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
}

.blinker {
  border: 7px solid #e9f8ea;
  animation: blink 1s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  50% {
    border-color: #fff;
  }
}

.track_box {
  margin-top: 30px;
  margin-bottom: -5px;
}

/*.hh-grayBox {
  background-color: #F8F8F8;
  margin-bottom: 20px;
  padding: 35px;
  margin-top: 20px;
}*/
.pt45 {
  padding-top: 45px;
}

.order-tracking {
  text-align: center;
  width: 20.33%;
  position: relative;
  display: block;
}

.order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  border: 0px solid #afafaf;
  background-color: #e2e2e6;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}

.order-tracking .is-cancel {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 13px;
  width: 13px;
  border: 0px solid #afafaf;
  background-color: #db202b;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}

/*.order-tracking .is-complete:after {
  display: block;
  position: absolute;
  content: '';
  height: 14px;
  width: 7px;
  top: -2px;
  bottom: 0;
  left: 5px;
  margin: auto 0;
  border: 0px solid #AFAFAF;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}*/
.order-tracking.completed .is-complete {
  border-color: #199a8e;
  border-width: 0px;
  background-color: #199a8e;
  border: 2px solid rgba(25, 154, 142, 0.33);
}

/*.order-tracking.completed .is-complete:after {
  border-color: #fff;
  border-width: 0px 3px 3px 0;
  width: 7px;
  left: 11px;
  opacity: 1;
}*/
.order-tracking p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #a4a4a4;
  margin-top: 20px;
  margin-bottom: 0;
  line-height: 20px;
}

.order-tracking p span {
  font-size: 14px;
}

.order-tracking.completed p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #909196;
}

.order-tracking::before {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background-color: #e2e2e6;
  top: 5px;
  position: absolute;
  left: calc(-50% + 0px);
  z-index: 0;
}

.order-tracking:first-child:before {
  display: none;
}

.order-tracking.completed:before {
  background-color: #27aa80;
}

.order-tracking.cancelled:before {
  background-color: #27aa80;
}

.booking_placed_title.active {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  margin-top: -40px;
}

.booking_placed_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #909196;
  margin-top: -40px;
}

.booking_cancelled_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #db202b;
  margin-top: -40px;
}

.sample_collected_by_box {
  display: inline-flex;
  column-gap: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.sample_collected_by {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
}

.sample_collected_doc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.sample_collected_emp_id {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #67696c;
}

.sample_collection_add {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
  margin: 15px 0;
}

.sample_collection_add_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  /*margin-top: 15px;*/
}

.sample_collection_add_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 5px;
  margin-top: 5px;
}

.test_payment_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 20px;
}

.test_payment_summary_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  color: #67696c;
}

.test_payment_summary_discount_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
}

.test_payment_summary_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
}

.test_payment_summary_discount_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #199a8e;
}

.test_pending_e_report_bg {
  background: #cffaf3;
  border-radius: 5px;
  padding: 8px 50px;
  display: inline-flex;
  column-gap: 15px;
}

.test_pendinge_report_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  margin-top: 0px;
  margin-bottom: 2px;
}

.test_cancel_status_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #db202b;
  margin-bottom: 0;
  margin-top: 7px;
}

.test_ongoing_status_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #4876ed;
  margin-bottom: 0;
  margin-top: 7px;
}

/*-----------------------------------------------------------Test Completed details Screen End-----------------------------------------------------------*/

/*----------------------------------------------------------Test Cancelled details Screen Start----------------------------------------------------------*/
.cancellation {
  width: 30.33%;
  position: relative;
  display: block;
  margin-top: -25px;
  text-align: left;
}

.cancellation_reason_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
}

.went_offline_lab_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.your_refund_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ef861a;
  margin-bottom: 0;
}

.test_cancelled_details_container {
  background: #f5f5f5;
  padding: 30px 0;
}

.test_cancelled_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #424448;
  /*margin-top: 20px;*/
  margin-bottom: 20px;
}

.test_cancelled_card_box {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  padding: 24px;
}

.test_cancelled_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
}

.test_cancelled_note {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #df6129;
}

.test_cancelled_option_box_1 {
  display: inline-flex;
  float: right;
}

.test_cancelled_hr {
  border: 0.8px dashed #909196;
}

.test_cancelled_body_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 15px;
}

.test_cancelled_time_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.test_cancelled_time_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
}

.test_cancelled_status_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  margin-bottom: 0;
  margin-top: 7px;
}

.test_cancelled_status_box {
  display: inline-flex;
  column-gap: 12px;
}

.test_cancelled_staus_icon {
  width: 18px;
  height: 18px;
  margin-top: 10px;
}

.offline_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 15px;
}

.offline_subtext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #df6129;
  margin-bottom: 0;
}

/*-----------------------------------------------------------Test Completed details Screen End-----------------------------------------------------------*/
/*-----------------------------------------------------------Radiology booked details Screen Start-----------------------------------------------------------*/
.radiology_test_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
}

.radiology_booked_details_container {
  background: #f5f5f5;
  padding: 30px 0px 80px 0;
}

.radiology_booked_card_box {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px;
  padding: 24px;
}

.radiology_booked_option_box_1 {
  display: inline-flex;
  float: right;
  margin-top: 10px;
}

.radiology_booked_option_box_inner {
  padding-left: 22px;
  padding-right: 22px;
}

.radiology_booked_for_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.radiology_booked_test_for {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
}

.radiology_booked_test_profile_box {
  display: inline-flex;
  column-gap: 10px;
}

.radiology_booked_test_for_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 5px;
}

.radiology_booked_hr {
  border: 0.8px dashed #909196;
  margin-top: 20px;
  margin-bottom: 20px;
}

.radiology_booked_note {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #df6129;
  margin-bottom: 0;
  margin-top: 12px;
}

.view_map_btn {
  background: #ffffff;
  border: 1.5px solid #e2e2e6;
  border-radius: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  padding: 10px 20px;
  margin-top: 20px;
}

/*------------------------------------------------------------Radiology booked details Screen End------------------------------------------------------------*/
/*------------------------------------------------------------My Consultation details Screen Start------------------------------------------------------------*/
.my_consultation_details_container {
  background: #f5f5f5;
  padding: 30px 0px 80px 0;
}

.my_consultation_card_box {
  background: #ffffff;
  padding: 20px 16px 20px 16px;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  height: 100%;
}

.my_consultation_ul {
  padding: 0px;
  margin-bottom: 0;
}

.my_consultation_tab_option_active_bg {
  background: rgba(25, 154, 142, 0.11);
  border-radius: 8px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.my_consultation_tab_option_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #909196;
  margin-left: 10px;
  margin-bottom: 0;
}

.my_consultation_tab_option_text_active {
  color: #199a8e;
}

.my_consultation_arrow_active {
  margin-top: 5px;
  float: right;
}

.my_consultation_tab_option_bg {
  background: #ffffff;
  border-radius: 12px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.my_consultation_icon {
  margin-top: -4px;
}

.my_consultation_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.my_consultation_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 0 0 12px 12px;
  padding: 25px;
}

.my_consultation_body_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 8px;
}

.my_consultation_body_card_head_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: #ffffff;
  border-radius: 14px;
  border: 1px solid #f68047;
  color: #f68047;
  padding: 10px 15px;
  float: right;
}

.react-stars-wrapper-026705391144771173 div span {
  font-size: 30px !important;
  margin-top: -10px;
  padding: 0 3px;
}

.my_consultation_doc_img {
  width: 85px;
}

/*-------------------------------------------------------------My Consultation details Screen End-------------------------------------------------------------*/
/*------------------------------------------------------------My Test Booking Screen Start------------------------------------------------------------*/
.my_test_booking_container {
  background: #f5f5f5;
  padding: 30px 0px 80px 0;
}

.my_test_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px 12px 0 0;
  padding: 23.5px 21px;
  border-bottom: 1px solid #eceff4;
}

.my_test_body_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
}

.my_test_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 0 0 12px 12px;
  padding: 25px;
}

.upcoming_test_booking_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 10px;
}

.upcoming_test_card_head {
  background: #199a8e;
  border-radius: 20px 20px 0px 0px;
  padding: 12px 15px;
}

.upcoming_test_card_body {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);*/
  border-radius: 0 0 20px 20px;
  padding: 14px;
  border: 1px solid #e2e2e6;
}

.my_test_id {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 20px;
}

.my_test_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
}

.my_test_note {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #df6129;
  margin-bottom: 0;
}

.my_test_profile_icon_1 {
  background: #bcffa5;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #424448;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  padding: 5px 1px;
}

.my_test_profile_icon_2 {
  background: #ffd0a5;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #424448;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  padding: 5px 1px;
}

.my_test_option_box {
  display: inline-flex;
  float: right;
  margin-top: 0px;
}

.past_test_booking_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 10px;
}

.past_test_card_head {
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  padding: 12px 15px;
  border: 1px solid #e2e2e6;
  border-bottom: 1px solid #eceff4;
}

.past_test_card_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
}

.past_test_card_body {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 0 0 20px 20px;
  padding: 14px;
  border-left: 1px solid #e2e2e6;
  border-bottom: 1px solid #e2e2e6;
  border-right: 1px solid #e2e2e6;
}

.past_test_card_cancel_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #db202b;
}

/*-------------------------------------------------------------My Test Booking Screen End-------------------------------------------------------------*/
/*-------------------------------------------------------------My Test Booking Completed Screen Start-------------------------------------------------------------*/
.mytest_booking_completed_container {
  background: #f5f5f5;
  padding: 30px 0px 80px 0;
}

.mytest_booking_completed_card_box {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px;
  padding: 24px;
}

/*--------------------------------------------------------------My Test Booking Completed Screen End--------------------------------------------------------------*/
/*-------------------------------------------------------------Medicine Orders Screen Start-------------------------------------------------------------*/
.medicine_orders_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.medicine_orders_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.medicine_orders_body_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 8px;
}

.medicine_orders_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 0 0 12px 12px;
  padding: 25px;
  height: 90%;
}

.medicine_orders_card_head {
  background: #fff;
  border-radius: 20px 20px 0px 0px;
  padding: 12px 15px;
  border-bottom: 1px solid #eceff4;
}

.medicine_orders_card_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
}

.medicine_orders_card_head_status_processing {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  color: #df6129;
  margin-bottom: 0;
}

.radiology_booked_test_for_amount {}

.medicine_orders_card_head_status_delivered {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #199a8e;
  margin-bottom: 0;
}

.medicine_orders_card_head_status_cancelled {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #db202b;
  margin-bottom: 0;
}

.medicine_orders_card_body {
  background: #ffffff;
  border-radius: 0 0 20px 20px;
  padding: 14px;
}

.medicine_order_card_box {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 20px;
  border: 1px solid #e2e2e6;
}

.medicine_order_card_icon {
  margin-top: -3px;
  margin-right: 10px;
}

.medicine_order_box_inner {
  text-align: right;
  margin-top: 15px;
}

/*--------------------------------------------------------------Medicine Orders Screen End--------------------------------------------------------------*/
/*-------------------------------------------------------------Medicine Orders Placed Screen Start-------------------------------------------------------------*/
.medicine_orders_placed_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.medicine_orders_placed_card_box {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px;
  padding: 24px;
}

.medicine_orders_placed_booked_hr {
  border: 0.8px dashed #909196;
  margin-top: 20px;
  margin-bottom: 20px;
}

.medicine_orders_placed_hr {
  border: 0.8px dashed #909196;
  margin-top: 20px;
  margin-bottom: 20px;
}

.medicine_orders_placed_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
}

.medicine_orders_placed_toptitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
}

.medicine_orders_placed_data {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.cancellation_reason {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
}

.cancellation_reason_add_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 15px;
}

.cancellation_reason_add_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #df6129;
  margin-bottom: 5px;
  margin-top: 5px;
}

/*--------------------------------------------------------------Medicine Orders Placed Screen End--------------------------------------------------------------*/
/*--------------------------------------------------------------Health Package Screen Start--------------------------------------------------------------*/
.health_package_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.health_package_container_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #424448;
}

.health_package_card_box {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  margin-top: 10px;
}

.health_package_left_side_section {
  padding: 24px;
}

.health_package_left_padding {
  padding-left: 0;
}

.health_package_right_padding {
  padding-right: 0;
}

.health_package_Recommended {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
}

.health_package_side_section_card {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  padding: 16px;
}

.lab_test_box {
  display: flex;
  justify-content: space-between;
}

.lab_test_tag {
  background: rgba(223, 97, 41, 0.44);
  border-radius: 4px;
  padding: 2px 11px;
  width: fit-content;
  margin-bottom: 12px;
}

.lab_test_tag_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
}

.lab_text_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 8px;
}

.lab_test_subtext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424448;
  margin-bottom: 0;
}

.book_test_btn {
  background: #199a8e;
  border: 1px solid rgba(137, 152, 189, 0.2);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  padding: 8px 16px;
  margin-top: 28px;
}

.popular_package_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
}

.popular_package_card {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  margin-bottom: 20px;
  /* height: 260px; */
  padding: 3rem;
}

.popular_package_card_img {
  width: -webkit-fill-available;
  border-radius: 14px 14px 0 0;
}

.popular_package_card_inner_box {
  padding: 15px 10px;
}

.popular_package_card_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #424448;
}

.popular_package_card_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #424448;
}

.popular_package_card_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #424448;
  margin-bottom: 0;
}

.health_package_right_side_section_1 {
  padding: 24px 24px 0 24px;
  border-left: 1px solid #eceff4;
  border-bottom: 1px solid #eceff4;
}

.health_package_right_side_section_2 {
  padding: 24px 24px 0 24px;
  border-left: 1px solid #eceff4;
}

.health_package_right_owl_slider_img {
  width: 332px;
  height: 195px;
  border-radius: 14px;
}

.health_package_lab_test {
  padding: 24px 0 29px 0;
}

.health_package_input_search {
  background: #f0f0f4;
  border-radius: 14px;
  width: 403px;
  height: 53px;
  border: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  padding: 0px 14px 0px 50px;
}

.supporttext {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #199A8E;
  padding-top: 8px;
}

.centertext {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: #67696C;
  width: 240px;
  padding-top: 15px;

}

.subscription_inner_3 {
  width: 100% !important;
}

.inputtext {
  background: #FFFFFF;
  border-radius: 14px;
  width: 408px;
  height: 53px;
  border: 1px solid #E2E2E6;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  padding: 0px 14px 0px 50px;
}

.health_package_lab_test_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
  margin-top: 5px;
}

.health_package_lab_test_view_all_btn {
  background: #ffffff;
  border: 1px solid rgba(137, 152, 189, 0.2);
  border-radius: 8px;
  padding: 8px 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #199a8e;
  float: right;
}

.health_package_lab_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.health_package_lab_text_hr {
  border-bottom: 1px solid #eceff4;
}

.health_package_lab_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  text-align: right;
  margin-bottom: 0;
}

.health_package_radiological_test_box {
  padding: 24px 0;
}

.health_package_radiological_test_box_inner {
  display: inline-flex;
  column-gap: 16px;
}

.health_package_radiological_test_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 5px;
  margin-top: 15px;
}

.health_package_radiological_test_title_2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0px;
  margin-top: 5px;
}

.health_package_radiological_test_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 5px;
}

.instructions_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #424448;
  margin-top: 10px;
  margin-bottom: 0;
}

.main {
  float: left;
}

.search {
  position: relative;
  margin: 0 auto;
  width: 300px;
}

.search input {
  background: #f0f0f4;
  border-radius: 14px;
  width: 403px;
  height: 53px;
  border: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  padding: 0px 14px 0px 50px;
}

.search input:focus {
  outline: none;
  border: none;
}

.search input:focus+.results {
  display: block;
}

.search .results {
  display: none;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  margin: 0;
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  width: 403px;
  padding: 30px 16px;

  overflow-y: scroll;
  height: 400px;
}

.search .results li {
  display: block;
}

.search .results li:first-child {
  margin-top: -1px;
}

.search .results li:first-child:before,
.search .results li:first-child:after {
  display: block;
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  margin-left: -5px;
  border: 5px outset transparent;
}

.search .results li:first-child:hover:before,
.search .results li:first-child:hover:after {
  display: none;
}

.search .results li:last-child {
  margin-bottom: -1px;
}

.search .results a {
  display: block;
  position: relative;
  margin: 0 -1px;
  padding: 6px 40px 6px 10px;
  color: #808394;
  font-weight: 500;
  text-shadow: 0 1px #fff;
  border: 1px solid transparent;
  border-radius: 3px;
}

.search .results a span {
  font-weight: 200;
}

.search .results a:hover {
  text-decoration: none;
  color: #fff;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.3);
  border-color: #2380dd #2179d5 #1a60aa;
  background-color: #338cdf;
  background-image: -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, #59aaf4),
      color-stop(100%, #338cdf));
  background-image: -webkit-linear-gradient(top, #59aaf4, #338cdf);
  background-image: -moz-linear-gradient(top, #59aaf4, #338cdf);
  background-image: -ms-linear-gradient(top, #59aaf4, #338cdf);
  background-image: -o-linear-gradient(top, #59aaf4, #338cdf);
  background-image: linear-gradient(top, #59aaf4, #338cdf);
  -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2),
    0 1px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2),
    0 1px rgba(0, 0, 0, 0.08);
  -ms-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2),
    0 1px rgba(0, 0, 0, 0.08);
  -o-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
}

.lab_test_modal_box {
  --bs-modal-width: 476px;
}

.lab_test_modal_box_head_padding {
  padding: 30px 34px 10px 34px;
}

.lab_test_modal_box_body_padding {
  padding: 0 34px;
}

.lab_test_modal_box_inner_1 {
  padding: 0 20px 20px 20px;
}

.lab_test_modal_box_inner_2 {
  padding: 0 20px;
}

.lab_test_popup_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
}

.lab_test_popup_box {
  padding: 0 0px;
  overflow-y: scroll;
  height: 30vh;
}

.lab_test_popup_close {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
}

.healthpackage {
  margin-bottom: -10px;
  margin-top: 7px;
}

.health_checkbox {
  margin-bottom: -10px;
  margin-left: -12px;
}

.healthpackage input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.healthpackage label {
  position: relative;
  cursor: pointer;
}

.healthpackage label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #c4c4c4;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 14px;
  border-radius: 5px;
  /* margin-top: -4px; */
}

.healthpackage input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  /* top: 29px; */
  left: 7px;
  width: 6px;
  height: 10px;
  border: 0.4px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.healthpackage_modal_box {
  justify-content: space-evenly;
  display: flex;
}

.healthpackage_modal_box_inner {
  display: inline-flex;
  column-gap: 210px;
}

.healthpackage_modal_box_inner_2 {
  display: inline-flex;
  column-gap: 225px;
}

.vh {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.healthpackage input[type="checkbox"]:checked~label:before {
  background: #199a8e no-repeat center;
  background-size: 12px 12px;
  border: 1px solid #199a8e;
  border-radius: 5px;
}

.healthpackage input[type="checkbox"]:focus~label {
  color: #20bf6b;
}

.healthpackage_checkbox_box {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
}

.healthpackage_checkbox_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px !important;
  line-height: 24px;
  color: #424448 !important;
}

.healthpackage_checkbox_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.healthpackage_modal_footer_box {
  background: #ffffff;
  box-shadow: 0px -3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 0px 0px 16px 16px;
  border-top: none;
  justify-content: space-between;
  display: block;
  padding: 10px 35px;
}

.lab_test_popup_box::-webkit-scrollbar {
  width: 4px !important;
  background: #ffffff !important;
  height: 10px;
}

.lab_test_popup_box::-webkit-scrollbar-track {
  background: #d9d9d9 !important;
  border-radius: 100px;
}

.lab_test_popup_box::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 5px;
}

.lab_test_popup_box::-webkit-scrollbar-thumb:hover {
  background: #ffffff !important;
}

.lab_test_modal_footer_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 4px;
}

.lab_test_modal_footer_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.lab_test_modal_footer_btn {
  background: #199a8e;
  border-radius: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 13px 48px 13px 48px;
  color: #ffffff;
}

.lab_test_modal_footer_inner {
  display: flex;
  justify-content: space-between;
}

.lab_test_modal_box_2 {
  padding: 0 20px;
}

.lab_member_box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.lab_member_box_inner {
  display: flex;
  justify-content: space-between;
  width: -webkit-fill-available;

}

.lab_member_box input[type="radio"] {
  opacity: 1;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
}

.lab_member_box [type="radio"]:checked,
.lab_member_box [type="radio"]:not(:checked) {
  position: absolute;
  width: 26px;
  height: 26px;
  margin-left: -27px;
}

.lab_member_box [type="radio"]:checked+label,
.lab_member_box [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.lab_member_box [type="radio"]:checked+label:before,
.lab_member_box [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.lab_member_box [type="radio"]:checked+label:after,
.lab_member_box [type="radio"]:not(:checked)+label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #199a8e;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.lab_member_box [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.lab_member_box [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.lab_member_modal_btn {
  background: #199a8e;
  border-radius: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  padding: 13px 48px 13px 48px;
  margin-top: 30px;
}

/*---------------------------------------------------------------Health Package Screen End---------------------------------------------------------------*/
/*---------------------------------------------------------------Lab Test Cart Screen Start---------------------------------------------------------------*/
.lab_test_cart_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.select_member_box_3 {
  margin-bottom: 15px;
  margin-left: 50px;
  margin-right: 10px;
}

.cart_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #424448;
  /*margin-top: 20px;*/
  margin-bottom: 20px;
}

.lab_test_cart_box {
  background: #ffffff;
  border-radius: 12px 12px;
}

.lab_test_cart_box_inner {
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
}

.lab_test_cart_left_side {
  background: #fff;
  padding: 24px;
  border-radius: 12px 0px 0px 0px;
}

.lab_test_cart_left_side_2 {
  background: #fff;
  padding: 24px;
  border-radius: 0px 0px 0px 0px;
  border-top: 1px solid #eceff4;
}

.lab_test_cart_right_side {
  background: #fff;
  padding: 24px;
  border-radius: 0px 12px 0px 0px;
  border-left: 1px solid #eceff4;
}

.lab_test_cart_left_side_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3e4046;
  margin-bottom: 0;
  margin-top: 4px;
}

.lab_test_add_test_btn {
  background: #ffffff;
  border: 1px solid rgba(137, 152, 189, 0.2);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #199a8e;
  float: right;
}

.lab_test_for_toptitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
}

.lab_test_for_test_box {
  display: flex;
}

.lab_test_for_test_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.lab_test_for_test_icon {
  width: 12px;
  height: 12px;
  margin-top: 5px;
  margin-right: 13px;
}

.lab_test_for_test_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.lab_test_for_test_hr {
  border-bottom: 1px solid #eceff4;
}

.lab_test_address {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3e4046;
  margin-bottom: 15px;
}

.lab_test_address_box {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 14px;
  cursor: pointer;
  border: 1px solid #e2e2e6;
}

.lab_test_address_box_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  margin-bottom: 0;
}

.lab_test_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3e4046;
  margin-bottom: 15px;
}

.lab_test_time_box {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 14px;
  cursor: pointer;
  border: 1px solid #e2e2e6;
}

.lab_test_time_box_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  margin-bottom: 0;
}

.lab_test_left_padding {
  padding-left: 0;
}

.lab_test_right_padding {
  padding-right: 0;
}

.lab_T_C_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);*/
  border-radius: 14px;
  padding: 17px 14px;
  margin-bottom: 40px;
  border: 1px solid #e2e2e6;
}

.lab_test_footer_card {
  background: #ffffff;
  box-shadow: 0px -6px 21px rgba(4, 29, 66, 0.06);
  border-radius: 0px 0px 12px 12px;
  padding: 15px 10px;
  justify-content: end;
  display: flex;
  /*margin-top: 55px;*/
}

.lab_test_card_box {
  display: inline-flex;
  column-gap: 28px;
}

.lab_test_price_box {
  margin-top: 10px;
}

.lab_test_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
}

.lab_test_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
  margin-bottom: 0;
}

.lab_test_btn {
  background: #199a8e;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  padding: 17px 45px 17px 45px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fbfcfc;
}

.lab_test_btns {
  background: #fbfcfc;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  padding: 17px 45px 17px 45px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #199a8e;
  border: 1px solid #199a8e;
}



.modal_bg_texts {
  padding-top: 20px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0px;
  text-align: center;
  color: #f0f0f4;
}

.modal_bg_textes {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0px;
  text-align: center;
  color: #f0f0f4;
}

.lab_sample_collection_add_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 15px;
}

.lab_sample_collection_add_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 15px;
}

.lab_sample_collection_add_title_2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 10px;
}

.lab_sample_collection_add_para_2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 10px;
}

.lab_add_member_box {
  margin-left: 15px;
}

.lab_add_tag {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  float: right;
  margin-top: -37px;
  margin-right: 11px;
}

.lab_sample_book_date_slots_active {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #15181e;
  margin-bottom: 0;
}

.lab_sample_book_date_slots {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #199a8e;
  margin-bottom: 0;
}

.lab_sample_apply_coupon_box_header {
  padding: 30px 20px 10px 20px;
}

.lab_sample_apply_coupon_box {
  padding: 0px 10px;
}

.lab_sample_apply_coupon_input {
  background: #f0f0f4;
  border-radius: 14px;
  padding: 15px 80px 15px 14px;
  border: none;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
}

.lab_sample_apply_coupon_apply {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  align-items: start;
  text-align: right;
  color: #199a8e;
  margin-top: 15px;
  float: right;
  position: absolute;
  margin-left: -60px;
  cursor: pointer;
}

.lab_sample_apply_coupon_list_box {
  padding: 24px 0 0 0;
}

.lab_sample_apply_coupon_list {
  display: flex;
  justify-content: space-between;
}

.lab_sample_apply_coupon_list_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #df6129;
  margin-bottom: 6px;
}

.lab_sample_apply_coupon_list_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
  margin-bottom: 0;
}

.lab_sample_apply_coupon_list_title_apply {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: right;
  color: #199a8e;
  margin-bottom: 0;
  margin-top: 15px;
  cursor: pointer;
}

.lab_sample_apply_coupon_list_hr {
  border-bottom: 0.8px dashed #909196;
}

.lab_test_date_time_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 10px;
}

.lab_test_change_add {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #df6129;
  margin-bottom: 0;
  margin-top: 10px;
  margin-left: 10px;
}

.lab_test_change_add_icon {
  margin-top: -4px;
  margin-right: 5px;
}

/*----------------------------------------------------------------Lab Test Cart Screen End----------------------------------------------------------------*/
/*--------------------------------------------------------------Lab Test Cart Payment Gateway Screen Start----------------------------------------------------------------*/
.lab_test_cart_payment_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.lab_test_cart_payment_box {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
}

.lab_test_cart_payment_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  align-items: center;
  color: #000000;
  padding: 260px 0;
}

.payment_done_card_box {
  background: #ffffff;
  border-radius: 14px;
  padding: 14px 19px;
}

.payment_done_test_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 8px;
  margin-top: 10px;
}

.payment_done_book {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
  margin-bottom: 0;
}

.payment_done_book_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  text-align: right;
  margin-bottom: 0;
}

.payment_done_payment_info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
}

.payment_done_hr {
  border-bottom: 0.8px dashed #909196;
}

.payment_done_payment_info_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
  margin-bottom: 5px;
}

.payment_done_payment_info_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  text-align: right;
  margin-bottom: 5px;
}

.payment_done_popup_modal_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 0;
}

/*---------------------------------------------------------------Lab Test Cart Payment Gateway Screen End-----------------------------------------------------------------*/
/*---------------------------------------------------------------Health Package Radiological Screen Start-----------------------------------------------------------------*/
.health_radiological_popup_list_box {
  display: flex;
}

.health_radiological_popup_close {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
  margin-top: -50px;
}

.health_radiological_popup_list_box_inner {
  display: flex;
  column-gap: 20px;
}

.health_radiological_popup_box {
  padding: 0 0px;
  overflow-y: scroll;
  height: 47.5vh;
  overflow-x: hidden;
}

.health_radiological_popup_box_body {
  padding: 16px 16px 0 16px;
}

.healthradiological {
  margin-bottom: -10px;
  margin-top: 7px;
}

.healthradiological input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.healthradiological label {
  position: relative;
  cursor: pointer;
}

.healthradiological label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #c4c4c4;
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 14px;
  border-radius: 5px;
  margin-top: 30px;
}

.healthradiological input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 33px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: 0.4px solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.vh {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.healthradiological input[type="checkbox"]:checked~label:before {
  background: #199a8e no-repeat center;
  background-size: 12px 12px;
  border: 1px solid #199a8e;
  border-radius: 5px;
}

.healthradiological input[type="checkbox"]:focus~label {
  color: #20bf6b;
}

.healthradiological_input {
  margin-top: 26.5px;
}



.healthradiological_star_box {
  display: flex;
  column-gap: 5px;
}

.healthradiological_star_icon {
  margin-top: -5px;
  margin-right: 3px;
}

.healthradiological_star_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.healthradiological_star_review {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #909196;
  margin-bottom: 0;
  margin-top: 2px;
}

.healthradiological_radiological_test_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 5px;
  margin-top: 15px;
}

.modal_747 {
  --bs-modal-width: 747px;
}

.modal_476 {
  --bs-modal-width: 476px;
}

.healthradiological_modal_box_head {
  padding: 30px 34px 0px 34px;
}

.healthradiological_modal_box_body {
  padding: 0px 5px;
}

.healthradiological_modal_box_body_inner {
  padding: 0px 34px;
}

.healthradiological_modal_box_hr {
  border-bottom: 1px solid #eceff4;
  margin-top: 20px;
  margin-bottom: 20px;
}

.healthradiological_tab_details_box {
  display: flex;
  justify-content: space-between;
}

.healthradiological_tab_details_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 20px;
}

.healthradiological_tab_details_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
}

.healthradiological_tab_details_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
}

.tab_details_view_map_btn {
  background: #ffffff;
  border: 1.5px solid #e2e2e6;
  border-radius: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  padding: 10px 19.5px 10px 19.5px;
  margin-top: 33px;
}

.tab_details_review_box {
  display: flex;
  column-gap: 10px;
}

.tab_details_review_box_inner {
  display: flex;
  justify-content: space-between;
}

.tab_details_review_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 6px;
}

.tab_details_review_date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #909196;
}

.tab_details_review_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.tab_details_review_scroll_box {
  padding: 0 0px;
  overflow-y: scroll;
  height: 40vh;
}

.tab_details_profile_bg_1 {
  background: #ffcba5;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #424448;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  padding: 5px 1px;
}

.tab_details_profile_bg_2 {
  background: #a5d9ff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #424448;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  padding: 5px 1px;
}

.select_radiology_img {
  width: auto;
}

.tab_details_head_img {
  width: auto;
}

/*----------------------------------------------------------------Health Package Radiological Screen End------------------------------------------------------------------*/
/*----------------------------------------------------------------Health Radiological cart Screen Start------------------------------------------------------------------*/
.healthradio_cart_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.view_map_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  margin-bottom: 0;
  margin-left: 10px;
  margin-top: 5px;
}

.healthradio_center_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.healthradio_center_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.healthradio_center_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
}

.healthradio_center_title_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.healthradio_center_title_add {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

/*-----------------------------------------------------------------Health Radiological cart Screen End-------------------------------------------------------------------*/
/*-----------------------------------------------------------------Lab Testing Package Screen Start-------------------------------------------------------------------*/
.fullbody_package_box {
  display: flex;
  justify-content: space-between;
}

.fullbody_package_box_inner {
  display: inline-flex;
  column-gap: 17px;
}

.fullbody_package_img {
  background: #f2f2f2;
  border-radius: 8px;
  width: 80px;
  height: 80px;
}

.package_detail_img {
  height: 100px;
  width: 100px;
  background: #f2f2f2;
  border-radius: 8px;
}

.fullbody_package_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 5px;
}

.fullbody_package_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #424448;
  margin-bottom: 5px;
}

.fullbody_package_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 5px;
}

.fullbody_package_btn {
  background: #199a8e;
  border: 1px solid rgba(137, 152, 189, 0.2);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  color: #ffffff;
  padding: 8px 16px;
  margin-top: 20px;
}

.fullbody_package_hr {
  border-bottom: 1px solid #eceff4;
}

.fullbody_package_scroll_box {
  padding: 0 20px;
  overflow-y: scroll;
  height: 60vh;
}

.fullbody_package_scroll_paratitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #df6129;
}

.fullbody_package_scroll_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
}

.fullbody_package_scroll_test_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 20px;
}

.fullbody_package_scroll_test_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
}

.fullbody_package_scroll_test_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
}

.modal_415 {
  --bs-modal-width: 415px;
}

/*------------------------------------------------------------------Lab Testing Package Screen End------------------------------------------------------------------*/
/*------------------------------------------------------------------Wallet Screen Start------------------------------------------------------------------*/
.wallet_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.wallet_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.wallet_body_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 8px;
}

.wallet_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 0 0 12px 12px;
  padding: 25px;
  height: 90%;
}

.wallet_balance_card {
  background: rgba(25, 154, 142, 0.12);
  border-radius: 14px;
  padding: 24px 14px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}

.wallet_balance_card_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-top: 4px;
}

.wallet_balance_card_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #199a8e;
  margin-bottom: 0;
}

.wallet_card_box {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 20px;
  border: 1px solid #e2e2e6;
}

.wallet_card_head {
  background: #fff;
  border-radius: 20px 20px 0px 0px;
  padding: 12px 15px;
  border-bottom: 1px solid #eceff4;
}

.wallet_transaction_id {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
  margin-bottom: 0;
}

.wallet_card_body {
  background: #ffffff;
  border-radius: 0 0 20px 20px;
  padding: 14px;
}

.wallet_card_body_box {
  margin-top: 0px;
}

.wallet_card_body_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 7px;
}

.wallet_card_body_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 5px;
}

.wallet_card_body_for {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #df6129;
  margin-bottom: 0;
}

/*-------------------------------------------------------------------Wallet Screen End-------------------------------------------------------------------*/
/*------------------------------------------------------------------Address Screen Start------------------------------------------------------------------*/
.address_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.address_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.address_body_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-top: 10px;
  margin-bottom: 10px;
}

.address_body_card_head_new_add {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #df6129;
  margin-bottom: 0;
  margin-top: 15px;
  float: right;
}

.address_body_card_head_add_icon {
  margin-right: 12px;
  margin-top: -3px;
}

.address_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 0 0 12px 12px;
  padding: 25px;
  height: 90%;
}

.address_box_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 20px;
  padding: 20px 14px;
  border: 1px solid #e2e2e6;
}

.address_box_card_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.address_box_card_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.address_box_card_btn_box {
  display: inline-flex;
  column-gap: 30px;
  margin-top: 10px;
}

.address_box_card_add_edit {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #199a8e;
  margin-bottom: 0;
}

.address_box_card_add_delete {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #db202b;
  margin-bottom: 0;
}

/*-------------------------------------------------------------------Address Screen End-------------------------------------------------------------------*/
/*------------------------------------------------------------------My Subscription Screen Start------------------------------------------------------------------*/
.my_subscription_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.my_subscription_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.my_subscription_body_box_card_heads {
  background: #ffffff;
  box-shadow: 0px 3px 21px 0px #041D420F;
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.my_subscription_body_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-top: 10px;
  margin-bottom: 10px;
}

.my_subscription_body_card_head_new_add {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #df6129;
  margin-bottom: 0;
  margin-top: 15px;
  float: right;
}

.my_subscription_body_card_head_add_icon {
  margin-right: 12px;
  margin-top: -3px;
}

.my_subscription_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 0 0 12px 12px;
  padding: 25px;
  height: 90%;
}

.my_subscription_box_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 20px;
  padding: 20px 14px;
  border: 1px solid #e2e2e6;
}

.my_subscription_card_inner {
  display: flex;
  justify-content: space-between;
}

.my_subscription_card_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 10px;
}

.my_subscription_card_desc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 10px;
}

.my_subscription_card_member {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  margin-bottom: 0;
}

.my_subscription_card_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 10px;
}

.my_subscription_card_consultation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #db202b;
  margin-bottom: 10px;
}

.my_subscription_card_validation {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0px;
}

/*-------------------------------------------------------------------My Subscription Screen End-------------------------------------------------------------------*/
/*------------------------------------------------------------------Subscription Details Screen Start------------------------------------------------------------------*/
.subscription_package_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.subscription_package_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #424448;
  /*margin-top: 20px;*/
  margin-bottom: 20px;
}

.subscription_package_card {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  padding: 30px;
}

.subscription_card_details {
  display: flex;
  justify-content: space-between;
}

.header_card {
  color: #424448;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.header_desc {
  color: #424448;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.header_count {
  color: #199A8E;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.header_consult {
  color: #DB202B;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.subscription_package_card_hr {
  border-bottom: 0.8px dashed #909196;
}

.subscription_title {
  color: #15181E;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.subscription_package_members_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.subscription_package_members_profile_box {
  display: flex;
  column-gap: 25px;
}

.subscription_package_members_profile_box_inner {
  display: flex;
  column-gap: 10px;
}

.subscription_package_members_box_col1_member_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 20px;
  margin-top: 5px;
}

.subscription_package_members_profile_d {
  background: #ffd0a5;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 6px;
  border-radius: 30px;
  width: 36px;
  height: 36px;
}

.subscription_package_members_profile_m {
  background: #8af1f1;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 4px;
  border-radius: 30px;
  width: 36px;
  height: 36px;
}

.subscription_package_members_profile_b {
  background: #ffa5fb;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 6px;
  border-radius: 30px;
  width: 36px;
  height: 36px;
}

.subscription_package_members_profile_j {
  background: #a5c9ff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 5px;
  border-radius: 30px;
  width: 36px;
  height: 36px;
}

.subscription_package_members_profile_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 6px;
}

.subscription_package_members_profile_remove {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.subscription_package_body_card_add {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #df6129;
  margin-bottom: 0;
  float: right;
  margin-top: 5px;
}

.subscription_package_body_card_add_icon {
  margin-right: 10px;
  margin-top: -3px;
}

.subscription_package_payment_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #15181e;
}

.subscription_package_payment_summary_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
}

.subscription_package_payment_summary_discount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
}

.center_img {
  align-self: center;
}

.main_div {
  width: 308px;
}

.same_div {
  display: flex;
  justify-content: space-between;
}

.subscription_package_payment_summary_discount_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #199a8e;
}

.subscription_package_payment_summary_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
}

.subscription_package_payment_summary_status {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #199a8e;
}

.subscription_package_consultation_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
}

.subscription_package_consultation_card_head {
  background: #199a8e;
  border-radius: 20px 20px 0px 0px;
  padding: 12px 15px;
}

.subscription_package_consultation_card_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 0;
}

.subscription_package_consultation_card_head_icon {
  margin-right: 13px;
  margin-top: -2px;
  height: 20px;
  align-self: center;
  align-items: center;
  width: 20px;
}

.subscription_package_consultation_card_body {
  background: #ffffff;
  box-shadow: 0px 3px 21px 0px rgba(4, 29, 66, 0.06);
  border-radius: 0 0 20px 20px;
  padding: 14px;
  /* border: 1px solid #e2e2e6; */
}

.subscription_package_box {
  display: inline-flex;
  column-gap: 10px;
}

.subscription_package_box_toptitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #67696c;
  margin-bottom: 0;
}

.subscription_package_box_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-top: 3px;
  line-height: 24px;
  color: #15181e;
}

.subscription_package_box_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding-top: 10px !important;
  color: #424448;
  margin-bottom: 0;
}

.subscription_package_box_endtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
  margin-bottom: 0;
}

.subscription_package_box_doc_img {
  width: 85px;
  height: 96px;
  display: inherit;
}

.subscription_package_option_box {
  display: inline-flex;
  float: right;
  margin-top: 33px;
}

.discription_under {
  display: flex;
}

.desc_second {
  margin-left: 10px;
  align-items: center;
  align-self: center;
}

.desc_second p {
  margin: 0 !important;
}

.subscription_package_box_inner_1 {
  border-right: 1px solid #e2e2e6;
  padding-right: 22px;
}

.subscription_package_box_inner_2 {
  padding-left: 22px;
  padding-right: 22px;
}

.main_description {
  display: flex;
  justify-content: space-between;
}

.second_description {
  align-self: center;
  display: flex;
}

.subscription_package_cancel_appointment {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #db202b;
  cursor: pointer;
}

.or_sub {
  color: #e2e2e6;
  margin: 0 30px;
}

.subscription_package_cancel_icon {
  margin-right: 5px;
  margin-top: -3px;
  align-self: center;
  cursor: pointer;
}

.subscription_package_reschedule_icon {
  margin-right: 5px;
  cursor: pointer;
}

.subscription_package_reschedule_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  cursor: pointer;
}

.subscription_package_consultation_box {
  /*box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);*/
  border-radius: 20px;
  border: 1px solid #e2e2e6;
}

.subscription_package_consultation_complete_head {
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 3px 21px 0px rgba(4, 29, 66, 0.06);

  padding: 5px 15px;
  border-bottom: 1px solid #eceff4;
}

.second_div_desc {
  align-self: center;
}

.second_div_desc p {
  margin: 0 !important;
}

.rate_title {
  color: #15181E;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.subscription_package_consultation_complete_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #199a8e;
  margin-bottom: 0;
}

.star {
  margin-right: 5px;
}

.subscription_package_consultation_complete_body {
  background: #ffffff;
  border-radius: 0 0 20px 20px;
  padding: 14px;
}

.subscription_package_consultation_cancel_head_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #db202b;
  margin-bottom: 0;
}

.subscription_package_rating_star_box {
  float: right;
  margin-top: 20px;
  margin-right: 26px;
}

/*-------------------------------------------------------------------Subscription Details Screen End-------------------------------------------------------------------*/
/*------------------------------------------------------------------Follow Up Consultation Screen Start------------------------------------------------------------------*/
.follow_up_consult_width {
  width: 413px;
  border-radius: 20px;
  border: 1px solid #e2e2e6;
}

.follow_up_consultation_box {
  padding: 45px 0 0 0;
}

.follow_up_consultation_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  letter-spacing: 0.3px;
  color: #424448;
  margin-bottom: 40px;
  position: relative;
}

.follow_up_consultation_title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  /* Adjust the space below the text */
  left: 0;
  height: 4px;
  /* Adjust the height of the underline */
  width: 70px;
  /* Adjust the width of the underline */
  background-color: #199A8E;
  /* Adjust the color of the underline */
}

.follow_up_consultation_titles {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  letter-spacing: 0.3px;
  color: #424448;
  margin-bottom: 40px;
  position: relative;
}

.follow_up_consultation_titles::after {
  content: "";
  position: absolute;
  bottom: -10px;
  /* Adjust the space below the text */
  left: 45%;
  height: 4px;
  /* Adjust the height of the underline */
  width: 70px;
  /* Adjust the width of the underline */
  background-color: #199A8E;
  /* Adjust the color of the underline */
}


.follow_up_upcoming_consultation_box {
  display: flex;
  column-gap: 24px;
}

.follow_up_upcoming_consultation_box_head {
  background: #199a8e;
  border-radius: 20px 20px 0px 0px;
  padding: 12px 15px;
}

.follow_up_upcoming_consultation_box_doc_img {
  width: 85px;
}

.follow_up_upcoming_consultation_box_head_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 0;
}

.follow_up_upcoming_consultation_box_body {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 0 0 20px 20px;
  padding: 14px;
}

.follow_up_upcoming_option_box {
  display: inline-flex;
  float: left;
  margin-top: 33px;
}

.follow_up_upcoming_box_inner_1 {
  border-right: 1px solid #e2e2e6;
  padding-right: 22px;
}

.follow_up_upcoming_box_inner_2 {
  padding-left: 22px;
  padding-right: 22px;
}

.follow_up_upcoming_cancel_appointment {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #db202b;
  cursor: pointer;
}

.follow_up_upcoming_cancel_icon {
  margin-right: 5px;
  margin-top: -3px;
  cursor: pointer;
}

.follow_up_upcoming_reschedule_icon {
  margin-right: 5px;
  cursor: pointer;
}

.follow_up_upcoming_reschedule_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  cursor: pointer;
}

.follow_up_upcoming_followup_box {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 22px;
}

.follow_up_upcoming_followup_box_head {
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  padding: 12px 15px;
  border-bottom: 1px solid #eceff4;
}

.follow_up_upcoming_followup_box_head_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  margin-bottom: 0;
}

.follow_up_upcoming_followup_box_inner {
  margin-top: 33px;
  display: flex;
  justify-content: space-between;
}


.follow_up_upcoming_followup_box_body {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 0 0 20px 20px;
  padding: 14px;
}

.follow_up_schedule_followup_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #df6129;
  margin-bottom: 0;
  cursor: pointer;
}

.follow_up_schedule_followup_icon {
  margin-right: 7px;
  margin-top: -3px;
}

.follow_up_schedule_remove_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
  margin-bottom: 0;
  cursor: pointer;
}

.follow_up_test_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  letter-spacing: 0.3px;
  color: #424448;
  /*margin-bottom: 40px;*/
}

.follow_up_test_box {
  padding: 45px 0 45px 0;
}

.follow_up_test_width {
  width: 375px;
  /* box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06); */
}
.follow_up_test_widths {
  width: 280px;
  /* box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06); */
}

.follow_up_test_box_card {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  padding: 16px;
  /* border: 1px solid #e2e2e6; */
}

.follow_up_test_box_tag {
  background: rgba(223, 97, 41, 0.44);
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  padding: 5.5px 12px;
}

.follow_up_test_box_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-top: 9px;
  margin-bottom: 5px;
}

.follow_up_test_for_client {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.follow_up_test_by_doc {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
}

.follow_up_test_time_status_box {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.follow_up_test_time_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424448;
  margin-bottom: 0;
}

.follow_up_test_time {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
}

.follow_up_test_status {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #424448;
  margin-bottom: 0;
}

.follow_up_test_status_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #df6129;
}

/*-------------------------------------------------------------------Follow Up Consultation Screen End-------------------------------------------------------------------*/
/*---------------------------------------------------------------Follow Up Subscription Plan Screen Start----------------------------------------------------------------*/
.follow_up_subscription_plan_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.follow_up_subscription_plan_head {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #424448;
  margin-top: 20px;
  margin-bottom: 20px;
}

.follow_up_subscription_plan_box {
  background: #ffffff;
  padding: 20px 16px 20px 16px;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  height: 100%;
}

.follow_up_subscription_plan_ul {
  padding: 0px;
  margin-bottom: 0;
}

.follow_up_subscription_plan_option_bg {
  background: #ffffff;
  border-radius: 12px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.follow_up_subscription_plan_option_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #909196;
}

.follow_up_subscription_plan_tab_option_text_active {
  color: #199a8e;
}

.follow_up_subscription_plan_tab_option_active_bg {
  background: rgba(25, 154, 142, 0.11);
  border-radius: 8px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.follow_up_subscription_plan_body_box_card_head {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px 12px 0 0;
  padding: 12px 21px;
  border-bottom: 1px solid #eceff4;
}

.follow_up_subscription_plan_body_box_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin-bottom: 0;
}

.follow_up_subscription_plan_body_box_card_body {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 0px 0px 0px 0px;
  padding: 0 25px;
}

.follow_up_subscription_plan_body_box {
  padding: 0;
  display: flex;
}

.follow_up_subscription_plan_inner_1 {
  width: 50%;
  height: 100%;
  padding: 30px 20px 30px 0;
}

.follow_up_subscription_plan_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);*/
  border-radius: 14px;
  padding: 14px;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid #e2e2e6;
}

.follow_up_subscription_plan_card_active {
  background: #fcefe6;
  border: 1px solid #df6129;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  cursor: pointer;
}

.follow_up_subscription_plan_box_inner {
  width: auto;
}

.follow_up_subscription_plan_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 0;
}

.follow_up_subscription_plan_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
  margin-bottom: 0;
}

.follow_up_subscription_plan_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
  margin-top: 5px;
}

.follow_up_subscription_plan_title_2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #3e4046;
  margin-top: 25px;
}

.follow_up_subscription_plan_consult_card {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 14px;
  padding: 17px 20px 17px 14px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.follow_up_subscription_plan_consult_card_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 0;
  color: #424448;
}

.follow_up_subscription_plan_consult_card_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #909196;
}

.follow_up_subscription_plan_inner_2 {
  width: 50%;
  height: 100%;
  padding: 30px 0px 30px 20px;
  border-left: 1px solid #eceff4;
}

.follow_up_subscription_plan_offer_benifit_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #424448;
  margin-bottom: 0;
}

.follow_up_subscription_plan_offer_benifit_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);*/
  border-radius: 14px;
  padding: 17px 20px 17px 14px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
  cursor: pointer;
  border: 1px solid #e2e2e6;
}

.follow_up_subscription_plan_apply_coupon_box {
  display: inline-flex;
}

.follow_up_subscription_plan_payment_summary {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3e4046;
}

.follow_up_subscription_plan_payment_summary_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 14px 17px 14px;
  border: 1px solid #e2e2e6;
}

.follow_up_subscription_plan_payment_summary_card_inner {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.follow_up_subscription_plan_payment_summary_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.follow_up_subscription_plan_payment_summary_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.follow_up_subscription_plan_payment_summary_price_discount {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #48988e;
  margin-bottom: 0;
}

.follow_up_subscription_plan_payment_summary_total_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.follow_up_subscription_plan_payment_summary_total_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: #424448;
  margin-bottom: 0;
}

.follow_up_subscription_plan_wallet_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 14px;
  margin-top: 8px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  border: 1px solid #e2e2e6;
}

.follow_up_subscription_plan_wallet_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
  line-height: 24px;
  color: #df6129 !important;
  margin-bottom: 0;
}

.follow_up_subscription_plan_wallet_subtext {
  margin-top: 10px;
  margin-left: 5px;
}

.follow_up_subscription_plan_available_bal_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #909196;
  margin-bottom: 0;
}

.follow_up_subscription_plan_available_bal_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.follow_up_subscription_plan_T_C_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3e4046;
  margin-bottom: 15px;
}

.follow_up_subscription_plan_T_C_card {
  background: #ffffff;
  /*box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);*/
  border-radius: 14px;
  padding: 17px 14px;
  margin-bottom: 30px;
  border: 1px solid #e2e2e6;
}

.follow_up_subscription_plan_T_C_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 10px;
}

.follow_up_subscription_plan_view_T_C_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #199a8e;
  margin-bottom: 0;
}

.follow_up_subscription_plan_book_consult_card {
  background: #ffffff;
  box-shadow: 0px -6px 21px rgba(4, 29, 66, 0.06);
  border-radius: 0px 0px 10px 10px;
  padding: 15px 10px;
  justify-content: end;
  display: flex;
}

.follow_up_subscription_plan_book_consult_card_box {
  display: inline-flex;
  column-gap: 28px;
}

.follow_up_subscription_plan_book_consult_price_box {
  margin-top: 8px;
}

.follow_up_subscription_plan_book_consult_price {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
  margin-bottom: 0;
}

.follow_up_subscription_plan_book_consult_subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
  margin-bottom: 0;
}

.follow_up_subscription_plan_book_consult_btn {
  background: #199a8e;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  padding: 17px 45px 17px 45px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fbfcfc;
}

/*----------------------------------------------------------------Follow Up Subscription Plan Screen End-----------------------------------------------------------------*/
/*---------------------------------------------------------------Buy Medicine Screen Start----------------------------------------------------------------*/
.buy_medicine_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.buy_medicine_card_bg {
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  background: #fff;
}

.buy_medicine_chat_card_head {
  background: #ffffff;
  border-radius: 12px 0px 0 0;
  padding: 24px 24px;
  border-bottom: 1px solid #eceff4;
  border-right: 1px solid #eceff4;
}

/*----------------------------------------------------------------Buy Medicine Screen End-----------------------------------------------------------------*/
/*---------------------------------------------------------------Buy Medicine Screen Start----------------------------------------------------------------*/
.buy_medicines_cart_left_side_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3e4046;
  margin-bottom: 0;
  margin-top: 4px;
}

.buy_medicine_box {}

.buy_medicine_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.buy_medicine_strip_of {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
  margin-bottom: 0;
}

.buy_medicine_right_detail {
  float: right;
  display: flex;
}

.quantity_field {
  display: flex;
  border: 1px solid #e2e2e6;
  border-radius: 9px;
  width: 78px;
  height: 35px;
}

.decrease_button {
  font-size: 20px;
  line-height: 0px;
  height: 33px;
  width: 30px;
  padding: 10px;
  color: #199a8e;
  background: #ffffff;
}

.number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 5px 5px 5px;
}

.increase_button {
  font-size: 20px;
  line-height: 0px;
  height: 33px;
  width: 30px;
  padding: 10px;
  color: #199a8e;
  background: #ffffff;
}

.buy_medicine_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  color: #424448;
  padding-left: 15px;
  margin-bottom: 0;
}

/*----------------------------------------------------------------Buy Medicine Screen End-----------------------------------------------------------------*/
/*----------------------------------------------------------------Buy Medicine cart active start-----------------------------------------------------------------*/
.orderplaced_medicines_ordered_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #15181e;
}

.medicine_order_hr {
  border: 0.8px dashed #909196;
}

.buy_medicine_hr {
  border: 0.5px solid #e2e2e6;
}

.order_placed {
  display: flex;
  justify-content: space-between;
}

.order_placed_on_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #67696c;
}

.order_placed_on_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
}

/*----------------------------------------------------------------Buy Medicine cart active End-----------------------------------------------------------------*/
/*----------------------------------------------------------------Medicine page start-----------------------------------------------------------------*/
.medicine_container {
  background: #f5f5f5;
  padding: 30px 0px 120px 0;
}

.medicine_card_box {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 12px;
  padding: 16px 14px;
}

.medicine_card {
  background: #ffffff;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  padding: 14px;
  margin-top: 20px;
}

.medicine_img {
  width: 100%;
  border-radius: 6px;
}

.medicine_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-top: 40px;
}

.medicine_mrp {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
}

.medicine_mrp_through_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: line-through;
  color: #67696c;
  padding-left: 10px;
}

.medicine_mrp_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #424448;
  padding-left: 10px;
}

.add_btn {
  background: #199a8e;
  border-radius: 9px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding: 3px 20px;
}

.medicine_footer {
  background: #ffffff;
  box-shadow: 0px -6px 21px rgba(4, 29, 66, 0.06);
  border-radius: 0px 0px 10px 10px;
  padding: 15px 10px;
  width: 50%;
  bottom: 0;
  position: fixed;
  display: inline;
  justify-content: center;

}

.medicine_footer_details {
  float: right;
  display: flex;
  grid-column-gap: 20px;
}

.item_number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15181e;
}

.items_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #909196;
}

.go_to_cart_btn {
  background: #199a8e;
  box-shadow: 0px 3px 21px rgba(4, 29, 66, 0.06);
  border-radius: 14px;
  padding: 17px 45px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fbfcfc;
}

/*----------------------------------------------------------------Medicine page End-----------------------------------------------------------------*/
/*--------------------------------------------------------Medicine search active page start---------------------------------------------------------*/
.medicine_input_search {
  background: #f0f0f4;
  border-radius: 14px;
  width: 403px;
  height: 53px;
  border: none;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  padding: 0px 14px 0px 50px;
}

.medicine_search_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
}

.medicine_name_search {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 5px;
  margin-top: 5px;
}

.medicine_search_box_inner {
  display: inline-flex;
  column-gap: 16px;
}

.medicine_strip_of {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  color: #67696c;
}

.medicine_search_box_inner_2 {
  float: right;
}

/*--------------------------------------------------------Medicine search active page End---------------------------------------------------------*/
.border-bottom {
  border-bottom: 1px solid #eceff4;
}

.medicine_slider {
  display: flex;
  float: left;
  grid-column-gap: 50px;
  border-right: 1px solid #eceff4;
  /*border-bottom: 1px solid #ECEFF4;*/
  padding: 40px;
}

.medicine_slider_box_active {
  width: 98px;
  height: 98px;
  background: #ffffff;
  border: 1px solid #199a8e;
  border-radius: 6px;
  margin-top: 10px;
}

.slider_img {
  padding: 3px;
  width: 100%;
}

.medicine_slider_box {
  width: 98px;
  height: 98px;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 10px;
}

.medicine_slider_img {
  width: 400px;
  /*height: 400px;*/
  background: #ffffff;
  border-radius: 6px;
  padding: 12px;
}

.medicine_slider_img {
  width: 100%;
}


.custom-select select {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #424448;
  border: 1px solid #e2e2e6;
  border-radius: 9px;
  padding: 8px 12px;
  gap: 15px;
  width: 157px;
}

.custom-select select:focus {
  outline: none;
}

.custom-select {
  outline: none !important;
}


.add_to_cart_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  padding: 8px 42px;
}

.product_details {
  padding: 0 40px 80px;
}

.product_details_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #424448;
  margin: 30px 0;
}

.product_details_para {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #67696c;
  align-items: center;
}

/* my CSS */
.terms_conditions_error_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #df6129;
  text-align: left !important;
  margin-top: -5px;
  /* margin-left: 14px; */
  margin-bottom: 0;
  padding-bottom: 6px;
}

.address_error_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #df6129;
  text-align: left !important;
  margin-top: 10px;
  /* margin-left: 14px; */
  margin-bottom: 0;
  padding-bottom: 6px;
}

.heath_problem_list_box_map {
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.heath_problem_list_box_map_sub {
  flex: 0 0 16.33%;
  max-width: 17.33%;
  padding: 0 10px;
}

/* .heath_problem_list_box_model_map_sub {
  flex: 1 0 19.33%;
  max-width: 19.99%;
  padding: 0 10px;
} */
.heath_problem_list_box_model_map_sub {
  width: 100%;
  max-width: 300px;
  padding: 0 10px;
}


@media (min-width: 900px) {
  .heath_problem_list_box_model_map_sub {
    width: 25%;
  }
}

@media (min-width: 1200px) {
  .heath_problem_list_box_model_map_sub {
    width: 19.33%;
  }
}

.usermenu-dropdown-nav .dropdown-toggle,
.usermenu-dropdown-nav .pull-left {
  display: flex;
  align-items: center;
}

.usermenu-dropdown-nav .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.usermenu-dropdown-nav .dropdown-toggle .pull-left .profileName {
  margin: 8px;
}

.usermenu-dropdown-nav .dropdown-toggle .pull-left .profileName {
  margin: 8px;
}

.profiledash_member_card_border {
  border: 1px solid #e2e2e6;
  border-radius: 14px;
  /* height: 108px; */
}

/* .profiledash_member_card {
  height: 80px;
} */

.chat_card_box .nav {
  display: block;
}

.profileSidebar_tab_option_active_bg {
  background: rgba(25, 154, 142, 0.11);
  border-radius: 8px;
  padding: 13px 15px;
  margin-bottom: 20px;
}

.my_consultation_icons {
  margin-top: -4px;
  margin-right: 10px;
}

.medicine_slider_box_width {
  width: 98px;
  height: 98px;
  background: #ffffff;
  margin-top: 10px;
}

.medicin_pegination_center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: inherit;
}

.pagination .page-item .active {
  color: red;
  background-color: red;
}

.active>.page-link,
.page-link.active {
  z-index: 3;
  color: #ffffff;
  background-color: #199a8e;
  border-color: #199a8e;
}

.active>.page-link,
.page-link {
  z-index: 3;
  color: #000000;
  border-color: #199a8e;
}

.doctor_date_card_box {
  display: inline-flex;
  /* column-gap: 10%; */
}

.doctor_date_card_box_active {
  /* background: #FCEFE6; */
  /* box-shadow: 0px 3px 21px rgb(4 29 66 / 6%); */
  /* border-radius: 14px; */
  cursor: pointer;
  border-bottom: 2px solid #df6128;
}

.health_problems_img {
  position: relative;
  width: 72px;
  height: 72px;
  left: 15px;
  top: 0px;

  /* background: linear-gradient(0deg, #F1F1F1, #F1F1F1), url(image); */
  border-radius: 2000px;
}

.health_problems_list_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #424448;
  margin-bottom: 0;
  margin-left: 30px;
  align-items: center;
  align-self: center;
}

.health_problem_serach_input {
  background: #f0f0f4;
  border-radius: 14px;
  padding: 17px 83px 19px 40px;
  border: none;
  margin-left: 7px;
  width: 96%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #15181e;
}

.consultation_cancel_modal.consulatation-time {
  margin: 0;
}

::-webkit-scrollbar {
  width: 4px;
  height: 86px;
  left: 950px;
  top: 315px;
  border-radius: 100px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9d9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

.sidebar-link-pointer {
  cursor: pointer;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 999;
}

.sidebar-fixed {
  height: 100%;
  position: fixed;
}

table {
  border-collapse: collapse;
  /* box-shadow: 0 5px 10px #e1e5ee; */
  background-color: white;
  text-align: left;
  overflow: hidden;
}

/* table thead {
  box-shadow: 0 5px 10px #e1e5ee;
} */
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

td {
  display: table-cell;
  vertical-align: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

table td {
  padding: 1rem 2rem;
}

table th {
  padding: 1rem 2rem;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: 500;
}

/*our services section*/
.services-svg-bg1 {
  background: #E5E5FE;
  border-radius: 50% !important;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-svg-bg2 {
  background: #D9F5E2;
  border-radius: 50% !important;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-svg-bg3 {
  background: #FFE4CE;
  border-radius: 50% !important;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-svg-bg4 {
  background: #FFEBEF;
  border-radius: 50% !important;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-svg-bg5 {
  background: #D2EBEC;
  border-radius: 50% !important;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services-svg-bg6 {
  background: #FFF2D4;
  border-radius: 50% !important;
  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.services {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.image-container {
  width: 100%;
  text-align: center;
}

.consult_now_services_box {
  background: #ffffff;
  /* box-shadow: 0px 2px 28px -6px rgba(0, 0, 0, .08); */
  border-radius: 18px;
  width: 181px;
  height: 244px;
  padding-top: 20px !important;

}

.consult_now_services_box a {
  text-decoration: none;
}

.ourservices_section_box {
  display: inline-flex;
  column-gap: 30px;
  box-shadow: 0px 2px 28px -6px rgba(0, 0, 0, .08);
}

.hrLine {
  position: relative;
  /* Required for positioning the border */
}

.hrLine::after {
  content: '';
  position: absolute;
  border-right: 1px solid #F0F0F4;
  height: 200px;
  top: 0;
  /* Position at the top */
  right: 0;
}

.hrLines {
  position: relative;
  /* Required for positioning the border */
}

.hrLines::after {
  content: '';
  position: absolute;
  border-right: 1px solid #F0F0F4;
  height: 200px;
  top: 0;
  /* Position at the top */
  right: 0;
}

.ourservices_section_box .consult_now_head {
  margin-top: 30px;
}

.dashboard-owl-slider.owl-theme .owl-dots .owl-dot span {
  width: 10px !important;
  height: 10px !important;
  margin: 5px 7px;
  background: #d9d9d9 !important;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 50%;
}

.owl-dots {
  display: none;
}

.dashboard-owl-slider.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #909196 !important;
}

.consultation-details-button-center {
  display: flex;
  justify-content: center;
}

.owl-item img.benner {
  display: block;
  width: 100%;
  height: auto;
}

/*------------------------------------------------Prescription one css Start -----------------------------------------------*/
/* SideNAv */

.nav_container {
  display: flex;
  gap: 10px;
  width: 100%;
  overflow: hidden;
  background: #f8fbff;
  padding: 30px 100px;
}

.prescription_heading {
  padding: 20px 100px;
  background: #f8fbff;
}

.prescription-search form input {
  background: #f8fbff;
  border-radius: 8px;
  width: 390px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.pro-sidebar-content {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pro-sidebar-content a {
  text-decoration: none;
}

.pro-sidebar-content li {
  list-style: none;
  margin: 5px 10px;
  padding: 12px 10px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #9393aa;
}

.prescriptionTabactive li {
  background: #f8fbff;
  border-radius: 8px;
  color: #3093bb;
}

.pro-sidebar {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 64, 128, 0.04);
  border-radius: 12px;
  /* width: 100%;
  max-width: 265px; */
}

.sidebar_nav {
  display: flex;
  gap: 20px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  padding: 15px;
}

.capsule_icon {
  height: 43px;
  width: 43px;
}

.hold_text {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #FFFFFF;
}

.medicine_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.pro-menu ul a {
  text-decoration: none;
}

.medicine_header {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1e1f20;
}

.medicine_search_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.medicine_card_box {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgb(0 64 128 / 4%);
  border-radius: 12px 12px 12px 12px;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.medicine_card_box label {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #1e1f20;
}

.Textarea {
  width: 391px;
  height: 119px;
  background: #ffffff;
  border: 1px solid #eceff4;
  border-radius: 8px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #1e1f20;
}

.follow_up {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: none;
  width: 135px;
  height: 40px;
  padding: 10px;
}

.prescription_table {
  border: none !important;
  border-top: 1px solid #eceff4 !important;
  margin-top: 20px;
}

.prescription_table_head {
  background: #f8fbff;
  border-radius: 12px;
  padding: 16px;
  height: 56px;
}

.prescription_table_head_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 40px;
  color: #9393aa;
  text-align: center;
}

.prescription_table_body_row {
  height: 56px;
  border-bottom: 1px solid #eceff4;
}

.prescription_table_body_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 40px;
  color: #1e1f20;
  padding-left: 20px !important;
}

.prescription_table_body_text input {
  height: 38px;
  background: #ffffff;
  border: 1px solid #eceff4;
  border-radius: 8px;
  padding: 5px !important;
}

.prescription_table_bottom_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prescription_left_align {
  display: flex;
}

.prescription_result_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.result_declared_text {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #9393aa;
}

.table_next_btn {
  background: linear-gradient(45deg, #3093bb 0%, #82dbff 100%);
  border-radius: 12px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #ffff;
  padding: 10px 60px;
  border: none;
}

.generate-prescription-modal .modal-dialog {
  max-width: unset;
}

.generate-prescription-modal .modal-content {
  width: 100%;
  max-width: 715px;
  margin: auto;
  border: none;

}


.prescription-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #24b2b2;
  color: #ffffff;
  text-align: right;
  padding-bottom: 0px;
}

.prescription_appoinment_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
}

.prescription_table_appoinment_input {
  padding: 5px 10px;
}

.prescription_appoinment_input .prescription-left-text {
  /* font-family: "Mulish"; */
  font-style: normal;
  font-weight: 800 !important;
  font-size: 14px !important;
  color: #1e1f20 !important;
  margin-bottom: 5px !important;
  max-width: 135px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.prescription_appoinment_input span,
.prescription_table_appoinment_input span {
  /* font-family: "Mulish"; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #9393aa;
}

.prescription_appoinment_input .prescription-right-text {
  /* font-family: "Mulish"; */
  font-style: normal;
  font-weight: 800 !important;
  font-size: 14px;
  color: #1e1f20;
  text-align: right;
  margin-bottom: 5px !important;
  max-width: 135px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-name span {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  text-align: right;
  color: #1e1f20;
}

.prescription-btn-modal-footer {
  border: none;
  width: 100%;
}

/*------------------------------------------------Prescription one css end -----------------------------------------------*/

.react-select-3-listbox {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #424448;
  padding: 10px;
}

/*------------------------------------------------Support css start -----------------------------------------------*/

.support_container {
  font-family: arial;
  font-size: 24px;
  min-height: 500px;
  /* outline: dashed 1px black; */
  /* Setup */
  position: relative;
}

.support_child {
  width: 500px;
  height: 450px;
  background-color: #ffffff;
  box-shadow: 0px 3px 21px rgb(4 29 66 / 6%);
  border-radius: 12px;
  /* Center vertically and horizontally */
  position: absolute;
  top: 30%;
  left: 30%;
  margin: -25px 0 0 -25px;
  /* Apply negative top and left margins to truly center the element */
}

.support_child_main_logo {
  left: 25%;
  position: absolute;
  width: 250px;
}

.support_logo_box {
  margin: 35px 15px 15px 15px;
}

.support_logo_heading {
  font-weight: bold;
}

.support_body {
  top: 50%;
  left: 20%;
  position: absolute;
}

.support_inner_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  color: #909196;
}

.support_inner_description {
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #424448;
  margin-left: 10px;
  margin-top: -15px;
  font-weight: bold;
}

.support_body_left {
  top: 50%;
  left: 18%;
  position: absolute;
}

.support_body_right {
  top: 50%;
  right: 18%;
  position: absolute;
}

/*------------------------------------------------Support css end -----------------------------------------------*/

.medicine_search_heading {
  font-weight: bold;
  font-family: "Inter";
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  padding-top: 15px;
}


/* pagination every pages */

.my_consultation_body_box_card_body .pagination {}


/* golder membership */
.modal-headersss {
  background-image: url("../img/png/card\ bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5px !important;
}

.golden_header {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.Golden_text {
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
}

.Gold_btn {
  background: linear-gradient(89.42deg, #AC8F38 2.07%, #CBB150 24.4%, #EED66A 49.14%, #D5B858 70.58%, #B39041 99.5%) !important;
  border-radius: 8px;
  height: 45px !important;
  width: 185px !important;
  border: 1px solid gold;
}

.Gold_btn_text {
  font-size: 16px;
  font-weight: 600;
  color: #232528;
  text-align: center;
}

.border_gold {
  border: 1px solid #FCFCFC40;
  width: 360px;
  margin: 20px;
  border-radius: 5px;
  margin-top: 40px !important;
}

.black_theam .modal-content {
  background: none !important;
  border: none !important;
}

.padding_text {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 10px !important;
}

.gold_text {
  background: linear-gradient(89.42deg, #AC8F38 2.07%, #CBB150 24.4%, #EED66A 49.14%, #D5B858 70.58%, #B39041 99.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.Gold_btns {
  background: linear-gradient(89.42deg, #AC8F38 2.07%, #CBB150 24.4%, #EED66A 49.14%, #D5B858 70.58%, #B39041 99.5%) !important;
  border-radius: 8px;
  height: 45px !important;
  width: 90% !important;
  border: 1px solid gold;
}

.flexcol {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.rightborder {
  border-right: 1px dashed #FCFCFC70;
  padding-right: 30px !important;
}

.gold_text_under {
  font-size: 16px;
  font-weight: 700;
  color: #E2E2E6;
  padding: 0px !important;
  text-align: center !important;
}

.dot-list {
  list-style-type: none;
  padding-left: 0;
}

.dot-list li {
  display: flex;
  /* Display items in a row */
  align-items: center;
  /* Center vertically within the li */
  margin-bottom: 5px;
  /* Add spacing between list items */
}

.bullet {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(89.42deg, #AC8F38 2.07%, #CBB150 24.4%, #EED66A 49.14%, #D5B858 70.58%, #B39041 99.5%);
  margin-right: 10px;
  /* Add spacing between bullet and text */
}

/* gold success */
.sucess_gold {
  background: linear-gradient(180deg, #3E4048 0%, #101115 100%);
  border-radius: 16px;
  padding: 10px !important;
}

.gold_info_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #E2E2E6;
}

.gold_successfully_hr {
  border: 1px dashed #E2E2E6;
}

.gold_successfully_details_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #909196;
  margin-bottom: 5px;
  margin-top: 5px;
}

.good_successfully_details_value {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #E2E2E6;
  text-align: right;
  margin-bottom: 5px;
  margin-top: 5px;
}

.hold_model .modal-content {
  border-radius: 50px !important;
  background-color: none !important;
  border: none !important;
}

.hold_model .modal-body {
  padding: 0px !important;
}

.card_background {
  background-image: url("../img/png/Rectangle 2232.png");
  background-repeat: no-repeat;
  padding: 20px !important;
}

.subscription_plan_row {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  justify-content: center!important;
}

.subscription_plan_card {
  width: calc(50% - 10px);
}

.small_card_active {
  border: 1px solid #DF6129;
  border-radius: 14px;
  background-color: #FCEFE6;
}

.small_card {
  border: 1px solid #FFFFFF;
  border-radius: 14px;
  background-color: #FFFFFF;
}

.line {
  flex-grow: 1;
  border: none;
  border-top: 1px solid black;
  margin: 0 10px;
}

.mainClass {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.location {
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  border-radius: 14px;
}

.inlinediv {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 15px 10px;
  font-family: Inter;
}

.inlinediv img {
  display: inline-block;
}

.inlinediv p {
  color: #303236;
  font-size: 14px;
  margin: 0;
}

.first {
  background-image: url('../img/png/card\ bg1.png');
  background-repeat: no-repeat;
  background-size: 100% 100% !important;
}

.goldMainpage {
  padding: 20px 50px 20px 120px;
  /* padding: 10px; */
}

.mainflexdiv {
  display: flex;
  /* gap: 100px!important; */
  gap: 20px !important;
  align-items: center;
  justify-content: center;
}

.Gold_btnes {
  background: linear-gradient(89.42deg, #AC8F38 2.07%, #CBB150 24.4%, #EED66A 49.14%, #D5B858 70.58%, #B39041 99.5%) !important;
  border-radius: 8px;
  height: 45px !important;
  width: 100% !important;
  border: 1px solid gold;
}

.secondDiv {
  background-color: #FFEDE5;
  border-radius: 14px;
  height: 130px !important;
  padding-top: 15px;
}

.svgflex {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.svgContent {
  display: flex;
  gap: 20px;
}

.ConsultBtns {
  background-color: #F6773F;
  border-radius: 8px;
  color: #FFFFFF;
  font-weight: 500;
  padding: 8px 15px;
  border: none;
}

.paddingDiv {
  padding: 10px 20px;
}

.consultText {
  color: #F6773F;
  font-weight: 700;
  font-size: 18px;
}

.consultText1 {
  color: #F6773F;
  font-weight: 500;
  font-size: 14px;
}

p.consultText2 {
  color: #67696C;
  font-weight: 400;
  font-size: 12px;
  padding: 0px !important;
  margin: 0px !important;
  width: 30%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* height: 120px!important; */
}


body {
  overflow: auto !important;
}

@media (max-width:1156px) {
  /* .maingolddivs {
    flex-direction: column !important;
    justify-content: center !important;
  } */

  .first {
    width: 100% !important;
    /* margin: 0px 80px !important; */
  }

  .secondDiv {
    padding-top: 20px;
    width: 100% !important;
    /* margin: 0px 80px !important; */
  }
}

.subtext {
  color: #199A8E !important;
  font-size: 14px;
  font-weight: 600;
  margin: 0px !important;
  text-align: center !important;
  padding-bottom: 10px !important;
  padding-top: 5px;
}

.subTect2 {
  color: #909196;
  font-size: 12px;
  font-weight: 500;
  margin: 0px !important;
  text-align: center !important;
  padding-top: 5px;
  text-decoration: line-through;
}

.subTect23 {
  color: #909196;
  font-size: 12px;
  font-weight: 500;
  margin: 0px !important;
  text-align: center !important;
  padding-top: 5px;
  overflow: hidden;
  white-space: nowrap;
 text-overflow: ellipsis;
 max-width: 90%;
 padding-left: 2px; 
}

.shadowclassimg {
  Width: 167px !important;
  Height: 121px !important;
  border-radius: 14px 14px 0px 0px !important;
   overflow: hidden;
   white-space: nowrap;
  text-overflow: ellipsis;
  width: 30%;  
}
.shadowclassimg1 {
  Width: 100% !important;
  Height: 50% !important;
  border-radius: 14px 14px 0px 0px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 30%; */
}

.subtext1 {
  color: "#303236";
  font-weight: 600;
  font-size: 14px;
  margin: 0px !important;
  text-align: center !important;
}

.bvg {
  padding-top: 20px;
}

.latestparagraph {
  color: #909196;
  margin: 0px;
  font-size: 14px;
}

.latestDiv {
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.shadowclass {
  background-color: #FFFFFF !important;
  box-shadow: 0px 3px 21px 0px #041D420F;
  border-radius: 14px !important;
  Width: 167px;
  margin-right: 20px;

}
.shadowclass1 {
  Width: 100% !important;
    /* Height: 190px !important; */
    border-radius: 14px 14px 0px 0px !important;
      overflow: hidden;
     white-space: nowrap;
    text-overflow: ellipsis;
     width: 30%;  

}
.maincarusal {
  display: flex;
  justify-content: space-between;
}

.latestNews {
  box-shadow: 0px 4px 14px 0px #00000014;
  background-color: #FFFFFF;
  border-radius: 18px;

}

.latestOne {
  background-color: #DFF1EB;
  border-radius: 9px;
  border: none;
  text-align: center;
  padding: 5px 10px;
}

.latestOnetext {
  color: #199A8E;
  font: "Inter";
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
}

.latestTwo {
  color: "#303236";
  font: "Inter";
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
  padding-top: 20px;
  line-height: 24px;

}

.centered-text {
  text-align: center;
}

.logo {
  display: flex;
  gap: 20px !important;
  align-items: center !important;
  justify-content: center !important;
}

.footermain {
  display: flex;
  justify-content: space-around !important;
  padding-top: 50px;
  padding-bottom: 30px;
}

.footerText {
  font-size: 20px;
  font-family: "Inter";
  font-weight: 600;
  color: #FFFFFF;
  padding-bottom: 20px;
}

.footerText1 {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  color: #FFFFFF;
}

.footerText12 {
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 0px;
}

.partnermain {
  margin-bottom: 50px;
}

.footermainclass {
  background-color: #199A8E;
}

.reviewmain {
  box-shadow: 0px 4px 14px 0px #00000014;
  background-color: #FFFFFF;
  border-radius: 18px;
  padding: 20px 25px;
}

.reviewtext {
  color: #303236;
  font-size: 22px;
  font-weight: 500;
  padding-right: 20px;
}

.smoothtext {
  padding-right: 30%;
}

.loremp {
  padding-right: 15%;
}

.reviewtextp {
  margin-right: 50px;
}

.reviewtextp {
  font-size: 15px;
  font-weight: 500;
  color: #909196;
}

.reviewcarousal {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.texth2 {
  color: #199A8E;
  font-size: 18px;
  font-weight: 700;
}

.texth3 {
  color: #199A8E;
  font-size: 32px;
  font-weight: 700;
}

.doctorpart {
  background-image: url("../img/png/Group\ 34657@2x.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
}

.doctortext {
  color: #199A8E;
  font-size: 18px;
  font-weight: 700;
}

.doctormaintext {
  color: #199A8E;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.subtextdoctor {
  color: #67696C;
  font-size: 18px;
  font-weight: 500;
}

.secondfg {
  background-color: #FFF2EA;
  border-radius: 14px;
  padding: 12px 10px;
}

.starttext {
  color: #303236;
  font-size: 20px;
  font-weight: 500;
}

.firstinlinediv {
  width: 100%;
  max-width: 212px;
  border-right: 1px solid #DFDFDF;
}

.doctorparetdf {
  display: flex;
  align-items: center;
  padding: 50px 0px;
  gap: 20px;
}

.inputtype {
  border: none;
  background-color: #F6F6F6;
  width: 518px;
}

@media only screen and (max-width: 927px) {
  .doctorparetdf {
    flex-direction: column !important;
  }

  .logo {
    flex-direction: column !important;
  }

  .mainClass {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 1200px) {
  .mainClass {
    flex-direction: column !important;
    gap: 20px !important;
  }

  .secondfg {
    width: 250px !important;
  }

  .inputtype {
    width: 100% !important;
  }
}

@media only screen and (max-width: 919px) {

  .inputtype {
    width: 100% !important;
  }
}

@media only screen and (max-width: 423px) {
  .dicimage {
    width: 200px !important;
  }
}

/* @media only screen and (max-width: 320px) {
  .ourservices_section_box {
    margin: 0 auto;
    width: fit-content;
    display: grid;
    column-gap: 18px;
    grid-template-columns: auto;
    column-count: 1;
    ;
  }

  .hrLines {
    border-right: none !important;
  }
} */

@media only screen and (max-width: 430px) {
  .servicdcv {
    padding-top: 20px;
  }

}

@media only screen and (max-width: 396px) {
  .imgflexdiv {
    flex-direction: column;
    align-items: center;
  }

  .firstimg {
    height: 30px;
    width: 50px;
  }

  .seimg {
    height: 30px;
    width: 50px;
  }

  .first {
    background-image: none !important;
    background-color: #07070a !important;
    border-radius: 8px !important;
  }

  .startflex {
    flex-direction: column !important;
    gap: 0px !important;
  }

  .time_slot_card_box {
    display: inline-grid;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 5px;
    grid-template-columns: 33.33% 33.33% 33.33%;
    row-gap: 15px;
  }

}

@media only screen and (max-width: 330px) {
  .time_slot_card_box {
    display: inline-grid;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 5px;
    grid-template-columns: 50% 50%;
    row-gap: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .mainflexdiv {
    flex-direction: column !important;
    margin-bottom: 10px;
  }

  .goldMainpage {
    padding: 10px 10px 10px 10px !important;
  }
}

@media only screen and (max-width: 815px) {
  .footermain {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 319px) {
  .consultText2 {
    width: 20px !important;
  }
}

@media only screen and (max-width: 1218px) {
  .maingolddivs {
    flex-direction: column !important;
  }

  .first {
    width: 100% !important;
  }

  .secondDiv {
    padding-top: 20px;
    width: 100% !important;
  }
}

@media only screen and (max-width: 1399px) {
  .secondDiv {
    padding-top: 25px;
    height: 150px !important;
  }
}

@media only screen and (max-width: 497px) {
  .svgflex {
    flex-direction: column !important;
  }

  .secondDiv {
    padding: 20px;
    height: 180px !important;
  }

  .ConsultBtns {
    margin-top: 10px !important;
  }

  .maingolddivs {
    margin: 0px 30px 0px 0px !important;
  }
}

.react-multi-carousel-list .ourservicecarpusal {
  display: none !important;
}

@media (max-width:716px) {
  .first {
    width: none !important;
    margin: none !important;
  }

  .secondDiv {
    width: none !important;
    margin: none !important;
  }
}

.helathimg {
  gap: 10px !important;
}

.surgeyPtext {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #303236;


}

.audio-call-container {
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;
}

/* .action-container {
} */
.btn-call-end {
  background: #fa4169;
  border: none;
  border-radius: 8px;
  padding: 8px 10px;
  margin: 0 10px;
}

.btn-call-mute {
  background: #e0e0e0;
  border: none;
  border-radius: 8px;
  padding: 8px 13px 12px 13px;
  margin: 0 10px;
}

.bank-verify {
  padding: 0 10px;
  height: 25px;
  margin: 10px;
}

.signature-canvas {
  width: 100%;
  min-height: 500px;
}

.signature-btn-modal-footer {
  width: 100%;
}

.profilesidebarmain {
  display: flex;
  justify-content: space-between !important;
}

.togglesidebar {
  visibility: hidden;
}

.back_btns {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.back_btn_texts {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  color: #424448;
  margin-bottom: 0;
}

.detailpage {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewmapbtn {
  border-radius: 14px;
  border: 1.5px solid var(--border, #E2E2E6);
  background: var(--white, #FFF);
  display: flex;
  width: 126px;
  height: 44px;
  justify-content: center;
  align-items: center;
  color: var(--primary, #199A8E);

}

.address {
  color: var(--text, #303236);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.adressh4 {
  color: var(--text, #303236);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.adressh5 {
  color: var(--text, #303236);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.lab_test_address_box_texts {
  color: var(--text, #303236);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.lab_test_address_box_textes {
  color: var(--text, #303236);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: lowercase;
}

.changese {
  display: flex;
  align-items: center;
  gap: 10px;
}

.editimg {
  /* Add any styling for the image here */
  width: 20px;
  /* Adjust this width as needed */
  height: 20px;
  /* Adjust this height as needed */
}

.changes {
  color: var(--orange-dark, #DF6129);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
  /* Reset margin to prevent unwanted spacing */
}

.memberlist {
  color: var(--sub-text-light, #909196);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 21px */
}

.memberimg {
  border-radius: 50%;
  width: 44px;
  height: 44px;
}

.location-header {
  display: flex;
  gap: 5px;

}

.listCity {
  color: #199A8E;
  font-size: 12px;
  text-transform: capitalize;
  cursor: pointer;
}

.city-name {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #199A8E;
  cursor: pointer;
  text-transform: capitalize;
}

.amounthealthcare {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;
  color: #199A8E;

}

.checkboxradio {
  width: 20px;
  height: 20px;
  top: 2px;
  left: 2px;
  background: #199A8E;

}

.nodata {
  text-align: center;
  font-size: 20px;
  padding-top: 20px;
}

.precption_div {
  display: flex;
  gap: 5px;
}

.mainprecptiondiv {
  box-shadow: 0px 3px 21px 0px #041D420F;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 20px;
  width: calc(50% - 8px);
}

.mainprecptiondivs {
  box-shadow: 0px 3px 21px 0px #041D420F;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 20px;
}

.prectexts {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;

}

.prec {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
}

.paybtn {
  width: 216px;
}

.spantext {
  font-family: CircularXX TTTrial;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #67696C;
}

.precdoctor {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #909196;

}

.precssecond {
  margin: 10px;
  padding: 5px;
  background-color: #F0F0F4;
  height: 151px;
  border-radius: 10px
}

.card-containers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* Adjust as needed */
  gap: 16px;
  /* Adjust the gap between cards */
}

.imagebutton {
  display: flex;
  gap: 10px;
}

.precssecond {
  position: relative;
}

.imagebutton {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.pagination {
  text-align: center;
  padding-top: 20px;
}

.carebtn {
  background: #E5E5FE;
  padding: 2px 12px 2px 12px;
  border-radius: 4px;
  gap: 10px;
  border: none;
}

.careparagraph {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #909196;

}

.careparagraph {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #67696C;
}

.paybtns {
  width: 216px;
  background: #199A8E1C;
  border: none;
  color: #199A8E;
}

.docname {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #F6773F;
}

.nameflex {
  display: flex;
  justify-content: space-between;
}

.healthradiologytext {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  color: " #424448"
}

.healthradiologytexts {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  color: #424448;

}

.healthradiologytextss {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  color: #424448;
}

.textdynamic {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  color: #DF6129;
  margin-bottom: 0px;

}

.textdynamics {
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  color: #424448;
  margin-bottom: 0px;

}

.textTest {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  color: #15181E;

}

.textdynamics {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  color: #424448;
}

.textdynamicses {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  padding-top: 10px;
  text-align: left;
  color: #67696C;
}

.modal_45 {
  --bs-modal-width: 747px;
}

.subscription-plans-container {
  display: flex;
  /* Display cards in a row */
  /* Allow horizontal scrolling if cards overflow */
  gap: 15px;
  /* Add some space around cards */
}

.goldtv {
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  padding-top: 20px;

}

.gol {
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: center;
  padding-top: 20px;
}

.Gold_btnss {
  background: linear-gradient(89.42deg, #AC8F38 2.07%, #CBB150 24.4%, #EED66A 49.14%, #D5B858 70.58%, #B39041 99.5%) !important;
  border-radius: 8px;
  height: 45px !important;
  width: 250px !important;
  border: 1px solid gold;
}

.refertext {
  font-family: Inter;
  font-size: 24px;
  font-weight: 800;
  line-height: 36px;
  text-align: left;
  color: #199A8E;
  padding-bottom: 10px;

}

.borderrefer {
  border: 1px solid #E2E2E6;
  border-radius: 12px;
  padding: 20px;
}

.refertextparaggrph {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  color: #303236;
  padding-top: 15px;
}

.refermaindiv {
  display: flex;
}

.referbetween {
  display: flex;
  justify-content: space-between !important;
  align-items: center !important;
}


.maintf {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #303236;

}

.maintfp {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #67696C;

}

.uploadlabel {
  position: relative;
  height: 136px;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #303236;
  border-radius: 50%;
  /* Equivalent to rounded-lg in Tailwind CSS */
  background-color: #F0F0F4;
  /* Assuming primary-50 refers to a CSS variable */
  text-transform: uppercase;
  border: 1px dashed;
  cursor: pointer;
}

.uploadlabel input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* Hide the "No file chosen" and "Choose file" text */
.uploadlabel input[type="file"]+span {
  display: none;
}

.imgupload {
  display: flex;
  justify-content: center !important;
}

.uploadtext {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #303236;
  padding-top: 20px;
}

.radio-group {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.question {
  color: "#303236";
  font-size: 14px;
  font-weight: 600;
  text-align: left;

}

.medical-history {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #303236;

}

.radio {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.radio input[type="radio"] {
  margin-right: 10px;
}

.labmainflow {
  display: flex;
  justify-content: space-between !important;
}

.rtext {
  font-size: 18px;
  font-weight: 500;

  text-align: left;
  color: #909196;

}

.eyesback {
  border-radius: 50%;
  background: #cfd0d1;
  padding: 5px 8px !important;
  align-items: center;
  display: inline;
}
.doctorequ{
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
}
