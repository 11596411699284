.loader-container {
  position: fixed;
  background: #000000a1;
  z-index: 999999;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container .loader-item {
  height: 25%;
  width: 25%;
}
.alert-primary .button-decline {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  background: #e0416580;
  border-radius: 10px;
  padding: 15px 10px;
  color: #fff;
  margin: 0 10px;
}
.alert-primary .button-accept {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  border: none;
  background: #24b2b2;
  border-radius: 10px;
  padding: 15px 10px;
  color: #fff;
  margin: 0 10px;
}

.alert {
  position: fixed;
  top: 40px;
  right: 50px;
  width: 25%;
  padding: 15px;
  z-index: 9999;
  font-size: 18px;
  border: 0;
  border-radius: 12px;
}

.alert-shown {
  opacity: 1;
  transition: all 250ms linear;
}

.alert-hidden {
  opacity: 0;
  z-index: 0;
  transition: all 250ms linear 0s;
  transform: translateX(500px);
}

.alert-primary {
  background-color: #ffffff;
  color: #3093bb;
  font-weight: 500;
  box-shadow: 0px 4px 4px 0px #82dbff;
}

.alert-success {
  background-color: #ffffff;
  color: #24b2b2;
  font-weight: 500;
  box-shadow: 0px 4px 4px 0px #24b2b282;
}

.alert-danger {
  background-color: #ffffff;
  color: #e04165;
  font-weight: 500;
  box-shadow: 0px 4px 4px 0px #e0416580;
}

.error {
  color: #e04165;
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
}

.btn-send {
  background: none;
  border: none;
  border-radius: 5px;
  color: #96a0b5;
  font-size: 16px;
  padding: 0px 10px 5px 10px;
  transition: all 0.3s cubic-bezier(0.88, 0.19, 0.37, 1.11);
}

.btn-send img {
  height: 20px;
  width: 25px;
}

.attach_img_box .attch_doc_img {
  height: 500px;
  width: 500px;
  object-fit: cover;
}

.upload_avatar_btn img {
  height: 20px;
}

.upload_avatar_btn {
  /* background: #199a8e; */
  border-radius: 9px;
  border: none;
  text-transform: uppercase;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  padding: 10px 15px;
  margin: 0 0 0 20px;
}


.upload_avatar_btn:hover {
  /* background: #24b2b2; */
  color: #fff;
}

.small_card {
  box-shadow: 0px 3px 21px 0px rgba(4, 29, 66, 0.06);

}

.card_bg {
  background-color: #24b2b2;
}
.footerShadow{
  box-shadow: 0px -6px 21px 0px #041D420F;

}
